import React from "react";
import { Modal } from "antd"

const CustomModal = ({ isModalOpen, handleOk, handleCancel, children, ...rest }) => {
  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      closable={false}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
