import React, { useEffect } from "react";
import Footer from "./Footer";
import Slider from "react-slick";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { REACT_APP_HOST_NAME } from "../baseurl";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { useRef } from "react";
// import io from "socket.io-client";
// const ENDPOINT = "http://localhost:3001"; // "https://talk-a-tive.herokuapp.com"; -> After deployment
// var socket, selectedChatCompare;
const Home = () => {
  const slider1 = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1199,
        slider1: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        slider1: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        slider1: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const slider2 = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "45px",
    responsive: [
      {
        breakpoint: 991,
        slider2: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        slider2: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        slider2: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    const closeDropdown = (e) => {
      if (catRef.current && !catRef.current.contains(e.target)) {
        setCatShow(false)
      }
    }

    document.body.addEventListener('click', closeDropdown);
    return () => document.body.removeEventListener('click', closeDropdown);
  }, [])

  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [category, setCategory] = useState([]);
  const [topRated, setTopRated] = useState([]);
  const [cat, setCat] = useState("");
  const catRef = useRef();
  const [catShow, setCatShow] = useState(false);
  const [topRatId, setTopRatId] = useState("");
  const [err, setErr] = useState(false);
  const nav = useNavigate();
  const [error, setError] = useState("");

  const getTopRated = () => {
    const token = localStorage.getItem("PO_TOKEN");
    axios({
      method: "get",
      url: `${REACT_APP_HOST_NAME}/apps/all-topRatedMoments`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setTopRated(response.data.data);
        setLoading2(false);
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        localStorage.removeItem("PO_TOKEN");
        nav("/");
      });
  };

  const getCategory = () => {
    const token = localStorage.getItem("PO_TOKEN");
    axios({
      method: "get",
      url: `${REACT_APP_HOST_NAME}/apps/all-category`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setCategory(response.data.data);
        setLoading1(false);
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        localStorage.removeItem("PO_TOKEN");
        nav("/");
      });
  };

  const handleSelect = () => {
    if (cat) {
      setError("");
      nav("/set_shedule", { state: { topRatId: topRatId } });
    } else {
      setErr(true);
    }
  };

  useEffect(() => {
    // socket = io(ENDPOINT);
    getCategory();
    getTopRated();
  }, []);

  return (
    <>
      <Header />
      {/* Site Content Start */}
      <main className="po-site-content">
        {/* Landing Section Start */}
        <section className="po-landing-section">
          <div className="container">
            <div className="po-landing-content">
              <div className="po-landing-content__inner">
                <h1 className="po-landing-section-title">
                  Find good company for Conference
                </h1>
                {/* <p className="po-scrolling-text-label">[Scrolling text]</p> */}
                <div className="po-landing-form">
                  <div className="po-landing-form-input-group">
                    <div className="po-landing-form-input">
                      <span className="po-landing-form-search-icon">
                        <img
                          src="/images/search-icon.png"
                          alt="Search"
                          className="img-fluid"
                          onClick={() => setCatShow(!catShow)}
                        />
                      </span>
                      <div
                        className="form-control prise_main_drop"
                        onClick={() => setCatShow(!catShow)}
                        ref={catRef}
                      >
                        <span className="prise-data">{cat}</span>
                        <span className="prise_down_icon"></span>

                        <ul
                          className={
                            catShow
                              ? "prise-list-merge opened"
                              : "prise-list-merge"
                          }
                        >
                          {topRated.map((elem) => (
                            <li
                              className={
                                cat === elem.name
                                  ? "prise_list selected"
                                  : "prise_list"
                              }
                              onClick={() => {
                                setErr(false);
                                setCat(elem.name);
                                setTopRatId(elem.id);
                              }}
                            >
                              <img
                                src={elem.image}
                                className="option-img mx-2"
                              />
                              {elem.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <span className="po-landing-form-cancel-icon">
                        {cat ? (
                          <img
                            src="/images/cancel-icon.png"
                            alt="Cancel"
                            className="img-fluid"
                            onClick={() => setCat(false)}
                          />
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={handleSelect}
                    >
                      Let’s Do It
                    </button>
                  </div>
                </div>
                <p className="text-danger">
                  {err ? "Please Select Category.." : ""}
                </p>

                {topRated.length > 0 ? (
                  <>
                    <ul className="po-service-list" style={{ margin: "10px" }}>
                      <li className="po-service-item">
                        <a
                          onClick={() => {
                            setErr(false);
                            setCat(topRated[0].name);
                            setTopRatId(topRated[0].id);
                          }}
                          className="po-service-link"
                        >
                          {topRated[0].name}
                        </a>
                      </li>
                      <li className="po-service-item">
                        <a
                          onClick={() => {
                            setErr(false);
                            setCat(topRated[1].name);
                            setTopRatId(topRated[1].id);
                          }}
                          className="po-service-link"
                        >
                          {topRated[1].name}
                        </a>
                      </li>
                      <li className="po-service-item">
                        <a
                          onClick={() => {
                            setErr(false);
                            setCat(topRated[2].name);
                            setTopRatId(topRated[2].id);
                          }}
                          className="po-service-link"
                        >
                          {topRated[2].name}
                        </a>
                      </li>
                      <li className="po-service-item">
                        <a
                          onClick={() => setCatShow(true)}
                          className="po-more-service-link"
                          ref={catRef}
                        >
                          See More
                        </a>
                      </li>
                    </ul>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="po-landing-img">
            <img
              src="/images/home-landing-img.png"
              alt="Landing image"
              className=" "
            />
          </div>
        </section>
        {/* Landing Section End */}

        {/* Envents Section Start */}
        <section className="event-section">
          <div className="container">
            <div className="event-content">
              {!loading1 ? (
                <div className="row">
                  {category.map((cat) => (
                    <div className="col col-12 col-md-4 col-lg-2 col-xl-2">
                      <div className="event-item ">
                        <div className="event-item-img">
                          <img src={cat.image} alt="" className="img-fluid" />
                        </div>
                        <h5 className="event-item-name ">{cat.name}</h5>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="row">
                  <div className="col col-12 col-md-4 col-lg-2 col-xl-2">
                    <div className="event-item">
                      <Skeleton count={1} className="sk-cat-img"></Skeleton>
                      <Skeleton count={2} className="sk-cat-name"></Skeleton>
                    </div>
                  </div>
                  <div className="col col-12 col-md-4 col-lg-2 col-xl-2">
                    <div className="event-item">
                      <Skeleton count={1} className="sk-cat-img"></Skeleton>
                      <Skeleton count={2} className="sk-cat-name"></Skeleton>
                    </div>
                  </div>
                  <div className="col col-12 col-md-4 col-lg-2 col-xl-2">
                    <div className="event-item">
                      <Skeleton count={1} className="sk-cat-img"></Skeleton>
                      <Skeleton count={2} className="sk-cat-name"></Skeleton>
                    </div>
                  </div>
                  <div className="col col-12 col-md-4 col-lg-2 col-xl-2">
                    <div className="event-item">
                      <Skeleton count={1} className="sk-cat-img"></Skeleton>
                      <Skeleton count={2} className="sk-cat-name"></Skeleton>
                    </div>
                  </div>
                  <div className="col col-12 col-md-4 col-lg-2 col-xl-2">
                    <div className="event-item">
                      <Skeleton count={1} className="sk-cat-img"></Skeleton>
                      <Skeleton count={2} className="sk-cat-name"></Skeleton>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        {/* Envents Section End */}

        {/* Trending Moments Section Start */}
        <section className="po-trending-moments-section">
          <div className="container">
            <h2 className="po-trending-moments-title section-title">
              Top Rated Moments In Your Area
            </h2>

            {!loading2 ? (
              <div className="po-trending-slider">
                <Slider {...slider1}>
                  {topRated.map((cat) => (
                    <div className="po-trending-slide">
                      <div className="po-trending-card">
                        <div className="po-trending-card-img">
                          <img
                            src={cat.image}
                            alt="shopping-img"
                            className="img-fluid"
                          />
                        </div>
                        <a className="po-trending-card-link">{cat.name}</a>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            ) : (
              <div className="row">
                <div className="col col-3">
                  <div className="po-trending-slide">
                    <div className="po-trending-card">
                      <div className="po-trending-card-img">
                        <Skeleton count={1} className="sk-top-img"></Skeleton>
                      </div>
                      <Skeleton count={1} className="sk-top-name"></Skeleton>
                    </div>
                  </div>
                </div>
                <div className="col col-3">
                  <div className="po-trending-slide">
                    <div className="po-trending-card">
                      <div className="po-trending-card-img">
                        <Skeleton count={1} className="sk-top-img"></Skeleton>
                      </div>
                      <Skeleton count={1} className="sk-top-name"></Skeleton>
                    </div>
                  </div>
                </div>
                <div className="col col-3">
                  <div className="po-trending-slide">
                    <div className="po-trending-card">
                      <div className="po-trending-card-img">
                        <Skeleton count={1} className="sk-top-img"></Skeleton>
                      </div>
                      <Skeleton count={1} className="sk-top-name"></Skeleton>
                    </div>
                  </div>
                </div>
                <div className="col col-3">
                  <div className="po-trending-slide">
                    <div className="po-trending-card">
                      <div className="po-trending-card-img">
                        <Skeleton count={1} className="sk-top-img"></Skeleton>
                      </div>
                      <Skeleton count={1} className="sk-top-name"></Skeleton>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
        {/* Trending Moments Section End */}

        {/* How Work Section Start */}
        <section className="how-work-section">
          <div className="container">
            <div className="how-work-content">
              <div className="how-work-heading">
                <div className="how-work-heading-img">
                  <img
                    src="/images/how_work.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="how-work-title">
                  <h2 className="section-title">How Does it Work?</h2>
                </div>
              </div>
              <div className="how-work-list">
                <div className="row justify-content-center">
                  <div className="col col-12 col-md-6 col-lg-4 col-xl-4">
                    <div className="how-work-item">
                      <div className="how-work-item-img how-work-pen-img">
                        <img
                          src="/images/work_1.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="how-work-item-body">
                        <h5 className="how-work-item-title">Sign Up Today</h5>
                        <p className="how-work-item-discription section-discription">
                          Getting started is free. We have 9 activities
                          available for you to choose from.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col col-12 col-md-6 col-lg-4 col-xl-4">
                    <div className="how-work-item">
                      <div className="how-work-item-img how-work-calander-img">
                        <img
                          src="/images/work_2.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="how-work-item-body">
                        <h5 className="how-work-item-title">Book a Moment</h5>
                        <p className="how-work-item-discription section-discription">
                          Let us know the time and place and we'll match you
                          with a Plus One. Price starts at $19/hr with a 2-hour
                          minimum.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col col-12 col-md-6 col-lg-4 col-xl-4">
                    <div className="how-work-item">
                      <div className="how-work-item-img how-work-user-img">
                        <img
                          src="/images/work_3.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="how-work-item-body">
                        <h5 className="how-work-item-title">
                          Get Good Company
                        </h5>
                        <p className="how-work-item-discription section-discription">
                          Finally! Now you have someone to do that fun thing
                          you've been putting off
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="how-wowk-btn text-center">
                  <a href="javascript:void(0)" className="btn btn-warning">
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* How Work Section End */}

        {/* Featured Section Start */}
        <section className="features-section">
          <div className="container">
            <div className="featured-content">
              <h2 className="how-work-slider-title section-title">
                Featured Plus Ones
              </h2>
              <div className="how-work-slider-list">
                <Slider {...slider2}>
                  <div className="how-work-slider-item">
                    <div className="how-work-profile">
                      <div className="how-work-profile-img">
                        <img
                          src="/images/featured_1.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="how-work-profile-details">
                        <h6 className="how-work-profile-title">George C.</h6>
                        <p className="how-work-profile-elite">
                          <i className="fa-solid fa-circle-check"></i>
                          <span className="section-discription">
                            ID Verified
                          </span>
                        </p>
                        <div className="how-work-profile-review">
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <p className="mb-0 section-discription">
                            4.8 <span>(5 reviews)</span>{" "}
                          </p>
                        </div>
                        <p className="how-work-profile-discription section-discription text-start">
                          <i className="fa-regular fa-circle-check"></i> 5
                          Moments
                        </p>
                        <p className="how-work-profile-discription section-discription text-start">
                          <i className="fa-regular fa-circle-check"></i> 1
                          Repeat Client
                        </p>
                      </div>
                    </div>
                    <p className="how-work-profile-love section-discription text-start">
                      "I love serene walks and good weather"
                    </p>
                  </div>
                  <div className="how-work-slider-item">
                    <div className="how-work-profile">
                      <div className="how-work-profile-img">
                        <img
                          src="/images/featured_2.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="how-work-profile-details">
                        <h6 className="how-work-profile-title">Alissa T.</h6>
                        <p className="how-work-profile-elite">
                          <i className="fa-solid fa-circle-check"></i>
                          <span className="section-discription">
                            ID Verified
                          </span>
                        </p>
                        <div className="how-work-profile-review">
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <p className="mb-0 section-discription">
                            4.9 <span>(10 reviews)</span>{" "}
                          </p>
                        </div>
                        <p className="how-work-profile-discription section-discription text-start">
                          <i className="fa-regular fa-circle-check"></i> 10
                          Moments
                        </p>
                        <p className="how-work-profile-discription section-discription text-start">
                          <i className="fa-regular fa-circle-check"></i> 1
                          Repeat Client
                        </p>
                      </div>
                    </div>
                    <p className="how-work-profile-love section-discription text-start">
                      "I enjoy shopping & travelling"
                    </p>
                  </div>
                  <div className="how-work-slider-item">
                    <div className="how-work-profile">
                      <div className="how-work-profile-img">
                        <img
                          src="/images/featured_3.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="how-work-profile-details">
                        <h6 className="how-work-profile-title">Mark S.</h6>
                        <p className="how-work-profile-elite">
                          <i className="fa-solid fa-circle-check"></i>
                          <span className="section-discription">
                            ID Verified
                          </span>
                        </p>
                        <div className="how-work-profile-review">
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <p className="mb-0 section-discription">
                            4.8 <span>(5 reviews)</span>{" "}
                          </p>
                        </div>
                        <p className="how-work-profile-discription section-discription text-start">
                          <i className="fa-regular fa-circle-check"></i> 5
                          Moments
                        </p>
                        <p className="how-work-profile-discription section-discription text-start">
                          <i className="fa-regular fa-circle-check"></i> 1
                          Repeat Client
                        </p>
                      </div>
                    </div>
                    <p className="how-work-profile-love section-discription text-start">
                      "I love serene walks and good weather"
                    </p>
                  </div>
                  <div className="how-work-slider-item">
                    <div className="how-work-profile">
                      <div className="how-work-profile-img">
                        <img
                          src="/images/featured_3.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="how-work-profile-details">
                        <h6 className="how-work-profile-title">Mark S.</h6>
                        <p className="how-work-profile-elite">
                          <i className="fa-solid fa-circle-check"></i>
                          <span className="section-discription">
                            ID Verified
                          </span>
                        </p>
                        <div className="how-work-profile-review">
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <p className="mb-0 section-discription">
                            5.0 <span>(12 reviews)</span>{" "}
                          </p>
                        </div>
                        <p className="how-work-profile-discription section-discription text-start">
                          <i className="fa-regular fa-circle-check"></i> 12
                          Moments
                        </p>
                        <p className="how-work-profile-discription section-discription text-start">
                          <i className="fa-regular fa-circle-check"></i> 1
                          Repeat Client
                        </p>
                      </div>
                    </div>
                    <p className="how-work-profile-love section-discription text-start">
                      "I love discovering new cuisines”
                    </p>
                  </div>
                </Slider>
              </div>
              <div className="how-work-slider-list mb-0">
                <Slider {...slider2}>
                  <div className="how-work-slider-item">
                    <div className="how-work-profile">
                      <div className="how-work-profile-img">
                        <img
                          src="/images/featured_4.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="how-work-profile-details">
                        <h6 className="how-work-profile-title">Kirsten C.</h6>
                        <p className="how-work-profile-elite">
                          <i className="fa-solid fa-circle-check"></i>
                          <span className="section-discription">
                            ID Verified
                          </span>
                        </p>
                        <div className="how-work-profile-review">
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <p className="mb-0 section-discription">
                            5.0 <span>(12 reviews)</span>{" "}
                          </p>
                        </div>
                        <p className="how-work-profile-discription section-discription text-start">
                          <i className="fa-regular fa-circle-check"></i> 12
                          Moments
                        </p>
                        <p className="how-work-profile-discription section-discription text-start">
                          <i className="fa-regular fa-circle-check"></i> 1
                          Repeat Client
                        </p>
                      </div>
                    </div>
                    <p className="how-work-profile-love section-discription text-start">
                      "I love discovering new cuisines”
                    </p>
                  </div>
                  <div className="how-work-slider-item">
                    <div className="how-work-profile">
                      <div className="how-work-profile-img">
                        <img
                          src="/images/featured_5.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="how-work-profile-details">
                        <h6 className="how-work-profile-title">Joe B.</h6>
                        <p className="how-work-profile-elite">
                          <i className="fa-solid fa-circle-check"></i>
                          <span className="section-discription">
                            ID Verified
                          </span>
                        </p>
                        <div className="how-work-profile-review">
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <p className="mb-0 section-discription">
                            5.0 <span>(12 reviews)</span>{" "}
                          </p>
                        </div>
                        <p className="how-work-profile-discription section-discription text-start">
                          <i className="fa-regular fa-circle-check"></i> 12
                          Moments
                        </p>
                        <p className="how-work-profile-discription section-discription text-start">
                          <i className="fa-regular fa-circle-check"></i> 1
                          Repeat Client
                        </p>
                      </div>
                    </div>
                    <p className="how-work-profile-love section-discription text-start">
                      "I love discovering new cuisines”
                    </p>
                  </div>
                  <div className="how-work-slider-item">
                    <div className="how-work-profile">
                      <div className="how-work-profile-img">
                        <img
                          src="/images/featured_6.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="how-work-profile-details">
                        <h6 className="how-work-profile-title">Sarah C.</h6>
                        <p className="how-work-profile-elite">
                          <i className="fa-solid fa-circle-check"></i>
                          <span className="section-discription">
                            ID Verified
                          </span>
                        </p>
                        <div className="how-work-profile-review">
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <p className="mb-0 section-discription">
                            5.0 <span>(12 reviews)</span>{" "}
                          </p>
                        </div>
                        <p className="how-work-profile-discription section-discription text-start">
                          <i className="fa-regular fa-circle-check"></i> 12
                          Moments
                        </p>
                        <p className="how-work-profile-discription section-discription text-start">
                          <i className="fa-regular fa-circle-check"></i> 1
                          Repeat Client
                        </p>
                      </div>
                    </div>
                    <p className="how-work-profile-love section-discription text-start">
                      "I love serene walks and good weather"
                    </p>
                  </div>
                  <div className="how-work-slider-item">
                    <div className="how-work-profile">
                      <div className="how-work-profile-img">
                        <img
                          src="/images/featured_3.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="how-work-profile-details">
                        <h6 className="how-work-profile-title">Mark S.</h6>
                        <p className="how-work-profile-elite">
                          <i className="fa-solid fa-circle-check"></i>
                          <span className="section-discription">
                            ID Verified
                          </span>
                        </p>
                        <div className="how-work-profile-review">
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <p className="mb-0 section-discription">
                            5.0 <span>(12 reviews)</span>{" "}
                          </p>
                        </div>
                        <p className="how-work-profile-discription section-discription text-start">
                          <i className="fa-regular fa-circle-check"></i> 12
                          Moments
                        </p>
                        <p className="how-work-profile-discription section-discription text-start">
                          <i className="fa-regular fa-circle-check"></i> 1
                          Repeat Client
                        </p>
                      </div>
                    </div>
                    <p className="how-work-profile-love section-discription text-start">
                      "I love discovering new cuisines”
                    </p>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>
        {/* Featured Section End */}

        {/* Mission Section Start */}
        <section className="mission-section">
          <div className="container">
            <div className="mission-containet">
              <div className="mission-tree-img text-center">
                <img
                  src="/images/misson_tree.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="mission-details text-center">
                <h2 className="mission-details-title">
                  Our Mission At Plus One
                </h2>
                <p className="mission-details-discription">
                  We're here to be there for you. We're on a mission to help{" "}
                  <br className="d-none d-lg-block" /> people to experience more
                  moments with good company.
                </p>
              </div>
              <div className="mission-men-img text-center">
                <img
                  src="/images/mission_men.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>
        {/* Mission Section End */}

        {/* FAQ Section Start */}
        <section className="faq-section">
          <div className="container">
            <div className="faq-content">
              <h2 className="faq-title mission-details-title">FAQ</h2>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item ">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What Is Plus One?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body p-0">
                      <p className="faq-discription">
                        Plus One is a tech-enabled service for people that want
                        someone to keep them company for a few hours.Whether you
                        or a loved one are experiencing boredom. Loneliness or.
                        Want a plus one to visit a cool new place – Plus One
                        will match you with the right Human to keep you
                        company.Plus Ones are Independent Contractors that help
                        our users and their loved ones by being physically
                        present with them. Whenever and wherever they want.Get
                        started by booking a Moment today.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      How Does It Work?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body p-0">
                      <p className="faq-discription">
                        Plus One is a tech-enabled service for people that want
                        someone to keep them company for a few hours.Whether you
                        or a loved one are experiencing boredom. Loneliness or.
                        Want a plus one to visit a cool new place – Plus One
                        will match you with the right Human to keep you
                        company.Plus Ones are Independent Contractors that help
                        our users and their loved ones by being physically
                        present with them. Whenever and wherever they want.Get
                        started by booking a Moment today.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      What Is A Plus One?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body p-0">
                      <p className="faq-discription">
                        Plus One is a tech-enabled service for people that want
                        someone to keep them company for a few hours.Whether you
                        or a loved one are experiencing boredom. Loneliness or.
                        Want a plus one to visit a cool new place – Plus One
                        will match you with the right Human to keep you
                        company.Plus Ones are Independent Contractors that help
                        our users and their loved ones by being physically
                        present with them. Whenever and wherever they want.Get
                        started by booking a Moment today.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      Are Plus Ones Therapists Or Specialists?
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body p-0">
                      <p className="faq-discription">
                        Plus One is a tech-enabled service for people that want
                        someone to keep them company for a few hours.Whether you
                        or a loved one are experiencing boredom. Loneliness or.
                        Want a plus one to visit a cool new place – Plus One
                        will match you with the right Human to keep you
                        company.Plus Ones are Independent Contractors that help
                        our users and their loved ones by being physically
                        present with them. Whenever and wherever they want.Get
                        started by booking a Moment today.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ">
                  <h2 className="accordion-header" id="headingFive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      What Is A Moment?
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body p-0">
                      <p className="faq-discription">
                        Plus One is a tech-enabled service for people that want
                        someone to keep them company for a few hours.Whether you
                        or a loved one are experiencing boredom. Loneliness or.
                        Want a plus one to visit a cool new place – Plus One
                        will match you with the right Human to keep you
                        company.Plus Ones are Independent Contractors that help
                        our users and their loved ones by being physically
                        present with them. Whenever and wherever they want.Get
                        started by booking a Moment today.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FAQ Section End */}
      </main>

      <Footer />
      {/* Site Content End</div> */}
    </>
  );
};

export default Home;
