import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import OtpInput from "react-otp-input";
import Countdown from "react-countdown";
import { toast } from "react-toastify";
import { REACT_APP_HOST_NAME } from "../baseurl";
import axios from "axios";

const OtpModal = ({
  country_code,
  mobile,
  handleChange,
  handleOtpSubmit,
  otp,
  handleEdit,
  handleOk,
  message,
  letedit = true,
  handleSubmit,
  time,
  timeNow,
  setTimeNow,
  url,
}) => {
  let tempTime = time;
  const [change, setChange] = useState(false);

  useEffect(() => {
    setChange(false);
  }, [
    country_code,
    mobile,
    handleChange,
    handleOtpSubmit,
    otp,
    handleEdit,
    handleOk,
    message,
    handleSubmit,
    time,
    timeNow,
    setTimeNow,
    url,
  ]);
  const [reflect, setReflect] = useState(false);
  const handleResend = () => {
    if (url) {
      axios
        .post(
          `${REACT_APP_HOST_NAME}/apps/${url}`,
          {
            country_code: country_code,
            mobile: mobile,
          },
          {
            headers: {
              // "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )

        .then((response) => {
          setChange(false);

          setTimeNow(Date.now() + 59000);
          setReflect(!reflect);
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log("error", error.response.data.message);
        });
    } else {
      handleSubmit();
    }
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      setChange(true);
    } else {
      // Render a countdown
      return <span>{seconds}</span>;
    }
  };
  return (
    <>
      <div className="modal-dialog login-modal-dialog" style={{}}>
        <div className="modal-content login-modal-content">
          <div className="modal-content login-modal-close">
            <a
              className="d-flex justify-content-end"
              onClick={handleOk}
              style={{
                padding: "0px 15px",
                fontSize: "25px",
                textDecoration: "none",
                color: "black",
              }}
            >
              x
            </a>
          </div>
          <div className="modal-body login-modal-body">
            <h5 className="login-modal-title">
              Enter the 4-digit code sent to you at +{country_code} {mobile}
            </h5>
            <div className="login-modal-input">
              <OtpInput
                className="otp-field"
                onChange={handleChange}
                numInputs={4}
                separator={<span></span>}
                value={otp}
                inputStyle={{
                  width: "60px",
                  borderRadius: "8px",
                }}
                isInputNum
              />
            </div>
            <div
              style={{
                color: "red",
                fontSize: "13px",
                marginLeft: "30px",
                marginBottom: "10px",
              }}
            >
              {message}
            </div>
            <div className="login-otp-resend">
              {!change ? (
                <a>
                  Resend code in&nbsp;
                  <Countdown date={timeNow} renderer={renderer} /> seconds
                </a>
              ) : (
                <a>
                  <h6 style={{ color: "#008ef9" }} onClick={handleResend}>
                    Resent
                  </h6>
                </a>
              )}
              {letedit && <a onClick={handleOk}>Edit my mobile number</a>}
            </div>
            <div className="login-modal-btn text-center">
              <button
                type="submit"
                className="btn btn-warning"
                onClick={(e) => handleOtpSubmit(e)}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default OtpModal;
