import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import firebase from "./Firebase";
import moment from "moment";

export const ChatRoom = (props) => {
  const [content, setContent] = useState("");
  const [chats, setChats] = useState([]);
  const { userChat } = props;
  const sendChat = (e) => {
    e.preventDefault();
    console.log(content, "aapka message");
    setChats([...chats, {
      content,
      created_at: Date.now(),
      delivered_at: new Date(),
      from: localStorage.getItem("PO_USER_ID"),
      read: false,
      read_at: null,
      to: userChat.to_id
    }])
    let msg = content;
    setContent("");
    firebase.firestore().collection('Chat_Users').doc(`${localStorage.getItem('PO_USER_ID')}-${userChat.to_id}-${userChat.moment_id}`)
      .collection('Chats').add({
        content: msg,
        created_at: Date.now(),
        delivered_at: new Date(),
        from: localStorage.getItem("PO_USER_ID"),
        read: false,
        read_at: null,
        to: "63a18956bf0cebe2957258a9"
      }).then((docRef) => {
        firebase.firestore().collection('Chat_Users').doc(`${localStorage.getItem('PO_USER_ID')}-${userChat.to_id}-${userChat.moment_id}`)
          .collection('Chats').doc(docRef.id).update({
            doc_id: docRef.id
          })
        firebase.firestore().collection('Chat_Users').doc(`${localStorage.getItem('PO_USER_ID')}-${userChat.to_id}-${userChat.moment_id}`)
          .update({
            last_message: {
              content: msg,
              from: localStorage.getItem("PO_USER_ID"),
              sent_at: new Date(),
              to: userChat.to_id,
            },
            updated_at: new Date()
          })
        getChat();
      })
  };

  const getChat = () => {
    firebase.firestore().collection('Chat_Users').doc(`${localStorage.getItem('PO_USER_ID')}-${userChat.to_id}-${userChat.moment_id}`)
      .collection('Chats').orderBy('delivered_at').get().then((querySnapshot) => {
        let arr = [];
        querySnapshot.forEach((doc) => {
          arr.push({ ...doc.data() })
        })
        setChats(arr);
      })
  }

  useEffect(() => {
    getChat();
  }, [userChat]);

  return (
    <>
      <div className="rk-message-user-chat-content">
        <div className="massage-print">
          <h6 className="massage-heading-title">{userChat.user_first_name} {userChat.user_last_name}</h6>
          <a>
            <img src="/images/Icon_print.png" alt="" className="img-fluid" />
          </a>
        </div>
        <div className="user-massage-chat">
          <div className="rk-user-chat-body" data-scrollbar>
            {/* <span className="message-profile-chart-time">July 27, 2022</span> */}
            {chats.map((chat, index) => chat.from === localStorage.getItem('PO_USER_ID') ?
              <div
                className="rk-user-chat-message rk-current-user-chat-message"
                key={index}
              >
                <ul className="rk-user-chat-message-list">
                  <li className="rk-user-chat-message-item">
                    {chat.content}
                    <span className="user-chat-time">{new Date(chat.created_at).getHours()}:{new Date(chat.created_at).getMinutes()}</span>
                  </li>

                </ul>
              </div>
              :
              <div
                className="rk-user-chat-message"
                key={index}
              >
                <ul className="rk-user-chat-message-list">
                  <li className="rk-user-chat-message-item">
                    {chat.content}
                    <span className="user-chat-time" style={{ color: "black" }}>{new Date(chat.created_at).getHours()}:{new Date(chat.created_at).getMinutes()}</span>
                  </li>
                </ul>
                {index === chat.length - 1 ? <span className="user-massage-read-time">
                  <b>Read</b> 8:03
                </span> : ""}
              </div>
            )}
          </div>
          <div className="rk-user-chat-input ">
            <input
              type="text"
              className="form-control"
              placeholder="Message your Plus One"
              onChange={(e) => setContent(e.target.value)}
              value={content}
            />
            <a
              href="javascript:void(0)"
              className="message-profile-select-item"
            >
              <img src="/images/plus.png" alt="" className="img-fluid" />
            </a>
            <a
              className="message-profile-send-item"
              onClick={sendChat}
            >
              <img src="/images/Icon_Send.png" alt="" className="img-fluid" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
