import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Header from "./Header";
import axios from "axios";
import { REACT_APP_HOST_NAME } from "../baseurl";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import firebase from "./Firebase";
import io from "socket.io-client";
import { SERVER_URL } from "../baseurl";

// const socket = io(SERVER_URL, { transports: ["websocket"] });
const Confirmed = () => {
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [error, setError] = useState({});
  const location = useLocation();
  const data = location.state.data;
  const detail = location.state.detail;
  const [topRated, setTopRated] = useState([]);
  const [date, setDate] = useState("");
  const [reason, setReason] = useState([]);
  const [otherReason, setOtherReason] = useState();
  const nav = useNavigate();
  const [msg, setMsg] = useState("");

  const validate = () => {
    let err = {};
    let isValid = false;
    if (!startTime) {
      err["sTime_err"] = "Please Select Start Time";
    }
    if (!endTime) {
      err["eTime_err"] = "Please Select End Time";
    }
    if (startTime && endTime) {
      if (startTime < endTime) {
        isValid = true;
      } else {
        err["seTime_err"] = "End time not Greater than Start Time";
      }
    }
    setError(err);
    return isValid;
  };

  const containerStyle = {
    width: "100%",
    height: "200px",
    border: "1px solid grey",
    borderRadius: "8px",
  };
  const changeTime = (time) => {
    const [hourString, minute] = time.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
  };

  const getTopRated = () => {
    const token = localStorage.getItem("PO_TOKEN");
    axios({
      method: "get",
      url: `${REACT_APP_HOST_NAME}/apps/all-topRatedMoments`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setTopRated(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleUpdate = () => {
    const weekday = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const d = new Date(date);
    let day = weekday[d.getDay()];
    const res = location.state.res;
    if (validate()) {
      const time = changeTime(startTime) + " - " + changeTime(endTime);
      const duration = parseInt(endTime) - parseInt(startTime);
      const token = localStorage.getItem("PO_TOKEN");
      var body = new URLSearchParams();
      body.append("new_time", time);
      body.append("moment_id", res.id);
      body.append(
        "updated_by",
        detail.is_switch_to_contractor ? "user" : "plus_one"
      );
      body.append("new_duration", parseInt(duration));
      body.append("week_day", day.toString());
      body.append("date", date.toString());
      axios({
        method: "post",
        url: `${REACT_APP_HOST_NAME}/apps/update-moment-time`,
        headers: { Authorization: `Bearer ${token}` },
        data: body,
      })
        .then((res) => {
          // socket.emit("new notification", res.data.data.notification);

          // socket.emit("send notification", res.data.data.notification);
          console.log(res);

          CancelUpdate();
          toast.success(res.data.message);
        })
        .catch((err) => {
          console.log("err", err.response.data.message);
        });
    }
  };

  const handleCancel = () => {
    let reasons = [...reason];
    if (reasons.includes("others")) {
      let index = reasons.indexOf("others");
      reasons[index] = otherReason;
    }
    const res = location.state.res;
    const token = localStorage.getItem("PO_TOKEN");
    var body = new URLSearchParams();
    body.append("moment_id", res.id);
    body.append("cancellation_reason", reasons);
    body.append("cancelled_by", "user");
    axios({
      method: "post",
      url: `${REACT_APP_HOST_NAME}/apps/cancel-moment`,
      headers: { Authorization: `Bearer ${token}` },
      data: body,
    })
      .then((res) => {
        toast.success(res.data.message);
        setTimeout(() => {
          nav("/home");
        }, 2000);
      })
      .catch((err) => {
        console.log("err", err.res.data.message);
      });
  };

  const CancelUpdate = () => {
    setStartTime(0);
    setEndTime(0);
    setError("");
  };

  const add3Dots = (string, limit) => {
    var dots = "...";
    if (string.length > limit) {
      string = string.substring(0, limit) + dots;
    }
    return string;
  };

  const handleReason = (e) => {
    if (e.target.checked) {
      let res = [...reason];
      res.push(e.target.value);
      setReason(res);
    } else {
      let res = [...reason];
      let index = res.indexOf(e.target.value);
      res.splice(index, 1);
      setReason(res);
    }
  };

  const createRoom = () => {
    firebase
      .firestore()
      .collection("Chat_Users")
      .doc(
        `${localStorage.getItem("PO_USER_ID")}-${location.state.id}-${location.state.res.id
        }`
      )
      .set({
        created_at: new Date(),
        optional_id: location.state.res.id,
        room_id: `${localStorage.getItem("PO_USER_ID")}-${location.state.id}-${location.state.res.id
          }`,
        user_ids: [localStorage.getItem("PO_USER_ID"), location.state.id],
      });
  };

  const sendMsg = (e) => {
    e.preventDefault();
    firebase
      .firestore()
      .collection("Chat_Users")
      .doc(
        `${localStorage.getItem("PO_USER_ID")}-${location.state.id}-${location.state.res.id
        }`
      )
      .collection("Chats")
      .add({
        content: msg,
        created_at: Date.now(),
        delivered_at: new Date(),
        from: localStorage.getItem("PO_USER_ID"),
        read: false,
        read_at: null,
        to: location.state.id,
      })
      .then((docRef) => {
        firebase
          .firestore()
          .collection("Chat_Users")
          .doc(
            `${localStorage.getItem("PO_USER_ID")}-${location.state.id}-${location.state.res.id
            }`
          )
          .collection("Chats")
          .doc(docRef.id)
          .update({
            doc_id: docRef.id,
          });
        firebase
          .firestore()
          .collection("Chat_Users")
          .doc(
            `${localStorage.getItem("PO_USER_ID")}-${location.state.id}-${location.state.res.id
            }`
          )
          .update({
            last_message: {
              content: msg,
              from: localStorage.getItem("PO_USER_ID"),
              sent_at: new Date(),
              to: location.state.id,
            },
            updated_at: new Date(),
          });
        setMsg("");
      });
  };

  useEffect(() => {
    getTopRated();
    createRoom();
  }, []);

  return (
    <>
      <Header />
      {/* Confirmed Section Start */}
      <section className="confirmed-section">
        <div className="container">
          <div className="confirmed-content">
            <div className="condirmed-heading">
              <a
                href="#"
                className="text-decoration-none information-form-back"
              >
                <i className="fa-solid fa-arrow-left"></i>
              </a>
              <h2 className="confirmend-title">
                Your Moment is Booked!
                <img
                  src="/images/circle_check.png"
                  alt=""
                  className="img-fluid"
                />
              </h2>
            </div>
            <div className="row">
              <div className="col col-12 col-md-12 col-lg-6 col-xl-6">
                <div className="confirmed-address">
                  <div className="massage-moment-profile">
                    {topRated.map((elem) =>
                      elem.id === location.state.data.topRatedId ? (
                        <div className="r-book-payment-celebration mt-0 justify-content-start">
                          <div className="r-book-celebration-img">
                            <img
                              src={elem.image}
                              alt=""
                              className="r-book-img"
                            />
                          </div>
                          <div className="r-book-celebration-details">
                            <h6 className="r-book-celebration-subtitle">
                              You're going to
                            </h6>
                            <h6 className="r-book-celebration-title">
                              {elem.name}
                            </h6>
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    )}
                    <div className="confirmed-address-input">
                      <label className="form-label">Message your PlusOne</label>
                      <div className="confirmed-input-box">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Write your message..."
                          value={msg}
                          onChange={(e) => {
                            setMsg(e.target.value);
                          }}
                        />
                        <a>
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.7733 2.08782C10.089 1.65239 9.23418 1.65239 8.54989 2.08782L2.26392 6.08782C1.61308 6.50198 1.21484 7.24793 1.21484 8.05291V17.1093C1.21484 18.3749 2.18227 19.4009 3.37565 19.4009H15.9476C17.141 19.4009 18.1084 18.3749 18.1084 17.1093V8.05291C18.1084 7.24793 17.7101 6.50198 17.0593 6.08782L10.7733 2.08782ZM9.15628 3.15969C9.46733 2.96176 9.8559 2.96176 10.1669 3.15969L16.4529 7.15969C16.7487 7.34794 16.9298 7.68701 16.9298 8.05291V17.1093C16.9298 17.6846 16.49 18.1509 15.9476 18.1509H3.37565C2.8332 18.1509 2.39346 17.6846 2.39346 17.1093V8.05291C2.39346 7.68701 2.57448 7.34794 2.87032 7.15969L9.15628 3.15969ZM6.07079 9.93066C5.80415 9.73272 5.4367 9.80149 5.25006 10.0843C5.06341 10.367 5.12826 10.7568 5.39489 10.9547L9.32362 13.8714C9.52653 14.022 9.79661 14.022 9.99952 13.8714L13.9282 10.9547C14.1949 10.7568 14.2597 10.367 14.0731 10.0843C13.8864 9.80149 13.519 9.73272 13.2524 9.93066L9.66157 12.5964L6.07079 9.93066Z"
                              fill="#008EF9"
                            />
                          </svg>
                        </a>
                        <a onClick={sendMsg}>
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M13.1674 2.71202L17.2922 6.83681C17.5363 7.08089 17.5363 7.47661 17.2922 7.72069L10.263 14.7499C9.98952 15.0234 9.61859 15.177 9.23181 15.177L5.45221 15.177C5.10703 15.177 4.82721 14.8972 4.82721 14.552L4.8272 10.7724C4.8272 10.3856 4.98085 10.0147 5.25434 9.74121L12.2835 2.71202C12.5276 2.46794 12.9233 2.46794 13.1674 2.71202ZM14.4932 8.75189L15.9664 7.27875L12.7255 4.03784L11.2523 5.51098L14.4932 8.75189ZM13.6094 9.63577L10.3685 6.39487L6.13822 10.6251C6.09915 10.6642 6.0772 10.7172 6.0772 10.7724L6.07721 13.927L9.23182 13.927C9.28707 13.927 9.34006 13.9051 9.37913 13.866L13.6094 9.63577ZM2.68359 17.529C2.33842 17.529 2.05859 17.8088 2.05859 18.154C2.05859 18.4992 2.33842 18.779 2.68359 18.779H17.6836C18.0288 18.779 18.3086 18.4992 18.3086 18.154C18.3086 17.8088 18.0288 17.529 17.6836 17.529H2.68359Z"
                              fill="#008EF9"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div className="confirmed-profile-details massage-moment-profile-details d-flex">
                      <img
                        src={detail.profile_pic}
                        alt=""
                        className="img-fluid"
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                      <p style={{ fontSize: "17px" }}>
                        Your Plus One <b>{detail.first_name}</b> will meet you
                        here:
                      </p>
                    </div>
                  </div>

                  <div className="massage-momemt-map mt-0">
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={{
                        lat: data.location.latitude,
                        lng: data.location.longitude,
                      }}
                      zoom={18}
                    >
                      <Marker
                        position={{
                          lat: data.location.latitude,
                          lng: data.location.longitude,
                        }}
                      />
                    </GoogleMap>
                  </div>
                  <div className="massage-update-moment">
                    <p className="order-address-link">
                      <img
                        src="/images/Calendar.png"
                        alt=""
                        className="img-fluid"
                      />
                      {data.day} - {moment(data.date).format("MMM DD, YYYY")}
                    </p>
                    <p className="order-address-link">
                      <img
                        src="/images/Icon_Time.png"
                        alt=""
                        className="img-fluid"
                      />
                      {data.time}
                    </p>
                    <p className="order-address-link">
                      <img
                        src="/images/pin_location.png"
                        alt=""
                        className="img-fluid"
                      />
                      {add3Dots(data.add, 50)}
                    </p>
                    <div className="massage-update-edite">
                      <a
                        // onClick={() => setUpdateToggle(false)}
                        data-bs-toggle="modal"
                        data-bs-target="#updateModal"
                        className="text-decoration-none"
                      >
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M16.2744 5.96538C16.5184 5.7213 16.5184 5.32557 16.2744 5.0815L12.1496 0.956706C11.9055 0.712628 11.5098 0.712628 11.2657 0.956706L0.90317 11.3192C0.62968 11.5927 0.476034 11.9637 0.476034 12.3504L0.476034 16.13C0.476034 16.4752 0.755856 16.755 1.10103 16.755L4.88065 16.755C5.26742 16.755 5.63835 16.6014 5.91184 16.3279L16.2744 5.96538ZM14.9485 5.52344L13.4754 6.99658L10.2345 3.75567L11.7076 2.28253L14.9485 5.52344ZM9.35061 4.63955L12.5915 7.88046L5.02796 15.444C4.98889 15.4831 4.9359 15.505 4.88065 15.505L1.72603 15.505L1.72603 12.3504C1.72603 12.2952 1.74798 12.2422 1.78705 12.2031L9.35061 4.63955Z"
                            fill="#FFBB00"
                          />
                        </svg>{" "}
                        Update Moment
                      </a>
                      <a
                        // onClick={() => setCancelToggle(false)}
                        className="mb-0"
                        data-bs-toggle="modal"
                        data-bs-target="#confirmcancelModal"
                      >
                        Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-12 col-md-12 col-lg-5 col-xl-5 p-0">
                <div className="confirmed-chat text-center">
                  <div className="comfirmed-help-img">
                    <img src="/images/moment_gift.png" className="img-fluid" />
                  </div>
                  <h5 className="confirmed-help-title r-book-celebration-title">
                    Help your friends, Get $10
                  </h5>
                  <p className="confirmed-help-discription">
                    Help a busy friend! Send them a $10 credit and get $10 when
                    they complete their first moment.
                  </p>
                  <div className="confirmed-help-btn">
                    <a href="#" className="btn">
                      Share
                    </a>
                    {/* <a href="#" className="btn mt-3" data-bs-toggle="modal" data-bs-target="#requestingModal">View Update</a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Confirmed Section End */}
      <div
        className="modal fade confirmcancel-modal"
        id="confirmcancelModal"
        tabindex="-1"
        aria-labelledby="confirmcancelModal"
        aria-hidden="true"
      >
        <div className="modal-dialog requesting-modal-dialog">
          <div className="modal-content requesting-modal-content">
            <div className="modal-body requesting-modal-body text-center">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2521_10929)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.0559 4.05543C4.3575 3.75383 4.84649 3.75383 5.14809 4.05543L10.0005 8.90781L14.8529 4.05543C15.1545 3.75383 15.6434 3.75383 15.945 4.05543C16.2466 4.35703 16.2466 4.84602 15.945 5.14761L11.0927 10L15.945 14.8524C16.2466 15.154 16.2466 15.643 15.945 15.9446C15.6434 16.2462 15.1545 16.2462 14.8529 15.9446L10.0005 11.0922L5.14809 15.9446C4.84649 16.2462 4.3575 16.2462 4.0559 15.9446C3.7543 15.643 3.7543 15.154 4.0559 14.8524L8.90829 10L4.0559 5.14761C3.7543 4.84602 3.7543 4.35703 4.0559 4.05543Z"
                      fill="#ACB4BA"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2521_10929">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <h2 className="requesting-modal-title massage-moment-title mt-0">
                Are you sure you want to cancel your Moment?
              </h2>
              <p className="requesting-modal-discription">
                Cancelling your moment may deduct $20 on your credit card.
              </p>
              <a
                href="#"
                className="btn btn-warning w-100"
                data-bs-dismiss="modal"
                data-bs-toggle="modal"
                data-bs-target="#cancelprofileModal"
              // onClick={handleCancel}
              >
                Yes, I’d like to cancel
              </a>
              <a href="#" className="btn w-100 confirmcancel-btn">
                No
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade confirmcancel-modal"
        id="updateModal"
        tabindex="-1"
        aria-labelledby="confirmcancelModal"
        aria-hidden="true"
      >
        <div className="modal-dialog requesting-modal-dialog">
          <div className="modal-content requesting-modal-content">
            <div className="modal-body requesting-modal-body text-center">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={CancelUpdate}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2521_10929)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.0559 4.05543C4.3575 3.75383 4.84649 3.75383 5.14809 4.05543L10.0005 8.90781L14.8529 4.05543C15.1545 3.75383 15.6434 3.75383 15.945 4.05543C16.2466 4.35703 16.2466 4.84602 15.945 5.14761L11.0927 10L15.945 14.8524C16.2466 15.154 16.2466 15.643 15.945 15.9446C15.6434 16.2462 15.1545 16.2462 14.8529 15.9446L10.0005 11.0922L5.14809 15.9446C4.84649 16.2462 4.3575 16.2462 4.0559 15.9446C3.7543 15.643 3.7543 15.154 4.0559 14.8524L8.90829 10L4.0559 5.14761C3.7543 4.84602 3.7543 4.35703 4.0559 4.05543Z"
                      fill="#ACB4BA"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2521_10929">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <div className="row">
                <div className="set-schedule-input">
                  <label className="set-schedule-input-title">
                    <span>1</span>
                    Set Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                  <span
                    style={{
                      color: "red",
                      fontWeight: "500",
                      fontSize: "12px",
                    }}
                  >
                    {error.date_err}
                  </span>
                </div>
                <div className="set-schedule-input">
                  <label className="set-schedule-input-title">
                    <span>2</span>
                    Set Time
                  </label>
                  <div className="row mt-2">
                    <div className="col col-6">
                      <label className="form-label">Start Time</label>
                      <input
                        type="time"
                        className="form-control"
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                      />
                      <span
                        style={{
                          color: "red",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                      >
                        {error.sTime_err}
                      </span>
                    </div>
                    <div className="col col-6">
                      <label className="form-label">End Time</label>
                      <input
                        type="time"
                        className="form-control"
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                      />
                      <span
                        style={{
                          color: "red",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                      >
                        {error.eTime_err}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <a
                href="#"
                className="btn btn-warning w-100"
                data-bs-dismiss="modal"
                data-bs-toggle="modal"
                data-bs-target="#updateModal"
                onClick={handleUpdate}
              >
                Select & Continue
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade cancel-profile"
        id="cancelprofileModal"
        tabindex="-1"
        aria-labelledby="cancelprofileModal"
        aria-hidden="true"
      >
        <div className="modal-dialog requesting-modal-dialog">
          <div className="modal-content requesting-modal-content">
            <div className="modal-body cancel-profile-mbody">
              <div className="cancel-profile-heading">
                <a className="text-decoration-none information-form-back mb-0">
                  <i className="fa-solid fa-arrow-left"></i>
                </a>
                <h2 className="requesting-modal-title massage-moment-title my-0 ">
                  Cancel your Moment
                </h2>
              </div>
              <div className="cancel-profile-reason">
                <h6 className="cancel-profile-title">
                  Reason for cancellation (optional):
                </h6>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="Conflicting schedule"
                    id="Checkreason1"
                    checked={reason.includes("Conflicting schedule")}
                    onChange={handleReason}
                  />
                  <label
                    className="form-check-label cancel-profile-title mb-0"
                    for="Checkreason1"
                  >
                    Conflicting schedule
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="Emergency"
                    id="Checkreason2"
                    checked={reason.includes("Emergency")}
                    onChange={handleReason}
                  />
                  <label
                    className="form-check-label cancel-profile-title mb-0"
                    for="Checkreason2"
                  >
                    Emergency
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="Changed my mind"
                    id="Checkreason3"
                    checked={reason.includes("Changed my mind")}
                    onChange={handleReason}
                  />
                  <label
                    className="form-check-label cancel-profile-title mb-0"
                    for="Checkreason3"
                  >
                    Changed my mind
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="others"
                    id="Checkreason4"
                    checked={reason.includes("others")}
                    onChange={handleReason}
                  />
                  <label
                    className="form-check-label cancel-profile-title mb-0"
                    for="Checkreason4"
                  >
                    Others (please specify)
                  </label>
                </div>
              </div>
              {reason.includes("others") ? (
                <div className="cancel-profile-input">
                  <textarea
                    className="form-control"
                    id="other-reason"
                    cols="30"
                    rows="4"
                    onChange={(e) => setOtherReason(e.target.value)}
                  ></textarea>
                </div>
              ) : (
                ""
              )}
              <div className="cancel-profile-btn text-center">
                <a
                  href="#"
                  className="btn btn-warning"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#cancelledModal"
                  onClick={handleCancel}
                >
                  Submit
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Confirmed;
