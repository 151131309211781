import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { REACT_APP_HOST_NAME } from "../baseurl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useGoogleLogout } from "react-google-login";

const Header = () => {
  const [user, setUser] = useState({});
  const nav = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("PO_TOKEN");
    axios({
      method: "get",
      url: `${REACT_APP_HOST_NAME}/apps/view-profile`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setUser(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        localStorage.removeItem("PO_TOKEN");
        nav("/");
      });
  }, []);

  const handlelogOut = () => {
    localStorage.removeItem("PO_TOKEN");
    nav("/");
    signOut();
  };
  const { signOut } = useGoogleLogout({
    clientId: "201615903518-fur4i8ph7aut8nigrgdsjus97sb1onsn.apps.googleusercontent.com",
  })

  const [loading, setLoading] = useState(true);
  return (
    <>
      <SkeletonTheme baseColor="#c0c0c0" highlightColor="#F0F0F0">
        <header className="po-site-header">
          <div className="container">
            <div className="header-content">
              <div className="header-logo">
                <a className="navbar-brand" href="javascript:void(0)">
                  <img
                    src="/images/logo.png"
                    alt="Logo"
                    className="w-100"
                    onClick={() => {
                      nav("/home");
                    }}
                  />
                </a>
              </div>

              <div className="header-dropdown">
                <p
                  className="header-dropdown-title mb-0"
                  onClick={() => {
                    nav("/contractor_login");
                  }}
                >
                  Become a Plus One
                </p>
                {!loading ? (
                  <div className="dropdown">
                    <button
                      className="btn header-dropdown-btn"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="/images/Icon_Menu.png"
                        alt=""
                        className="img-fluid"
                      />
                      <img
                        src={user.profile_pic}
                        style={{
                          objectFit: "cover",
                          height: "30px",
                          width: "30px",
                        }}
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu border-0"
                      aria-labelledby="dropdownMenuButton1"
                      style={{ width: "250px", padding: "20px 0" }}
                    >
                      <li
                        style={{ padding: "5px 20px" }}
                        onClick={() => {
                          // nav("/message");
                        }}
                      >
                        <a className="dropdown-item">
                          <b>Message</b>
                        </a>
                      </li>{" "}
                      <li
                        style={{ padding: "5px 20px" }}
                        onClick={() => {
                          nav("/notification");
                        }}
                      >
                        <a className="dropdown-item">
                          <b>Notification</b>
                        </a>
                      </li>{" "}
                      <li
                        style={{ padding: "5px 20px" }}
                        onClick={() => {
                          nav("/scheduled");
                        }}
                      >
                        <a className="dropdown-item">
                          <b>Moments</b>
                        </a>
                      </li>
                      <li>
                        <hr
                          className="dropdown-divider"
                          style={{ color: "#ACB4BA", height: "0.5px" }}
                        />
                      </li>
                      <li style={{ padding: "5px 20px" }}>
                        <a className="dropdown-item">Book Moment</a>
                      </li>
                      <li style={{ padding: "5px 20px" }}>
                        <a
                          onClick={() => nav("/profile")}
                          className="dropdown-item"
                        >
                          Profile
                        </a>
                      </li>
                      <li>
                        <hr
                          className="dropdown-divider"
                          style={{ color: "#ACB4BA", height: "0.5px" }}
                        />
                      </li>
                      <li style={{ padding: "5px 20px" }}>
                        <a className="dropdown-item">Help</a>
                      </li>
                      <li style={{ padding: "5px 20px" }}>
                        <a onClick={handlelogOut} className="dropdown-item">
                          Log Out
                        </a>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div>
                    <Skeleton count={1} className="sk-profile"></Skeleton>
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>
      </SkeletonTheme>
    </>
  );
};

export default Header;
