import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import NavBar from "./NavBar";

const Refer = () => {
  return (
    <>
      {/*  Refer Section Start  */}
      {/* <section className="refer-section">
            <div className="container">
            <div className="refer-content">
                <h2 className="refer-main-title">
                Earn $15 for every new Plus One you refer
                </h2>
                <p className="refer-discription">
                Help a busy friend! Send them a $10 credit and get $10 when they
                complete their first moment.
                </p>
                <a href="javascript:void(0)" className="policy-rules-title ferer-share-link">
                Copy link to share below
                </a>
                <div className="refer-input">
                <input type="text" placeholder="www.plusone.com/r/stacyanne" />
                <a
                    href="javascript:void(0)"
                    className="btn btn-warning refer-btn mt-0 forgot-pass-btn"
                >
                    Share
                </a>
                </div>
                <div className="refer-work-content">
                <h2 className="refer-work-title">How referrals work</h2>
                <div className="row">
                    <div className="col col-12 col-md-6 col-lg-4 col-xl-4">
                    <div className="refer-work-item">
                        <h6 className="refer-work-item-no">1</h6>
                        <h2 className="refer-work-item-title">Moment updates</h2>
                        <p className="refer-work-discription">
                        Send referrals to your friends either here or on the web
                        </p>
                    </div>
                    </div>
                    <div className="col col-12 col-md-6 col-lg-4 col-xl-4">
                    <div className="refer-work-item">
                        <h6 className="refer-work-item-no">2</h6>
                        <h2 className="refer-work-item-title">Follow along</h2>
                        <p className="refer-work-discription">
                        You can track when they create their listing, host their
                        first stay, and more.
                        </p>
                    </div>
                    </div>
                    <div className="col col-12 col-md-6 col-lg-4 col-xl-4">
                    <div className="refer-work-item">
                        <h6 className="refer-work-item-no">3</h6>
                        <h2 className="refer-work-item-title">Get paid</h2>
                        <p className="refer-work-discription">
                        When your firneds starts to be a Plus One, you’ll get paid
                        after their first guest checks out.
                        </p>
                    </div>
                    </div>
                </div>
                </div>
                <div className="refer-questions">
                <h2 className="refer-questions-title refer-work-title">
                    Frequently Asked Questions
                </h2>
                <div className="row">
                    <div className="col col-12 col-md-6 col-lg-5 col-xl-5">
                    <ul className="refer-questions-list">
                        <li className="refer-questions-item">
                        <a href="javascript:void(0)" className="refer-questions-link">
                            Where can I read the full terms?
                        </a>
                        </li>
                        <li className="refer-questions-item">
                        <a href="javascript:void(0)" className="refer-questions-link">
                            Who can I refer?
                        </a>
                        </li>
                        <li className="refer-questions-item">
                        <a href="javascript:void(0)" className="refer-questions-link">
                            What is a qualifying stay?
                        </a>
                        </li>
                        <li className="refer-questions-item">
                        <a href="javascript:void(0)" className="refer-questions-link">
                            How do I get credit for my referrals?
                        </a>
                        </li>
                    </ul>
                    </div>
                    <div className="col col-12 col-md-6 col-lg-6 col-xl-6">
                    <ul className="refer-questions-list">
                        <li className="refer-questions-item">
                        <a href="javascript:void(0)" className="refer-questions-link">
                            When do I get my cash reward?
                        </a>
                        </li>
                        <li className="refer-questions-item">
                        <a href="javascript:void(0)" className="refer-questions-link">
                            Does the referral expire?
                        </a>
                        </li>
                        <li className="refer-questions-item">
                        <a href="javascript:void(0)" className="refer-questions-link mb-0">
                            How many referrals can I make?
                        </a>
                        </li>
                    </ul>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section> */}

      <Header />
      {/* Profile sharing Section Start  */}
      <section className="po-sharing-section">
        <div className="po-sharing-content">
          <NavBar />
          <div
            className="po-sharing-form-content m-0"
            style={{ padding: "100px" }}
          >
            <div className="po-sharing-sidebar-icon d-block d-lg-none">
              <i className="fa-solid fa-bars"></i>
            </div>
            <div className="refer-content">
              <h2 className="refer-main-title">
                Earn $15 for every new Plus One you refer
              </h2>
              <p className="refer-discription">
                Help a busy friend! Send them a $10 credit and get $10 when they
                complete their first moment.
              </p>
              <a
                href="javascript:void(0)"
                className="policy-rules-title ferer-share-link"
              >
                Copy link to share below
              </a>
              <div className="refer-input">
                <input type="text" placeholder="www.plusone.com/r/stacyanne" />
                <a
                  href="javascript:void(0)"
                  className="btn btn-warning refer-btn mt-0 forgot-pass-btn"
                >
                  Share
                </a>
              </div>
              <div className="refer-work-content">
                <h2 className="refer-work-title">How referrals work</h2>
                <div className="row">
                  <div className="col col-12 col-md-6 col-lg-4 col-xl-4">
                    <div className="refer-work-item">
                      <h6 className="refer-work-item-no">1</h6>
                      <h2 className="refer-work-item-title">Moment updates</h2>
                      <p className="refer-work-discription">
                        Send referrals to your friends either here or on the web
                      </p>
                    </div>
                  </div>
                  <div className="col col-12 col-md-6 col-lg-4 col-xl-4">
                    <div className="refer-work-item">
                      <h6 className="refer-work-item-no">2</h6>
                      <h2 className="refer-work-item-title">Follow along</h2>
                      <p className="refer-work-discription">
                        You can track when they create their listing, host their
                        first stay, and more.
                      </p>
                    </div>
                  </div>
                  <div className="col col-12 col-md-6 col-lg-4 col-xl-4">
                    <div className="refer-work-item">
                      <h6 className="refer-work-item-no">3</h6>
                      <h2 className="refer-work-item-title">Get paid</h2>
                      <p className="refer-work-discription">
                        When your firneds starts to be a Plus One, you’ll get
                        paid after their first guest checks out.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="refer-questions">
                <h2 className="refer-questions-title refer-work-title">
                  Frequently Asked Questions
                </h2>
                <div className="row">
                  <div className="col col-12 col-md-6 col-lg-5 col-xl-5">
                    <ul className="refer-questions-list">
                      <li className="refer-questions-item">
                        <a
                          href="javascript:void(0)"
                          className="refer-questions-link"
                        >
                          Where can I read the full terms?
                        </a>
                      </li>
                      <li className="refer-questions-item">
                        <a
                          href="javascript:void(0)"
                          className="refer-questions-link"
                        >
                          Who can I refer?
                        </a>
                      </li>
                      <li className="refer-questions-item">
                        <a
                          href="javascript:void(0)"
                          className="refer-questions-link"
                        >
                          What is a qualifying stay?
                        </a>
                      </li>
                      <li className="refer-questions-item">
                        <a
                          href="javascript:void(0)"
                          className="refer-questions-link"
                        >
                          How do I get credit for my referrals?
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col col-12 col-md-6 col-lg-6 col-xl-6">
                    <ul className="refer-questions-list">
                      <li className="refer-questions-item">
                        <a
                          href="javascript:void(0)"
                          className="refer-questions-link"
                        >
                          When do I get my cash reward?
                        </a>
                      </li>
                      <li className="refer-questions-item">
                        <a
                          href="javascript:void(0)"
                          className="refer-questions-link"
                        >
                          Does the referral expire?
                        </a>
                      </li>
                      <li className="refer-questions-item">
                        <a
                          href="javascript:void(0)"
                          className="refer-questions-link mb-0"
                        >
                          How many referrals can I make?
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  Refer Section End  */}
    </>
  );
};

export default Refer;
