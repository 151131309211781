import React, { useState, useEffect } from "react";

import Header from "./Header";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import axios from "axios";
import { REACT_APP_HOST_NAME } from "../baseurl";
import { toast } from "react-toastify";
import CreditCardInput from "react-credit-card-input";

import StripePayment from "./StripePayment";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripComponent from "./StripComponent";
import { usePaymentInputs } from "react-payment-inputs";

function ReviewAndBook2() {
  const stripePromise = loadStripe(
    "pk_test_51KZp1SGTgg2E5zBcVUmNuPWdYn6dxQNIvf9BqGYJabOueYRq3wRTgyshLps5IT7dIHXwVs0jrO9SNH8mTxBmeyHi00VkpI6qjT"
  );
  const location = useLocation();
  const nav = useNavigate();
  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs();

  const voucher = location.state.selectedVoucher;
  const data = location.state.data;
  const id = location.state.id;
  const detail = location.state.detail;
  const [readOnlyCard, setReadOnlyCard] = useState(false);
  const containerStyle = {
    width: "400px",
    height: "200px",
    border: "1px solid grey",
    borderRadius: "8px",
  };
  const [topRated, setTopRated] = useState([]);
  const [error, setError] = useState({});
  const [details, setDetails] = useState({
    name: "",
    card_number: "",
    exp_year: "",
    cvc: "",
    postal_code: "",
    country: "US",
  });
  const [disable, setDisable] = useState(false);

  const getTopRated = () => {
    const token = localStorage.getItem("PO_TOKEN");
    axios({
      method: "get",
      url: `${REACT_APP_HOST_NAME}/apps/all-topRatedMoments`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setTopRated(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleChange = (e) => {
    const newData = { ...details };
    newData[e.target.id] = e.target.value;
    setDetails(newData);
  };

  const add3Dots = (string, limit) => {
    var dots = "...";
    if (string.length > limit) {
      string = string.substring(0, limit) + dots;
    }
    return string;
  };

  const hourlyRate = detail.hourly_rate;
  const duration = data.duration;
  const tax = (hourlyRate * 25) / 100;
  const taxedHourlyRate = hourlyRate + tax;
  const totalAmount = duration * taxedHourlyRate;
  const externalTax = (totalAmount * 10) / 100;
  const taxedTotalAmount = totalAmount + externalTax;

  const validate = () => {
    let isValid = true;
    let err = {};

    if (!details.name) {
      isValid = false;
      err["name_err"] = "Please enter name";
    }
    if (!details.card_number || !details.exp_year || !details.cvc) {
      isValid = false;
      err["card_err"] = "Missing paramter";
    }
    if (!details.postal_code) {
      isValid = false;
      err["zip_err"] = "Please enter postal code";
    }
    if (!details.country) {
      isValid = false;
      err["country_err"] = "Please enter country";
    }

    setError(err);
    return isValid;
  };

  const handleConfirm = async () => {
    if (validate()) {
      setDisable(true);
      const cardType = detectCardType(details.card_number.replaceAll(" ", ""));
      const token = localStorage.getItem("PO_TOKEN");
      var bodyFormData = new URLSearchParams();
      bodyFormData.append("card_number", details.card_number);
      bodyFormData.append("exp_month", details.exp_year.split("/")[0].trim());
      bodyFormData.append("exp_year", details.exp_year.split("/")[1].trim());
      bodyFormData.append("cvc", details.cvc);
      bodyFormData.append(
        "amount",
        voucher ? taxedTotalAmount - voucher.voucher_amount : taxedTotalAmount
      );
      bodyFormData.append("postal_code", details.postal_code);
      bodyFormData.append("country", details.country);
      bodyFormData.append("card_holder_name", details.name);
      bodyFormData.append("plus_one_id", id);
      bodyFormData.append('card_brand', cardType);
      axios({
        method: "post",
        url: `${REACT_APP_HOST_NAME}/apps/checkout-booking`,
        data: bodyFormData,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          setDisable(false);
          // setTopRated(response.data.data);
          console.log(response.data.data);
          if (response.data.success === true) {
            nav("/review_and_book", {
              state: {
                id,
                data,
                detail,
                voucher,
                payment: "done",
                intent: response.data.data.payment_intent_id,
              },
            });
          }
        })
        .catch((error) => {
          setDisable(false)
          console.log("error", error.response.data.message);
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const getCardDetail = () => {
    const token = localStorage.getItem("PO_TOKEN");
    axios({
      method: "get",
      url: `${REACT_APP_HOST_NAME}/apps/defualt-card-detail`,
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      if (response.data.success === true) {
        setReadOnlyCard(true);
        let res = response.data.data;
        console.log(response);
        setDetails({
          card_number: res.card_number,
          exp_year: `${res.expiry_month}/${res.expiry_year}`,
          cvc: res.cvv,
          postal_code: res.zip_code,
          country: res.country
        })
      }
    });
  };

  function detectCardType(number) {
    var re = {
      electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
      maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
      dankort: /^(5019)\d+$/,
      interpayment: /^(636)\d+$/,
      unionpay: /^(62|88)\d+$/,
      visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      mastercard: /^5[1-5][0-9]{14}$/,
      amex: /^3[47][0-9]{13}$/,
      diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}$/
    }

    for (var key in re) {
      if (re[key].test(number)) {
        return key
      }
    }
  }
  useEffect(() => {
    getTopRated();
    getCardDetail();
  }, []);

  return (
    <>
      <Header />
      <div className="container">
        <div className="plusone-payment-fill price-tab-body">
          <div className="plusone-profile-heading align-items-start mb-0">
            <a
              href="javascript:void(0)"
              className="text-decoration-none information-form-back"
              onClick={() => {
                nav("/review_and_book", {
                  state: { id: id, data: data, detail: detail },
                });
              }}
            >
              <i className="fa-solid fa-arrow-left"></i>
            </a>
            <div className="p-method-heading">
              <h2 className="payment-book-title plusone-heading-title">
                Review & Book
              </h2>
              <p className="payment-book-discription mb-0">
                <img src="/images/card.png" alt="" className="img-fluid" /> You
                won't be billed until you're done with your Moment.
              </p>
            </div>
          </div>
          <div className="row g-xl-0">
            <div className="col col-12 col-md-12 col-lg-6 xol-xl-6">
              <div className="plusone-payment-book">
                <div className="payment-book-card-details plusone-profile-price">
                  <div className="payment-book-details-heading">
                    <h5 className="payment-book-heading-title">Pay with</h5>
                    <div className="payment-book-logo">
                      <img
                        src="/images/visa-logo.png"
                        alt=""
                        className="img-fluid"
                      />
                      <img
                        src="/images/amex_logo.png"
                        alt=""
                        className="img-fluid"
                      />
                      <img
                        src="/images/mastercard.png"
                        alt=""
                        className="img-fluid"
                      />
                      <img
                        src="/images/discover.png"
                        alt=""
                        className="img-fluid"
                      />
                      <img
                        src="/images/paypal_logo.png"
                        alt=""
                        className="img-fluid"
                      />
                      <img
                        src="/images/GooglePay.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="payment-card-address">
                    <div className="payment-card-input">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Card Holder Name"
                        id="name"
                        onChange={handleChange}
                      />
                      <div className="text-danger">{error.name_err}</div>
                    </div>
                    <div className="payment-card-input payment-card-no">
                      <input
                        {...getCardNumberProps({ onChange: handleChange })}
                        className="form-control border-top-0 border-end-0 border-start-0 card-lock"
                        placeholder="Card number"
                        name="card_number"
                        id="card_number"
                        value={details.card_number}
                        readOnly={readOnlyCard}
                      />

                      <div className="payment-card-input payment-card-Expiration mb-0">
                        <input
                          {...getExpiryDateProps({ onChange: handleChange })}
                          className="form-control border-0"
                          placeholder="Expiration"
                          name="exp_year"
                          id="exp_year"
                          value={details.exp_year}
                          readOnly={readOnlyCard}
                        />
                        <input
                          {...getCVCProps({ onChange: handleChange })}
                          className="form-control border-top-0 border-end-0 border-bottom-0"
                          placeholder="CVV"
                          name="cvc"
                          id="cvc"
                          value={details.cvc}
                          readOnly={readOnlyCard}
                        />
                      </div>
                    </div>
                    <div className="text-danger">
                      {error.card_err}{" "}
                      {meta.isTouched && meta.error && (
                        <span>Error: {meta.error}</span>
                      )}
                    </div>
                    <div className="payment-card-input">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Postcode"
                        id="postal_code"
                        onChange={handleChange}
                        readOnly={readOnlyCard}
                        value={details.postal_code}
                      />
                      <div className="text-danger">{error.zip_err}</div>
                    </div>
                    <div className="payment-card-input payment-card-select-country">
                      <span>Country/Region</span>
                      <select
                        className="form-select form-control"
                        id="country"
                        onChange={handleChange}
                        readOnly={readOnlyCard}
                        value={details.country}
                      >
                        <option selected value="US">
                          United States of America
                        </option>
                        <option value="UK">United Kingdom</option>
                        <option value="IN">India</option>
                        {/* <option>Algeria</option> */}
                      </select>
                      <div className="text-danger">{error.country_err}</div>
                    </div>
                    <div className="payment-book-btn text-center">
                      <a onClick={handleConfirm} className="btn btn-warning">
                        {disable ? "Processing...." : "Confirm Payment Method"}
                      </a>
                    </div>
                    {/* <Elements stripe={stripePromise}>
                      <StripComponent error={error} readOnlyCard={readOnlyCard} handleChange={handleChange} details={details} disable={disable} handleConfirm={handleConfirm} />
                    </Elements> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-12 col-md-12 col-lg-6 xol-xl-6">
              <div className="plusone-payment-order plusone-profile-price">
                <div className="payment-order-profile plusone-profile">
                  <div className="payment-order-img plusone-profile-img">
                    <img
                      src={detail.profile_pic}
                      alt=""
                      className="img-fluid rounded-circle"
                    />
                  </div>
                  <div className="how-work-profile-details me-0">
                    <h6 className="profile-details-title how-work-profile-title">
                      {detail.first_name} {detail.last_name[0]}.
                    </h6>
                    <p className="how-work-profile-elite mt-0">
                      <i className="fa-solid fa-circle-check"></i>
                      <span className="section-discription">ID Verified</span>
                    </p>
                    <div className="profile-details-star how-work-profile-review">
                      <img
                        src="/images/group_star.png"
                        alt=""
                        className="img-fluid"
                      />
                      <p className="mb-0 profile-details-review section-discription review-payment-method">
                        {detail.rate}{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="p-method-profile-address profile-order-address">
                  <p className="order-address-link">
                    <img
                      src="/images/Calendar.png"
                      alt=""
                      className="img-fluid"
                    />
                    {data.day} - {moment(data.date).format("MMM DD, YYYY")}
                  </p>
                  <p className="order-address-link">
                    <img
                      src="/images/Icon_Time.png"
                      alt=""
                      className="img-fluid"
                    />{" "}
                    {data.time}
                  </p>
                  <p className="order-address-link">
                    <img
                      src="/images/pin_location.png"
                      alt=""
                      className="img-fluid"
                    />
                    {add3Dots(data.add, 30)}
                  </p>
                </div>
                <div className="profile-order-map payment-order-profile">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={{
                      lat: data.location.latitude,
                      lng: data.location.longitude,
                    }}
                    zoom={18}
                  >
                    <Marker
                      position={{
                        lat: data.location.latitude,
                        lng: data.location.longitude,
                      }}
                    />
                  </GoogleMap>
                  {topRated.map((elem) =>
                    elem.id === location.state.data.topRatedId ? (
                      <div className="r-book-payment-celebration">
                        <div className="r-book-celebration-img">
                          <img
                            src={elem.image}
                            style={{
                              height: "70px",
                              width: "70px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="r-book-celebration-details">
                          <h6 className="r-book-celebration-subtitle">
                            You're going to
                          </h6>
                          <h6 className="r-book-celebration-title">
                            {elem.name}
                          </h6>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  )}
                </div>
                <div className="profile-order-price-details payment-order-profile">
                  <h5 className="order-address-title payment-book-heading-title">
                    Price Details
                  </h5>
                  <div className="order-price-details-rate">
                    <p>Hourly Rate</p>
                    <p>${hourlyRate}/hr</p>
                  </div>
                  <div className="order-price-details-rate">
                    <p>Trust & Support Fee</p>
                    <p>$8.00</p>
                  </div>
                  {voucher ? (
                    <div className="order-price-details-rate-voucher">
                      <p className="discount-text">Voucher Discount</p>
                      <p className="discount-text">
                        -${voucher.voucher_amount}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="order-price-details-rate">
                    <h5 className="order-address-title payment-book-heading-title m-0">
                      Total
                    </h5>
                    <h5 className="order-address-title payment-book-heading-title m-0">
                      $
                      {voucher
                        ? parseFloat(
                          taxedTotalAmount - voucher.voucher_amount
                        ).toFixed(2)
                        : parseFloat(taxedTotalAmount).toFixed(2)}
                    </h5>
                  </div>
                </div>
                {/* <div className="profile-order-cancellation">
                  <p className="order-cancellation-title order-address-link">
                    Free cancellation until 3:00 pm on 27 Jul{" "}
                  </p>
                  <p className="order-cancellation-discription order-address-link">
                    After that, cancel before 3:00PM on 28 Jul and get a full
                    refund, minus the first night and service fee.{" "}
                    <a href="javascript:void(0)">Learn more</a>{" "}
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReviewAndBook2;
