import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { REACT_APP_HOST_NAME } from "../baseurl";
import Skeleton from "react-loading-skeleton";
import ReactStars from "react-rating-stars-component";
import moment from "moment";

const SelectingProfile = () => {
  const selectslider3 = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    centerMode: true,
    centerPadding: "40px",
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const [loading, setLoading] = useState(true);
  const nav = useNavigate();
  const handleNavigate = () => {
    nav("/selecting", { state: data });
  };
  const location = useLocation();
  const data = location.state.data;
  console.log(data, "location vala");
  const [detail, setDetails] = useState({});
  const [photos, setPhotos] = useState([]);
  const [rating, setRating] = useState({});
  const [topRated, setTopRated] = useState([]);

  const getTopRated = () => {
    const token = localStorage.getItem("PO_TOKEN");
    axios({
      method: "get",
      url: `${REACT_APP_HOST_NAME}/apps/all-topRatedMoments`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setTopRated(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    getTopRated();
  }, []);

  const getDetail = () => {
    const token = localStorage.getItem("PO_TOKEN");
    axios({
      method: "get",
      url: `${REACT_APP_HOST_NAME}/apps/plus-one-detail?plus_one_id=${location.state.id}`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        console.log(res.data.data);
        setDetails(res.data.data);
        setPhotos(res.data.data.photo_gallery);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err.res.data.message);
      });
  };

  const getRating = () => {
    const token = localStorage.getItem("PO_TOKEN");

    axios({
      method: "get",
      url: `${REACT_APP_HOST_NAME}/apps/get-rating?plus_one_id=${location.state.id}`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        console.log(res.data.data);

        setRating(res.data.data);
      })
      .catch((err) => {
        console.log("err", err.res.data.message);
      });
  };

  useEffect(() => {
    getDetail();
    getRating();
  }, [location.state]);

  const handleNav = () => {
    nav("/review_and_book", {
      state: {
        id: location.state.id,
        data: location.state.data,
        detail: { ...detail, rate: rating.avgRating ? rating.avgRating : "0.0" },
      },
    });
    window.scrollTo({ behavior: "smooth", top: 0 });
  };

  const getDay = (date) => {
    const weekday = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const d = new Date(date);
    let day = weekday[d.getDay()];
    return day;
  };

  return (
    <>
      {/* Selecting Profile Section Start  */}
      <section className="selecting-profile-section">
        {!loading ? (
          <div className="selection-profile-content">
            <div className="r-book-heading">
              <div className="r-book-logo information-logo">
                <img src="/images/logo.png" alt="" className="img-fluid" onClick={() => {
                  nav("/home");
                }} />
              </div>
              <div className="r-book-progress information-tab-progress">
                <div className="r-book-progress-title information-progress-title">
                  <h6 className="mb-0">
                    <span>Your progress:</span> 50% Complete
                  </h6>
                </div>
                <div className="progress w-100">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "50%" }}
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="selecting-profile-body">
                <div className="plusone-profile-body price-tab-body pb-0">
                  <div className="selecting-profile-heading selecting-body-heading timeset-body-heading">
                    <a
                      className="text-decoration-none information-form-back"
                      onClick={handleNavigate}
                    >
                      <i className="fa-solid fa-arrow-left"></i>
                    </a>
                    <h5 className="errands-map-title errands-tab-body-title mb-0">
                      Plus One Profile
                    </h5>
                  </div>
                  <div className="row">
                    <div className="col col-12 col-md-12 col-lg-7 col-xl-7">
                      <div className="plusone-profile-content">
                        <div className="plusone-profile">
                          <div className="plusone-profile-img">
                            <img
                              src={detail.profile_pic}
                              alt=""
                              className="img-fluid"
                              style={{
                                height: "150px",
                                width: "150px",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                            />
                          </div>
                          <div className="how-work-profile-details me-0">
                            <h6 className="profile-details-title how-work-profile-title">
                              {detail.first_name} {detail.last_name}
                            </h6>
                            <span className="profile-verified ms-0">
                              <img
                                src="/images/check_circle.png"
                                alt=""
                                className="img-fluid"
                              />
                              ID Verified
                            </span>

                            <div className="profile-details-star how-work-profile-review">
                              <ReactStars
                                count={5}
                                size={24}
                                isHalf={true}
                                activeColor="#ffd700"
                                edit={false}
                                value={rating.avgRating}
                              />
                              <p className="mb-0 profile-details-review section-discription">
                                {rating.avgRating ? rating.avgRating : "0.0"}
                                <span>
                                  ({rating.TotalRating ? rating.TotalRating : 0}{" "}
                                  {!rating.TotalRating ? 'review' : 'reviews'})
                                </span>
                              </p>
                            </div>
                            <p className="profile-details-discription how-work-profile-discription section-discription text-start">
                              <i className="fa-regular fa-circle-check"></i>{" "}
                              {detail.total_completed_moment}{" "}
                              Moments
                            </p>
                            <p className="profile-details-discription how-work-profile-discription section-discription text-start">
                              <i className="fa-regular fa-circle-check"></i>{" "}
                              {detail.repeated_clinets}{" "}
                              Repeat Client
                            </p>
                          </div>
                        </div>
                        <div className="selecting-profile-slider">
                          <Slider {...selectslider3}>
                            {photos.map((elem) => (
                              <div className="selecting-profile-item">
                                <img src={elem} alt="" className="img-fluid" />
                              </div>
                            ))}
                          </Slider>
                        </div>
                        <div className="plusone-profile-work">
                          <div className="profile-work-list">
                            <div className="profile-work-item">
                              <p className="profile-work-link">
                                <img
                                  src="/images/Icon_Time.png"
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                Minimum Hours: 2
                              </p>
                              <p className="profile-work-link">
                                <img
                                  src="/images/Icon_Gender.png"
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                Gender: {detail.gender}
                              </p>
                              <p className="profile-work-link">
                                <img
                                  src="/images/group.png"
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                Age:
                                {Math.floor(
                                  (new Date() -
                                    new Date(detail.birth_date).getTime()) /
                                  3.15576e10
                                )}
                              </p>
                            </div>
                            <div className="profile-work-item">
                              <p className="profile-work-link">
                                <img
                                  src="/images/chat-bubble-empty.png"
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                Speaks: {detail.language.join(", ")}
                              </p>
                              <p className="profile-work-link">
                                <img
                                  src="/images/User.png"
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                Ethnicity: {detail.ethnicity}
                              </p>
                              <p className="profile-work-link">
                                <img
                                  src="/images/Icon_right.png"
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                Good Company To: Everyone
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="plusone-profile-about">
                          <h6 className="plusone-profile-title">About</h6>
                          <p className="profile-about-discription selecting-profile-discription">
                            {detail.bio}
                          </p>
                        </div>
                        <div className="plusone-profile-about">
                          <h6 className="plusone-profile-title">
                            Preferred Moments
                          </h6>
                          <div className="profile-work-list profile-preferred">
                            <div className="profile-work-item profile-preferred-item">
                              <p className="profile-work-link">
                                <img
                                  src="/images/Icon_right.png"
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                Nam pulvinar blandit velit.
                              </p>
                              <p className="profile-work-link">
                                <img
                                  src="/images/Icon_right.png"
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                Pharetra ac elit eu.
                              </p>
                              <p className="profile-work-link">
                                <img
                                  src="/images/Icon_right.png"
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                Praesent ac aliquet lorem.
                              </p>
                            </div>
                            <div className="profile-work-item">
                              <p className="profile-work-link">
                                <img
                                  src="/images/Icon_right.png"
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                Nam pulvinar blandit velit.
                              </p>
                              <p className="profile-work-link">
                                <img
                                  src="/images/Icon_right.png"
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                Pharetra ac elit eu.
                              </p>
                              <p className="profile-work-link">
                                <img
                                  src="/images/Icon_right.png"
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                Good Company To: Everyone
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="plusone-profile-review">
                          <h6 className="plusone-profile-title">Reviews</h6>
                          <p className="profile-review-rating">
                            <img
                              src="/images/Icon_Star.png"
                              alt=""
                              className="img-fluid"
                            />{" "}
                            {rating.avgRating ? rating.avgRating : "0.0"}
                            <span>
                              {" "}
                              ({rating.TotalRating ? rating.TotalRating : 0}{" "}
                              {!rating.TotalRating ? 'review' : 'reviews'})
                            </span>
                          </p>
                          <div className="profile-review-star-rating">
                            <div className="profile-review-no">
                              <div className="profile-review-item">
                                <h6>5</h6>
                                <img
                                  src="/images/Icon_Star.png"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="profile-review-item">
                                <h6>4</h6>
                                <img
                                  src="/images/Icon_Star.png"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="profile-review-item">
                                <h6>3</h6>
                                <img
                                  src="/images/Icon_Star.png"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="profile-review-item">
                                <h6>2</h6>
                                <img
                                  src="/images/Icon_Star.png"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="profile-review-item">
                                <h6>1</h6>
                                <img
                                  src="/images/Icon_Star.png"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div className="profile-review-progress">
                              <div className="progress selecting-profile-prograss">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width: `${rating.fiveRates / rating.TotalRating
                                      }%`,
                                  }}
                                  aria-valuenow="0"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                              <div className="progress selecting-profile-prograss">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width: `${rating.fourRates / rating.TotalRating
                                      }%`,
                                  }}
                                  aria-valuenow="10"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                              <div className="progress selecting-profile-prograss">
                                <div
                                  className="progress-bar"
                                  style={{
                                    width: `${rating.threeRates / rating.TotalRating
                                      }%`,
                                  }}
                                  role="progressbar"
                                  aria-valuenow="0"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                              <div className="progress selecting-profile-prograss">
                                <div
                                  className="progress-bar"
                                  style={{
                                    width: `${rating.twoRates / rating.TotalRating
                                      }%`,
                                  }}
                                  role="progressbar"
                                  aria-valuenow="0"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                              <div className="progress selecting-profile-prograss">
                                <div
                                  className="progress-bar"
                                  style={{
                                    width: `${rating.oneRates / rating.TotalRating
                                      }%`,
                                  }}
                                  role="progressbar"
                                  aria-valuenow="0"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </div>
                            <div className="profile-review-rating-no">
                              <p>({rating.fiveRates ? rating.fiveRates : 0})</p>
                              <p>({rating.fourRates ? rating.fourRates : 0})</p>
                              <p>
                                ({rating.threeRates ? rating.threeRates : 0})
                              </p>
                              <p>({rating.twoRates ? rating.twoRates : 0})</p>
                              <p>({rating.oneRates ? rating.oneRates : 0})</p>
                            </div>
                          </div>
                        </div>
                        {Object.keys(rating).length > 0
                          ? rating.data.map((e) => (
                            <div className="plusone-client-review">
                              <h6 className="client-review-title plusone-profile-title">
                                {e.name}
                                <p>
                                  <ReactStars
                                    count={5}
                                    size={24}
                                    isHalf={true}
                                    activeColor="#ffd700"
                                    edit={false}
                                    value={e.rate}
                                  />
                                </p>
                              </h6>
                              <p className="client-review-date">
                                {getDay(e.createdAt)} -{" "}
                                {moment(e.createdAt).format("MMM DD, YYYY")}
                              </p>
                              <p className="profile-about-discription pe-0 me-0">
                                {e.review}{" "}
                              </p>
                            </div>
                          ))
                          : ""}
                      </div>
                    </div>
                    <div className="col col-12 col-md-12 col-lg-5 col-xl-5">
                      <div className="plusone-profile-price">
                        <div className="plusone-profile-price-title">
                          <h4> {topRated.length > 0 ? topRated.filter(top => top.id === location.state.data.topRatedId)[0].name : ''} </h4>
                          <div className="profile-details-star how-work-profile-review mb-0">
                            <img
                              src="/images/Icon_Star.png"
                              alt=""
                              className="img-fluid"
                            />
                            <p className="selecting-profile-run profile-details-review section-discription mb-0">
                              {rating.avgRating ? rating.avgRating : "0.0"}
                              <span>
                                ({rating.TotalRating ? rating.TotalRating : 0}{" "}
                                {!rating.TotalRating ? 'review' : 'reviews'})
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="profile-hourly-rate">
                          <h5 className="hourly-rate-title">Hourly Rate</h5>
                          <h5 className="plusone-hourly-rate">
                            ${detail.hourly_rate}/hr
                          </h5>
                        </div>
                        <p className="plusone-profile-price-discription selected-profile-charged">
                          You won’t be charged yet.
                        </p>
                        <a
                          onClick={handleNav}
                          className="btn btn-warning profile-hourly-btn w-100"
                        >
                          Select
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="selecting-profile-footer">
              <div className="container">
                <div className="selecting-profile">
                  <img
                    src={detail.profile_pic}
                    alt=""
                    className="select-profile"
                  />
                  <h6 className="selecting-profile-name">
                    {detail.first_name} {detail.last_name}
                  </h6>
                </div>
                <div className="selecting-profile-price">
                  <h2 className="selecting-profile-price-title">
                    ${detail.hourly_rate}/hr
                  </h2>
                  <a
                    onClick={handleNav}
                    className="btn btn-warning selecting-profile-btn"
                  >
                    Select This Plus One
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="selection-profile-content">
            <div className="r-book-heading">
              <div className="r-book-logo information-logo">
                <img src="/images/logo.png" alt="" className="img-fluid" />
              </div>
              <div className="r-book-progress information-tab-progress">
                <div className="r-book-progress-title information-progress-title">
                  <h6 className="mb-0">
                    <span>Your progress:</span> 50% Complete
                  </h6>
                </div>
                <div className="progress w-100">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "50%" }}
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="selecting-profile-body">
                <div className="plusone-profile-body price-tab-body pb-0">
                  <div className="selecting-profile-heading selecting-body-heading timeset-body-heading">
                    <a
                      className="text-decoration-none information-form-back"
                      onClick={handleNavigate}
                    >
                      <i className="fa-solid fa-arrow-left"></i>
                    </a>
                    <h5 className="errands-map-title errands-tab-body-title mb-0">
                      Plus One Profile
                    </h5>
                  </div>
                  <div className="row">
                    <div className="col col-12 col-md-12 col-lg-7 col-xl-7">
                      <div className="plusone-profile-content">
                        <div className="plusone-profile">
                          <div className="plusone-profile-img">
                            <Skeleton
                              count={1}
                              className="sk-sel-img"
                            ></Skeleton>
                          </div>
                          <div className="how-work-profile-details me-0">
                            <h6 className="profile-details-title how-work-profile-title">
                              <Skeleton
                                count={1}
                                className="sk-sel-name"
                              ></Skeleton>
                            </h6>
                            <Skeleton
                              count={3}
                              className="sk-sel-details"
                            ></Skeleton>
                          </div>
                        </div>
                        <div className="selecting-profile-slider">
                          <div className="row sk-sel-slide">
                            <div className="col col-2">
                              <Skeleton
                                count={1}
                                className="sk-sel-gal"
                              ></Skeleton>
                            </div>
                            <div className="col col-2">
                              <Skeleton
                                count={1}
                                className="sk-sel-gal"
                              ></Skeleton>
                            </div>
                            <div className="col col-2">
                              <Skeleton
                                count={1}
                                className="sk-sel-gal"
                              ></Skeleton>
                            </div>
                            <div className="col col-2">
                              <Skeleton
                                count={1}
                                className="sk-sel-gal"
                              ></Skeleton>
                            </div>
                            <div className="col col-2">
                              <Skeleton
                                count={1}
                                className="sk-sel-gal"
                              ></Skeleton>
                            </div>
                          </div>
                        </div>
                        <div className="plusone-profile-work">
                          <div className="profile-work-list">
                            <div className="profile-work-item">
                              <Skeleton
                                count={3}
                                className="sk-sel-details"
                              ></Skeleton>
                            </div>
                            <div className="profile-work-item">
                              <Skeleton
                                count={3}
                                className="sk-sel-details"
                              ></Skeleton>
                            </div>
                          </div>
                        </div>
                        <div className="plusone-profile-about">
                          <Skeleton
                            count={1}
                            className="sk-sel-about"
                          ></Skeleton>
                          <Skeleton
                            count={2}
                            className="sk-sel-info"
                          ></Skeleton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      {/* Selecting Profile Section End  */}
    </>
  );
};

export default SelectingProfile;
