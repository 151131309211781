import React from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import PwdSuceesModal from "../../components/PwdSuceesModal";
import "react-toastify/dist/ReactToastify.css";
import {
  faEye,
  faEyeSlash,
  faTimes,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import CustomModal from "../../components/CustomModal";
import { REACT_APP_HOST_NAME } from "../../baseurl";

const ForgetPasswordNew = () => {
  const location = useLocation();
  const nav = useNavigate();
  const user_id = location.state.userId;
  const [pwd, setPwd] = useState("");
  const [cpwd, setCpwd] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleShowhide1 = () => {
    setShow1(!show1);
  };
  const [show2, setShow2] = useState(false);

  const handleShowhide2 = () => {
    setShow2(!show2);
  };

  const [err, setErr] = useState({});

  const [pwdCondition, setPwdCondition] = useState({
    con1: false,
    con2: false,
    con3: false,
  });
  const [pwdCcon, setPwdCcon] = useState(false);

  const [message, setMessage] = useState("");

  const handleChangePassword = (e) => {
    const txt = e.target.value;
    setPwd(e.target.value);
    if (txt.length > 0) {
      // console.log(txt.length>=8);
      if (txt.length >= 8) {
        setPwdCondition({ ...pwdCondition, con1: true });
      }
      if (txt.match(/^[a-zA-Z]*$/) != null) {
        setPwdCondition({ ...pwdCondition, con2: true });
      }
      if (txt.match(/[0-9]/) != null) {
        setPwdCondition({ ...pwdCondition, con3: true });
      }
    } else {
      setPwdCondition({
        ...pwdCondition,
        con1: false,
        con2: false,
        con3: false,
      });
    }
  };

  const handleConfirmPassword = (e) => {
    const txt = e.target.value;
    setCpwd(txt);

    if (pwd === txt) {
      setPwdCcon(true);
    } else {
      setPwdCcon(false);
    }
  };

  const validate = () => {
    let error = {};
    let isValid = true;
    if (!pwd) {
      error["err1"] = "Please Enter New Password";

      isValid = false;
    }
    if (!cpwd) {
      error["err2"] = "Please Enter Confirm Password";
      isValid = false;
    }
    if (pwd.length < 8) {
      error["err1"] = "Password must be atleast 8 character.";
      isValid = false;
    }
    if (pwd !== cpwd) {
      error["err2"] = "Password does not match!";
      isValid = false;
    }
    setErr(error);
    setMessage("");
    return isValid;
  };

  const handleSubmit = () => {
    console.log(user_id);
    if (validate()) {
      const data = { user_id: user_id, new_password: cpwd };
      axios
        .post(`${REACT_APP_HOST_NAME}/apps/update-password`, data, {
          headers: {
            // "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          if (response.data.success) {
            setSuccessModal(true);
            setTimeout(() => {
              setSuccessModal(false);
              nav("/login");
            }, 2000);
          }
        })
        .catch((error) => {
          console.log(error.response.data.message);
          setMessage(error.response.data.message);
        });
    }
  };

  return (
    <>
      {/* Forgot Psaaword Section Start */}
      <section className="forgot-pass-section">
        <div className="container">
          <div className="forgot-pass-content">
            <div className="forgot-pass-heading">
              <a
                onClick={() => {
                  nav("/login");
                }}
              >
                <img
                  src="/images/left_arrow.png"
                  alt=""
                  className="img-fluid"
                />
              </a>
              <h6>Password</h6>
            </div>
            <div className="forgot-pass-body">
              <h4 className="forgot-pass-new-title">Create new password</h4>
              {/* <p className="forgot-pass-pattern">
                        {pwdCondition.con1? <p className="forgot-pass-match">
                            <i className="fa-solid fa-circle-check"></i>
                            </p>:<i className="fa-regular fa-circle"></i>} Must be at least 8
                            characters long
                        </p> */}
              {/* <p className="forgot-pass-pattern">
                        {pwdCondition.con2? <p className="forgot-pass-match">
                            <i className="fa-solid fa-circle-check"></i>
                            </p>:<i className="fa-regular fa-circle"></i>} Must have one alphabet
                        </p>
                        <p className="forgot-pass-pattern">
                        {pwdCondition.con3? <p className="forgot-pass-match">
                            <i className="fa-solid fa-circle-check"></i>
                            </p>:<i className="fa-regular fa-circle"></i>} Must have one digit
                        </p> */}

              <div className="forgot-pass-email forgot-pass-new-input new-password">
                <label className="form-label">New Password</label>
                <div className="forgot-new-password">
                  <input
                    type={show1 ? "email" : "password"}
                    className="form-control"
                    value={pwd}
                    onChange={handleChangePassword}
                    placeholder="••••••••••••••••"
                  />
                  {show1 ? (
                    <FontAwesomeIcon
                      icon={faEye}
                      id="show_hide_fp"
                      onClick={handleShowhide1}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      id="show_hide_fp"
                      onClick={handleShowhide1}
                    />
                  )}

                  <span
                    style={{ color: "red", fontSize: "15px", margin: "0px" }}
                  >
                    {err.err1}
                  </span>
                </div>
              </div>
              <div className="forgot-pass-email forgot-pass-new-input">
                <label className="form-label">Confirm Password</label>
                <div className="forgot-new-password">
                  <input
                    type={show2 ? "email" : "password"}
                    className="form-control"
                    placeholder="••••••••••••••••"
                    value={cpwd}
                    onChange={handleConfirmPassword}
                  />
                  {show2 ? (
                    <FontAwesomeIcon
                      icon={faEye}
                      id="show_hide_fp"
                      onClick={handleShowhide2}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      id="show_hide_fp"
                      onClick={handleShowhide2}
                    />
                  )}
                  <br />
                  <span style={{ color: "red", fontSize: "15px" }}>
                    {err.err2}
                    {message}
                  </span>
                </div>
                {/* {pwdCcon ? (
                  <p className="forgot-pass-match">
                    <i className="fa-solid fa-circle-check"></i> Password Match!
                  </p>
                ) : (
                  <></>
                )} */}
              </div>
              <a
                className="btn btn-warning forgot-pass-btn w-100 mt-0"
                onClick={handleSubmit}
              >
                Change Password
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* Forgot Psaaword Section Start  */}

      <CustomModal
        isModalOpen={successModal}
        handleOk={() => setSuccessModal(false)}
        handleCancel={() => setSuccessModal(false)}
      >
        <PwdSuceesModal />
      </CustomModal>
    </>
  );
};

export default ForgetPasswordNew;
