import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "../node_modules/jquery-ui/dist/jquery-ui";
// import './assets/js/custom.js';
// import './assets/js/npm i jquery-ui.min.js';
// import './assets/js/select2.min';
// import './assets/js/slick.min.js';
import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Confirmed from "./components/Confirmed";
import Conversation from "./components/Conversation";
import ForgetPasswordEmail from "./screen/login/ForgetPasswordEmail";
import ForgetPasswordNew from "./screen/login/ForgetPasswordNew";
import ForgetPasswordNotMatch from "./screen/login/ForgetPasswordNotMatch";
import ForgetPassword from "./screen/login/ForgetPassword";
import Locations from "./components/Locations";
import Login from "./screen/login/Login";
import LoginEmail from "./screen/login/LoginEmail";
import LoginFacebook from "./screen/login/LoginFacebook";
import Message from "./components/Message";
import Notification from "./components/Notification";
import PaymentMethod2 from "./components/PaymentMethod2.jsx";
import PaymentMethod from "./components/PaymentMethod.jsx";
import PrivacyPolicy from "./components/PrivacyPolicy.jsx";
import Profile from "./components/Profile.jsx";
import Refer from "./components/Refer.jsx";
import ReviewAndBook from "./components/ReviewAndBook.jsx";
import Scheduled from "./components/Scheduled.jsx";
import SelectingProfile from "./components/SelectingProfile.jsx";
import Selecting from "./components/Selecting.jsx";
import SetShedule from "./components/SetShedule.jsx";
import SignupHome from "./screen/signUp/SignupHome.jsx";
import Signup from "./screen/signUp/Signup.jsx";
import TermsAndService from "./components/TermsAndService.jsx";
import ViewProfile from "./components/ViewProfile.jsx";
import Home from "./components/Home";
import TakeProfileImg from "./screen/signUp/TakeProfileImg.jsx";
import PaymentMethods from "./screen/signUp/PaymentMethods.jsx";
import ProfileComplete from "./screen/signUp/ProfileComplete";
import { ToastContainer } from "react-toastify";
import ContractorLogin from "./components/ContractorLogin";
import { ContractorProfile } from "./components/ContractorProfile";
import ReviewAndBook2 from "./components/ReviewAndBook2";
import TermService from "./components/TermServices";
import SafetyCenter from "./components/SafetyCenter";
import ContactSupport from "./components/ContactSupport";
import HowPlusOneWorks from "./components/HowPlusOneWorks";
import AddPaymentMethod from "./components/AddPaymentMethod";
import ViewPayment from "./components/ViewPayment";

function App() {
  let navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div className="po-site">
      <Routes>
        <Route
          exact
          path="/"
          element={<SignupHome handleNavigate={handleNavigate} />}
        />
        <Route exact path="/home" element={<Home />} />
        <Route path="/confirmed" element={<Confirmed />} />
        <Route path="/conversation" element={<Conversation />} />
        <Route
          path="/forget_password_email"
          element={<ForgetPasswordEmail handleNavigate={handleNavigate} />}
        />
        <Route path="/forget_password_new" element={<ForgetPasswordNew />} />
        <Route
          path="/forget_password_not_match"
          element={<ForgetPasswordNotMatch />}
        />
        <Route
          path="/forget_password"
          element={<ForgetPassword handleNavigate={handleNavigate} />}
        />
        <Route path="/locations" element={<Locations />} />

        <Route
          path="/login"
          element={<Login handleNavigate={handleNavigate} />}
        />
        <Route path="/login_email" element={<LoginEmail />} />

        <Route path="/login_facebook" element={<LoginFacebook />} />
        <Route path="/message" element={<Message />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/payment_method" element={<PaymentMethod />} />
        <Route path="/payment_method_2" element={<PaymentMethod2 />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/refer" element={<Refer />} />
        <Route path="/review_and_book" element={<ReviewAndBook />} />
        <Route path="/review_and_book2" element={<ReviewAndBook2 />} />
        <Route path="/scheduled" element={<Scheduled />} />
        <Route path="/selecting" element={<Selecting />} />
        <Route path="/selecting_profile" element={<SelectingProfile />} />
        <Route path="/set_shedule" element={<SetShedule />} />
        <Route path="/contractor_login" element={<ContractorLogin />} />
        <Route path="/contractor_profile" element={<ContractorProfile />} />
        <Route path="/term_service" element={<TermService />} />
        <Route path="/privacy_policy" element={<TermService />} />
        <Route path="/add_payment_method" element={<AddPaymentMethod />} />
        <Route path="/view_payment" element={<ViewPayment />} />

        <Route
          path="/signup_home"
          element={<SignupHome handleNavigate={handleNavigate} />}
        />
        <Route
          path="/signup"
          element={<Signup handleNavigate={handleNavigate} />}
        />
        <Route path="/terms_service" element={<TermsAndService />} />
        <Route path="/view_profile" element={<ViewProfile />} />
        <Route path="/take_profile_img" element={<TakeProfileImg />} />
        <Route path="/payment_methods" element={<PaymentMethods />} />
        <Route path="/safety_center" element={<SafetyCenter />} />
        <Route path="/contact_support" element={<ContactSupport />} />
        <Route
          exact
          path="/profile_complete"
          element={<ProfileComplete handleNavigate={handleNavigate} />}
        />
        <Route path="/how_plusone_works" element={<HowPlusOneWorks />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
