import React, { useRef, useState, useCallback, useEffect } from "react";
import CustomModal from "../../components/CustomModal";
import Header from "../../components/Header";
import ForgotModal from "./ForgotModal";
import SimpleReactValidator from "simple-react-validator";
import ForgetPasswordEmail from "./ForgetPasswordEmail";
import { toast } from "react-toastify";
import axios from "axios";
import { REACT_APP_HOST_NAME } from "../../baseurl";
import {
  formatPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import SignupModal from "../signUp/SignupModal";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

const ForgetPassword = ({ handleNavigate }) => {
  const [toggleOtp, setToggleOtp] = useState(false);
  const [timeNow, setTimeNow] = useState(Date.now() + 59000);

  const [data, setData] = useState({
    country_code: "",
    mobile: "",
  });
  const [otp, setOtp] = useState();
  const validator = useRef(new SimpleReactValidator({}));

  const handleChange = (otp) => {
    setOtp(otp.toString());
  };

  const [option, setOption] = useState("sms");
  const [toggleSignUp, setToggleSignUp] = useState(false);
  const forceUpdate = useCallback(() => updateState({}), []);
  const [, updateState] = useState();
  const [mobile, setMobile] = useState();
  const [userId, setUserId] = useState("");
  const nav = useNavigate();
  useEffect(() => {
    if (mobile) {
      setData({
        country_code: parseInt(formatPhoneNumberIntl(mobile).split(" ")[0]),
        mobile: parseInt(formatPhoneNumber(mobile).replace(/ /g, "")),
      });
    }
    return () => { };
  }, [mobile]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setError("");
      axios
        .post(`${REACT_APP_HOST_NAME}/apps/forgot-password-with-phone`, data, {
          headers: {
            // "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })

        .then((response) => {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setToggleSignUp(false);
          setToggleOtp(true);
          setTimeNow(Date.now() + 59000);

          setUserId(response.data.data.user_id);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log("error", error.response.data.message);
        });

      // .then((response) => {
      //   console.log('response', response)
      //   setToggleSignUp(false);
      //   setToggleOtp(true);
      // })
      // .catch((error) => {
      //   console.log("error", error);
      // });
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };

  const handleOtpSubmit = (e) => {
    e.preventDefault();
    if (validOTP()) {
      const { country_code, mobile } = data;
      setMessage("");
      let payload = { country_code, mobile, otp };
      // setToggleOtp(true);
      // handleNavigate("/forget_password_new");
      axios
        .post(
          `${REACT_APP_HOST_NAME}/apps/forget-password-verify-otp`,
          payload,
          {
            headers: {
              // "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          setToggleOtp(false);
          nav("/forget_password_new", { state: { userId } });
        })
        .catch((error) => {
          console.log("error", error);
          setMessage("Enter Valid OTP");
        });
    } else {
      validator.current.showMessages();
      // forceUpdate();
    }
  };

  const handleOtpEmailSubmit = () => {
    if (option === "sms") {
      // setToggleOtp(true);
      setToggleSignUp(true);
    } else {
      handleNavigate("/forget_password_email");
    }
  };

  const [message, setMessage] = useState("");
  const validOTP = () => {
    let isvalid = true;
    if (!otp) {
      setMessage("Please Enter OTP");
      isvalid = false;
    }
    return isvalid;
  };

  const [error, setError] = useState("");
  const validate = () => {
    let isvalid = true;
    if (!mobile) {
      setError("Please Enter Mobile Number");
      isvalid = false;
    }
    return isvalid;
  };

  return (
    <>
      {/* Forgot Psaaword Section Start  */}
      <section className="forgot-pass-section">
        <div className="container">
          <div className="forgot-pass-content">
            <div className="forgot-pass-heading">
              <a
                onClick={() => {
                  nav("/login");
                }}
              >
                <img
                  src="/images/left_arrow.png"
                  alt=""
                  className="img-fluid"
                />
              </a>
              <h6>Forgot Password?</h6>
            </div>
            <div className="forgot-pass-body">
              <p className="forgot-pass-discription">
                Select which contact details should we use to reset your
                password:
              </p>
              <div className="forgot-pass-option">
                <label className="form-check-label" htmlFor="Radioforgot1">
                  <div className="forgot-pass-link">
                    <div className="forgot-pass-img">
                      <img
                        src="/images/sms.png"
                        alt=""
                        className="img-fluid w-100 h-100"
                      />
                    </div>
                    <div className="forgot-pass-type">
                      <h5>Via SMS:</h5>
                      {/* <h5>•••• •••• 8910</h5> */}
                    </div>
                  </div>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="Radioforgot1"
                    checked={option === "sms" ? true : false}
                    onChange={() => {
                      setOption("sms");
                    }}
                  />
                </label>
              </div>
              <div className="forgot-pass-option">
                <label className="form-check-label" htmlFor="Radioforgot2">
                  <div className="forgot-pass-link">
                    <div className="forgot-pass-img">
                      <img
                        src="/images/email.png"
                        alt=""
                        className="img-fluid w-100 h-100"
                      />
                    </div>
                    <div className="forgot-pass-type">
                      <h5>Via Email:</h5>
                      {/* <h5>•••••••th@email.com</h5> */}
                    </div>
                  </div>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="Radioforgot2"
                    checked={option !== "sms" ? true : false}
                    onChange={() => {
                      setOption("email");
                    }}
                  />
                </label>
              </div>
              <button
                // href="javascript:void(0)"
                className="btn btn-warning forgot-pass-btn w-100"
                onClick={() => handleOtpEmailSubmit()}
              // data-bs-toggle="modal"
              // data-bs-target="#forgotModal"
              >
                Send Code
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* Forgot Psaaword Section End  */}

      {/* Forgot Modal Start  */}
      <CustomModal
        isModalOpen={toggleOtp}
        handleOk={() => setToggleOtp(false)}
        handleCancel={() => setToggleOtp(false)}
      >
        <ForgotModal
          handleChange={handleChange}
          handleOtpSubmit={handleOtpSubmit}
          otp={otp}
          message={message}
          country_code={data.country_code}
          mobile={data.mobile}
          handleOk={() => {
            setToggleOtp(false);
            setMobile("");
            setOtp("");
            setError("");
          }}
          timeNow={timeNow}
          setTimeNow={(e) => setTimeNow(e)}
        />
      </CustomModal>

      {/* Mobile Modal Start  */}
      <CustomModal
        isModalOpen={toggleSignUp}
        handleOk={() => setToggleSignUp(false)}
        handleCancel={() => setToggleSignUp(false)}
      >
        <SignupModal
          handleSubmit={handleSubmit}
          mobile={mobile}
          setMobile={setMobile}
          validator={validator}
          data={data}
          handleOk={() => {
            setMobile("");
            // setOtp("");
            // setToggleOtp(false);
            setToggleSignUp(false);
            // setError("");
          }}
          error={error}
        />
      </CustomModal>

      {/* <div className="modal fade forgot-modal" id="forgotModal" tabIndex="-1" aria-labelledby="forgotModal" aria-hidden="true">
                <div className="modal-dialog forgot-modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body forgot-modal-body">
                            <h5 className="forgot-modal-title">Enter 4-digit recovery code</h5>
                            <p className="forgot-modal-discription">The recovery code was sent to your mobile number. Please enter code:</p>
                            <div className="forgot-modal-input">
                                <input type="text" className="form-control"/>
                                <input type="text" className="form-control"/>
                                <input type="text" className="form-control"/>
                                <input type="text" className="form-control"/>
                            </div>
                            <a href="javascript:void(0)" className="btn btn-warning forgot-pass-btn w-100" data-bs-dismiss="modal">Verify</a>
                        </div>
                    </div>
                </div>
            </div> */}
      {/* Forgot Modal End  */}
    </>
  );
};

export default ForgetPassword;
