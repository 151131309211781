import axios from "axios";
import React, { useState } from "react";
import { REACT_APP_HOST_NAME } from "../baseurl";
import { toast } from "react-toastify";

const ReviewModal = (props) => {
  const [rating, setRating] = useState();
  const [rateMessage, setRateMessage] = useState("");
  const { moment_data } = props;

  const handleSubmit = () => {
    if (rateMessage && rating) {
      const token = localStorage.getItem("PO_TOKEN");
      var body = new URLSearchParams();
      body.append("rate", rating);
      body.append("rate_message", rateMessage);
      body.append("moment_id", moment_data._id);
      body.append("by_whom", "user");
      body.append("plus_one_id", moment_data.plus_one_id);
      body.append("user_id", moment_data.user_id);

      axios({
        method: "post",
        url: `${REACT_APP_HOST_NAME}/apps/Add-rating`,
        headers: { Authorization: `Bearer ${token}` },
        data: body,
      })
        .then((res) => {
          console.log(res);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setRateMessage("");
        })
        .catch((err) => {
          console.log(err, "review Error");
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setRateMessage("");
        });
    }
  };
  return (
    <>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body review-modal">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img src="/images/Cancel_icon.png" alt="" className="img-fluid" />
            </button>
            <div className="review-modal-heading">
              <div className="review-modal-img" style={{ objectFit: "cover" }}>
                <img
                  src={moment_data.profile_pic}
                  alt=""
                  className="img-fluid"
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <h4 className="review-modal-title">Rate Your Moment</h4>
              <h5 className="review-modal-subtitle">
                Shopping with {moment_data.first_name}
              </h5>
              <div className="review-modal-star">
                <input
                  type="radio"
                  id="star1"
                  name="rate"
                  value="1"
                  onChange={() => setRating(1)}
                />
                <label htmlFor="star1" title="text">
                  1 star
                </label>
                <input
                  type="radio"
                  id="star2"
                  name="rate"
                  value="2"
                  onChange={() => setRating(2)}
                />
                <label htmlFor="star2" title="text">
                  2 stars
                </label>
                <input
                  type="radio"
                  id="star3"
                  name="rate"
                  value="3"
                  onChange={() => setRating(3)}
                />
                <label htmlFor="star3" title="text">
                  3 stars
                </label>
                <input
                  type="radio"
                  id="star4"
                  name="rate"
                  value="4"
                  onChange={() => setRating(4)}
                />
                <label htmlFor="star4" title="text">
                  4 stars
                </label>
                <input
                  type="radio"
                  id="star5"
                  name="rate"
                  value="5"
                  onChange={() => setRating(5)}
                />
                <label htmlFor="star5" title="text">
                  5 stars
                </label>
              </div>
            </div>
            <div className="review-modal-check-btn">
              <h6 className="review-perecenteage-title">
                Add a tip for {moment_data.first_name}
              </h6>
              <div className="review-modal-percentage">
                <div className="form-check p-0">
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="flexRadioDefault"
                    id="Radio2"
                    checked
                  />
                  <label className="form-check-label" htmlFor="Radio2">
                    10%
                  </label>
                </div>
                <div className="form-check p-0">
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="flexRadioDefault"
                    id="Radio3"
                  />
                  <label className="form-check-label" htmlFor="Radio3">
                    30%
                  </label>
                </div>
                <div className="form-check p-0">
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="flexRadioDefault"
                    id="Radio4"
                  />
                  <label className="form-check-label" htmlFor="Radio4">
                    50%
                  </label>
                </div>
              </div>
              <p className="review-modal-amout">Enter Custom Amount</p>
            </div>
            <div className="review-modal-meass-box review-modal-check-btn border-bottom-0">
              <h6 className="review-perecenteage-title">
                Write a review about your Moment
              </h6>
              <textarea
                className="form-control"
                cols="30"
                rows="3"
                placeholder="Write review here..."
                onChange={(e) => setRateMessage(e.target.value)}
              ></textarea>
            </div>
            <div className="review-modal-btn text-center">
              <a className="btn btn-warning" onClick={handleSubmit}>
                Submit
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewModal;
