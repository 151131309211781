import React from "react";
import moment from "moment";
import { useState } from "react";
import { REACT_APP_HOST_NAME } from "../baseurl";
import axios from "axios";
import { toast } from "react-toastify";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const ViewDetailModal = (props) => {
  const [updateToggle, setUpdateToggle] = useState(true);
  const [cancelToggle, setCancelToggle] = useState(true);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [error, setError] = useState({});
  const [topRated, setTopRated] = useState([]);
  const { moment_data } = props;
  const containerStyle = {
    width: "100%",
    height: "200px",
    border: "1px solid grey",
    borderRadius: "8px",
  };
  const [otherReason, setOtherReason] = useState();
  const [reason, setReason] = useState([]);
  const [date, setDate] = useState("");
  const nav = useNavigate();
  const [showModal, setShowModal] = useState(0);

  const add3Dots = (string, limit) => {
    var dots = "...";
    if (string.length > limit) {
      string = string.substring(0, limit) + dots;
    }
    return string;
  };

  const validate = () => {
    let err = {};
    let isValid = false;
    if (!startTime) {
      err["sTime_err"] = "Please Select Start Time";
    }
    if (!endTime) {
      err["eTime_err"] = "Please Select End Time";
    }
    if (startTime && endTime) {
      if (startTime < endTime) {
        isValid = true;
      } else {
        err["seTime_err"] = "End time not Greater than Start Time";
      }
    }
    setError(err);
    return isValid;
  };

  const handleReason = (e) => {
    if (e.target.checked) {
      let res = [...reason];
      res.push(e.target.value);
      setReason(res);
    } else {
      let res = [...reason];
      let index = res.indexOf(e.target.value);
      res.splice(index, 1);
      setReason(res);
    }
  };

  const changeTime = (time) => {
    const [hourString, minute] = time.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
  };

  const getTopRated = () => {
    const token = localStorage.getItem("PO_TOKEN");
    axios({
      method: "get",
      url: `${REACT_APP_HOST_NAME}/apps/all-topRatedMoments`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setTopRated(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  console.log(moment_data);

  const handleUpdate = () => {
    const weekday = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    const d = new Date(date);
    let day = weekday[d.getDay()];
    if (validate()) {
      const time = changeTime(startTime) + " - " + changeTime(endTime);
      const duration = parseInt(endTime) - parseInt(startTime);
      const token = localStorage.getItem("PO_TOKEN");
      var body = new URLSearchParams();
      body.append("new_time", time);
      body.append("moment_id", moment_data._id);
      body.append(
        "updated_by",
        moment_data.is_switch_to_contractor ? "user" : "plus_one"
      );
      body.append("new_duration", parseInt(duration));
      body.append("week_day", day.toString());
      body.append("date", date.toString());
      axios({
        method: "post",
        url: `${REACT_APP_HOST_NAME}/apps/update-moment-time`,
        headers: { Authorization: `Bearer ${token}` },
        data: body,
      })
        .then((res) => {
          CancelUpdate();
          toast.success(res.data.message);
        })
        .catch((err) => {
          console.log("err", err.res.data.message);
        });
    }
  };

  const handleCancel = () => {
    let reasons = [...reason];
    if (reasons.includes("others")) {
      let index = reasons.indexOf("others");
      reasons[index] = otherReason;
    }
    const token = localStorage.getItem("PO_TOKEN");
    var body = new URLSearchParams();
    body.append("moment_id", moment_data._id);
    body.append("cancellation_reason", reasons);
    body.append(
      "cancelled_by",
      moment_data.is_switch_to_contractor ? "user" : "plus_one"
    );
    axios({
      method: "post",
      url: `${REACT_APP_HOST_NAME}/apps/cancel-moment`,
      headers: { Authorization: `Bearer ${token}` },
      data: body,
    })
      .then((res) => {
        toast.success(res.data.message);
        CancelUpdate();
      })
      .catch((err) => {
        console.log("err", err.res.data.message);
      });
  };

  const CancelUpdate = () => {
    setUpdateToggle(true);
    setCancelToggle(true);
    setStartTime(0);
    setEndTime(0);
    setError("");
    setShowModal(0);
    props.handleCancel();
    props.handleScedule();
  };

  const getDay = (date) => {
    let day = moment(date).day();
    if (day === 1) return "monday";
    if (day === 2) return "tuesday";
    if (day === 3) return "wednesday";
    if (day === 4) return "thursday";
    if (day === 5) return "friday";
    if (day === 6) return "saturday";
    if (day === 7) return "sunday";
  };

  useEffect(() => {
    getTopRated();
  }, []);

  return (
    <>
      <div className="modal-dialog checkin-modal-dialog" id="viewMomentModal">
        {showModal === 0 ? (
          <div className="modal-content checkin-modal-content">
            <div
              className="modal-content login-modal-close"
              onClick={CancelUpdate}
            >
              <a
                className="d-flex justify-content-end"
                style={{
                  padding: "0px 15px",
                  fontSize: "25px",
                  textDecoration: "none",
                  color: "black",
                }}
              >
                x
              </a>
            </div>
            <div className="modal-body checkin-modal-body">
              <div className="">
                <div className="checkin-modal-profile massage-moment-profile">
                  {topRated.map((elem) =>
                    elem.id === moment_data.moment_id ? (
                      <div className="r-book-payment-celebration mt-0 justify-content-start">
                        <div className="r-book-celebration-img">
                          <img
                            src={moment_data.moment_image}
                            alt=""
                            className="r-book-img"
                          />
                        </div>
                        <div className="r-book-celebration-details">
                          <h6 className="r-book-celebration-subtitle">
                            You're going to
                          </h6>
                          <h6 className="r-book-celebration-title">
                            {elem.name}
                          </h6>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  )}
                </div>
                <div className="confirmed-profile-details massage-moment-profile-details d-flex">
                  <img
                    src={moment_data.profile_pic}
                    alt=""
                    className="img-fluid"
                    style={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                  <p
                    style={{
                      fontSize: "15px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Your Plus One{" "}
                    <b className="mx-1"> {moment_data.first_name} </b> will meet
                    you here:
                  </p>
                </div>
              </div>
              <div className="checkin-modal-map massage-momemt-map">
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={{
                    lat: moment_data.lat,
                    lng: moment_data.long,
                  }}
                  zoom={18}
                >
                  <Marker
                    position={{
                      lat: moment_data.lat,
                      lng: moment_data.long,
                    }}
                  />
                </GoogleMap>
              </div>
              <div className="plus-one-moment-detail d-flex justify-content-center">
                <div className="text-align-center">
                  <p className="order-address-link">
                    <img
                      src="/images/Calendar.png"
                      alt=""
                      className="img-fluid"
                    />
                    {getDay(moment_data.date)} -{" "}
                    {moment(moment_data.date).format("MMM DD, YYYY")}
                  </p>
                  <p className="order-address-link">
                    <img
                      src="/images/Icon_Time.png"
                      alt=""
                      className="img-fluid"
                    />
                    {moment_data.time["new"]} <br /> Duration:{" "}
                    {moment_data.duration} hour
                  </p>
                  <p className="order-address-link">
                    <img
                      src="/images/pin_location.png"
                      alt=""
                      className="img-fluid"
                    />
                    {add3Dots(moment_data.address, 30)}
                  </p>
                  <div className="massage-update-edite">
                    <a
                      onClick={() => {
                        setShowModal(1);
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#updateModal"
                      className="text-decoration-none"
                    >
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M16.2744 5.96538C16.5184 5.7213 16.5184 5.32557 16.2744 5.0815L12.1496 0.956706C11.9055 0.712628 11.5098 0.712628 11.2657 0.956706L0.90317 11.3192C0.62968 11.5927 0.476034 11.9637 0.476034 12.3504L0.476034 16.13C0.476034 16.4752 0.755856 16.755 1.10103 16.755L4.88065 16.755C5.26742 16.755 5.63835 16.6014 5.91184 16.3279L16.2744 5.96538ZM14.9485 5.52344L13.4754 6.99658L10.2345 3.75567L11.7076 2.28253L14.9485 5.52344ZM9.35061 4.63955L12.5915 7.88046L5.02796 15.444C4.98889 15.4831 4.9359 15.505 4.88065 15.505L1.72603 15.505L1.72603 12.3504C1.72603 12.2952 1.74798 12.2422 1.78705 12.2031L9.35061 4.63955Z"
                          fill="#FFBB00"
                        />
                      </svg>{" "}
                      Update Moment
                    </a>
                    <a
                      onClick={() => {
                        setShowModal(2);
                      }}
                      className="mb-0"
                      data-bs-toggle="modal"
                      data-bs-target="#confirmcancelModal"
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {showModal === 1 ? (
          <div className="modal-dialog requesting-modal-dialog">
            <div className="modal-content requesting-modal-content">
              <div className="modal-body requesting-modal-body text-center">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setShowModal(0);
                  }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_2521_10929)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.0559 4.05543C4.3575 3.75383 4.84649 3.75383 5.14809 4.05543L10.0005 8.90781L14.8529 4.05543C15.1545 3.75383 15.6434 3.75383 15.945 4.05543C16.2466 4.35703 16.2466 4.84602 15.945 5.14761L11.0927 10L15.945 14.8524C16.2466 15.154 16.2466 15.643 15.945 15.9446C15.6434 16.2462 15.1545 16.2462 14.8529 15.9446L10.0005 11.0922L5.14809 15.9446C4.84649 16.2462 4.3575 16.2462 4.0559 15.9446C3.7543 15.643 3.7543 15.154 4.0559 14.8524L8.90829 10L4.0559 5.14761C3.7543 4.84602 3.7543 4.35703 4.0559 4.05543Z"
                        fill="#ACB4BA"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2521_10929">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                <div className="row">
                  <div className="set-schedule-input">
                    <label className="set-schedule-input-title">
                      <span>1</span>
                      Set Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                    <span
                      style={{
                        color: "red",
                        fontWeight: "500",
                        fontSize: "12px",
                      }}
                    >
                      {error.date_err}
                    </span>
                  </div>
                  <div className="set-schedule-input">
                    <label className="set-schedule-input-title">
                      <span>2</span>
                      Set Time
                    </label>
                    <div className="row mt-2">
                      <div className="col col-6">
                        <label className="form-label">Start Time</label>
                        <input
                          type="time"
                          className="form-control"
                          value={startTime}
                          onChange={(e) => setStartTime(e.target.value)}
                        />
                        <span
                          style={{
                            color: "red",
                            fontWeight: "500",
                            fontSize: "15px",
                          }}
                        >
                          {error.sTime_err}
                        </span>
                      </div>
                      <div className="col col-6">
                        <label className="form-label">End Time</label>
                        <input
                          type="time"
                          className="form-control"
                          value={endTime}
                          onChange={(e) => setEndTime(e.target.value)}
                        />
                        <span
                          style={{
                            color: "red",
                            fontWeight: "500",
                            fontSize: "15px",
                          }}
                        >
                          {error.eTime_err}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  href="#"
                  className="btn btn-warning w-100"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#updateModal"
                  onClick={handleUpdate}
                >
                  Select & Continue
                </a>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {showModal === 2 ? (
          <div className="modal-dialog requesting-modal-dialog">
            <div className="modal-content requesting-modal-content">
              <div
                className="modal-content login-modal-close"
                onClick={() => {
                  setShowModal(0);
                }}
              >
                <a
                  className="d-flex justify-content-end"
                  style={{
                    padding: "0px 15px",
                    fontSize: "25px",
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  x
                </a>
              </div>
              <div className="modal-body cancel-profile-mbody">
                <div className="cancel-profile-heading">
                  <h2 className="requesting-modal-title massage-moment-title my-0 ">
                    Cancel your Moment
                  </h2>
                </div>
                <div className="cancel-profile-reason">
                  <h6 className="cancel-profile-title">
                    Reason for cancellation (optional):
                  </h6>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Conflicting schedule"
                      id="Checkreason1"
                      checked={reason.includes("Conflicting schedule")}
                      onChange={handleReason}
                    />
                    <label
                      className="form-check-label cancel-profile-title mb-0"
                      for="Checkreason1"
                    >
                      Conflicting schedule
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Emergency"
                      id="Checkreason2"
                      checked={reason.includes("Emergency")}
                      onChange={handleReason}
                    />
                    <label
                      className="form-check-label cancel-profile-title mb-0"
                      for="Checkreason2"
                    >
                      Emergency
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Changed my mind"
                      id="Checkreason3"
                      checked={reason.includes("Changed my mind")}
                      onChange={handleReason}
                    />
                    <label
                      className="form-check-label cancel-profile-title mb-0"
                      for="Checkreason3"
                    >
                      Changed my mind
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="others"
                      id="Checkreason4"
                      checked={reason.includes("others")}
                      onChange={handleReason}
                    />
                    <label
                      className="form-check-label cancel-profile-title mb-0"
                      for="Checkreason4"
                    >
                      Others (please specify)
                    </label>
                  </div>
                </div>
                {reason.includes("others") ? (
                  <div className="cancel-profile-input">
                    <textarea
                      className="form-control"
                      id="other-reason"
                      cols="30"
                      rows="4"
                      onChange={(e) => setOtherReason(e.target.value)}
                    ></textarea>
                  </div>
                ) : (
                  ""
                )}
                <div className="cancel-profile-btn text-center">
                  <a
                    href="#"
                    className="btn btn-warning"
                    data-bs-dismiss="modal"
                    data-bs-toggle="modal"
                    data-bs-target="#cancelledModal"
                    onClick={handleCancel}
                  >
                    Submit
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
