import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import PhoneInput from "react-phone-number-input";

const SignupModal = ({
  handleSubmit,
  mobile,
  setMobile,
  data,
  error,
  handleOk,
}) => {
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="modal-dialog login-modal-dialog">
          <div
            className="modal-content login-modal-content"
            style={{ border: "none" }}
          >
            <div
              className="modal-content login-modal-close"
              style={{ border: "none" }}
            >
              <a
                className="d-flex justify-content-end"
                onClick={handleOk}
                style={{
                  padding: "0px 15px",
                  fontSize: "25px",
                  textDecoration: "none",
                  color: "black",
                }}
              >
                x
              </a>
            </div>
            <div className="modal-body login-modal-body">
              <div
                className="login-input"
                style={{ borderBottom: "0.5px solid #959da3" }}
              >
                <label htmlFor="form-label">Enter your mobile number</label>

                <div className="login-country-input" style={{ width: "100%" }}>
                  <PhoneInput
                    placeholder="(650) 251-53-21"
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="IN"
                    value={mobile}
                    onChange={setMobile}
                    className="mt-2"
                    limitMaxLength={15}
                  />
                </div>
              </div>
              <div
                style={{
                  color: "red",
                  fontSize: "13px",
                  // marginTop: "10px",
                }}
              >
                {error}
              </div>
              <div className="login-modal-btn text-center">
                <button
                  type="submit"
                  className="btn btn-warning"
                  data-bs-toggle="modal"
                  data-bs-target="#loginModal"
                  // onClick={(e) => handleSubmit(e)}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      {/* </div> */}
    </>
  );
};

export default SignupModal;
