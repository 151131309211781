import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import Confetti from "react-confetti";
import ConfettiExplosion from "react-confetti-explosion";
import Confetti from "react-confetti";

const ProfileComplete = ({ data }) => {
  const nav = useNavigate();
  const [isExploding, setIsExploding] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      nav("/home");
    }, 4000);
    setIsExploding(true);
  }, []);

  return (
    <>
      <div
        className="tab-pane"
        id="profile"

        // role="tabpanel"
        // aria-labelledby="profile-tab"
      >
        {/* Modal */}
        <div
          class="modal fade profile-modal show"
          id="profileModal"
          style={{ display: "block" }}
          tabindex="-1"
          aria-labelledby="profileModal"
          // aria-hidden="true"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body profile-modal-body">
                <h2 className="profile-modal-title">
                  {/* <Confetti
                  style={{
                    width: "250px",
                    marginTop: "50px",
                    height: "200px",
                  }}
                  height="200%"
                  width="200%"
                  numberOfPieces={100}
                  // run={false}
                /> */}
                  {/* <div style={{ marginLeft: "100px" }}>
                  {isExploding && (
                    <ConfettiExplosion
                      force={0.4}
                      duration={10000}
                      particleCount={400}
                      height={1600}
                      width={1600}
                      particleSize={10}
                    />
                  )}
                </div> */}
                  Your Profile is complete!
                  <canvas id="celebration"></canvas>
                </h2>
                <div className="blue profile-progress">
                  <span className="progress-left">
                    <span className="progress-bar"></span>
                  </span>
                  <span className="progress-right">
                    <span className="progress-bar"></span>
                  </span>
                  <div className="progress-value">
                    <img
                      src={data?.profile_pic}
                      alt=""
                      className="img-fluid rounded-circle"
                      style={{ objectFit: "cover", margin: "0" }}
                    />
                  </div>
                </div>
                <h2 className="profile-modal-name">
                  {data?.fname} {data?.lname}
                </h2>
                {/* <h5 className="profile-modal-country">Orlando, Florida</h5> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileComplete;
