import React, { useState, useRef, useEffect, useCallback } from "react";
import SimpleReactValidator from "simple-react-validator";
import OtpModal from "../../components/OtpModal";
import "react-phone-number-input/style.css";
import {
  formatPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import { toast } from "react-toastify";
import { REACT_APP_HOST_NAME } from "../../baseurl";

import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import CustomModal from "../../components/CustomModal";
import SignupModal from "./SignupModal";
import { useNavigate } from "react-router-dom";

const SignupHome = ({ handleNavigate }) => {
  const [data, setData] = useState({
    country_code: "",
    mobile: "",
  });
  const nav = useNavigate();
  const [mobile, setMobile] = useState();
  const [toggleOtp, setToggleOtp] = useState(false);
  const [toggleSignUp, setToggleSignUp] = useState(false);
  const validator = useRef(new SimpleReactValidator({}));
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [otp, setOtp] = useState();
  const [timeNow, setTimeNow] = useState(Date.now() + 59000);

  useEffect(() => {
    if (mobile) {
      setData({
        country_code: parseInt(formatPhoneNumberIntl(mobile).split(" ")[0]),
        mobile: parseInt(formatPhoneNumber(mobile).replace(/ /g, "")),
      });
    }
    return () => {};
  }, [mobile]);
  useEffect(() => {
    if (
      localStorage.getItem("PO_TOKEN") !== null &&
      localStorage.getItem("PO_TOKEN") !== ""
    ) {
      handleNavigate("/home");
    }
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("PO_TOKEN") !== null &&
      localStorage.getItem("PO_TOKEN") !== ""
    ) {
      handleNavigate("/home");
    }
  }, []);

  const handleChange = (otp) => {
    setOtp(parseInt(otp));
  };

  const handleEdit = () => {
    setToggleOtp(false);
    setToggleSignUp(true);
  };

  const [message, setMessage] = useState("");
  const validOTP = () => {
    let isvalid = true;
    if (!otp) {
      setMessage("Please Enter OTP");
      isvalid = false;
    }
    return isvalid;
  };

  const handleOk = () => {
    setMobile("");
    setOtp("");
    setToggleOtp(false);
    setError("");
  };

  const handleOtpSubmit = (e) => {
    e.preventDefault();
    if (validOTP()) {
      const { country_code, mobile } = data;
      setMessage("");
      let payload = { country_code, mobile, otp };
      axios
        .post(`${REACT_APP_HOST_NAME}/apps/signup-verify-otp`, payload, {
          headers: {
            // "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          console.log(response.data, "response.data");
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setToggleOtp(false);
          nav("/signup", { state: { data } });
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log("error", error);
        });
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    if (validate()) {
      setError("");
      axios
        .post(`${REACT_APP_HOST_NAME}/apps/signup-send-otp`, data, {
          headers: {
            // "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })

        .then((response) => {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setMobile(undefined);
          setToggleSignUp(false);
          setToggleOtp(true);
          setTimeNow(Date.now() + 59000);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log("error", error.response.data.message);
        });
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };

  const handleLogin = () => {
    handleNavigate("/login");
  };
  const [error, setError] = useState("");
  const validate = () => {
    let isvalid = true;
    if (!mobile) {
      setError("Please Enter Mobile Number");
      isvalid = false;
    }
    return isvalid;
  };

  return (
    <>
      {/* Site Content Start  */}
      <main className="po-site-content">
        {/* Login Section Start  */}
        <section className="po-login-section po-signup-section">
          <div className="container-fluid">
            <div className="po-login-content">
              <div className="row ">
                <div className="col col-12 col-md-12 col-lg-6 col-xl-6 p-0">
                  <div className="login-img">
                    <img
                      src="/images/login_img.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col col-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="login-form-area">
                    <div className="login-body-content signup-body-content">
                      <div className="login-logo signup-logo">
                        <img
                          src="/images/logo.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <h2 className="signup-body-title po-landing-section-title">
                        Good company <br /> at your fingertips
                      </h2>
                      <div className="signup-btn login-btn text-center">
                        <button
                          type="button"
                          className="btn btn-warning"
                          // data-bs-toggle="modal"
                          // data-bs-target="#signupModal"
                          onClick={() => {
                            setToggleSignUp(true);
                          }}
                        >
                          Sign Up
                        </button>

                        <button
                          type="submit"
                          className="btn btn-warning"
                          onClick={() => handleLogin()}
                        >
                          Log In
                        </button>
                      </div>
                      <p className="signup-policy">
                        By signing up you agree to our{" "}
                        <a href="javascript:void(0)">Terms of Service</a> and{" "}
                        <a href="javascript:void(0)">Privacy Policy</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Login Section End  */}
      </main>

      {/* Signup Modal Start */}

      <CustomModal
        isModalOpen={toggleSignUp}
        handleOk={() => setToggleSignUp(false)}
        handleCancel={() => setToggleSignUp(false)}
      >
        <SignupModal
          handleSubmit={handleSubmit}
          mobile={mobile}
          setMobile={setMobile}
          data={data}
          error={error}
          handleOk={() => {
            setMobile(undefined);
            setToggleSignUp(false);
          }}
        />
      </CustomModal>

      {/* Otp Modal Start */}
      <CustomModal
        isModalOpen={toggleOtp}
        handleOk={() => setToggleOtp(false)}
        handleCancel={() => setToggleOtp(false)}
      >
        <OtpModal
          country_code={data.country_code}
          mobile={data.mobile}
          handleChange={handleChange}
          handleOtpSubmit={handleOtpSubmit}
          handleEdit={handleEdit}
          otp={otp}
          handleOk={handleOk}
          message={message}
          handleSubmit={handleSubmit}
          timeNow={timeNow}
          setTimeNow={(e) => setTimeNow(e)}
          url="signup-send-otp"
        />
      </CustomModal>
      {/* Site Content End       */}
    </>
  );
};

export default SignupHome;
