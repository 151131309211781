import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import NavBar from "./NavBar";
import { REACT_APP_HOST_NAME } from "../baseurl";
import axios from "axios";

const ViewPayment = () => {
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const listCard = () => {
    const token = localStorage.getItem("PO_TOKEN");

    axios({
      method: "get",
      url: `${REACT_APP_HOST_NAME}/apps/get-all-moments-payments`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        console.log(response.data.data);
        setData(response.data.data);
      })
      .catch((error) => {
        console.log("nigga");
        console.log("error", error);
      });
  };
  function dateFormat(date) {
    const formattedDate = new Date(date).toLocaleDateString(
      {},
      { timeZone: "UTC", month: "long", day: "2-digit", year: "numeric" }
    );
    const sp = formattedDate.split(" ");
    return `${sp[0]} ${sp[1]} ${sp[2]}`;
  }
  function timeDiff(date, stime, etime) {
    var timeStart = new Date(date + " " + stime);
    var timeEnd = new Date(date + " " + etime);

    var diff = (timeEnd - timeStart) / 60000; //dividing by seconds and milliseconds
    var minutes = diff / 60;
    // var hours = (diff - minutes) / 60;
    // console.log(diff, minutes, hours);

    return (Math.round(minutes * 100) / 100).toFixed(2);
  }
  useEffect(() => {
    listCard();
  }, []);
  return (
    <>
      <Header />
      {/* <!-- Site Header End --> */}

      {/* <!-- Profile sharing Section Start --> */}
      <section class="po-sharing-section">
        <div class="po-sharing-content">
          <NavBar />
          <div class="privacy-sharing-body" data-scrollbar>
            <div class="po-sharing-sidebar-icon d-block d-lg-none">
              <i class="fa-solid fa-bars"></i>
            </div>
            <div class="privacy-sharing payment-view-transfer">
              <div class="privacy-sharing-tabs payment-no-transfer">
                <a
                  onClick={() => nav("/add_payment_method")}
                  class="privacy-sharing-back"
                >
                  <i class="fa-solid fa-arrow-left"></i>
                </a>
                <div class="no-transfer-content">
                  <h2 class="privacy-sharing-title notification-title">
                    Your payments
                  </h2>
                </div>
              </div>
              <div class="view-transfer-table table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Moments</th>
                      <th scope="col">Date</th>
                      <th scope="col">Duration</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((e, i) => (
                      <tr key={e.payment_id}>
                        <td scope="row">
                          <div class="view-transfer-cell">
                            <div class="view-transfer-img">
                              <img
                                src={e.moment_image}
                                alt=""
                                class="img-fluid"
                                width="80px"
                              />
                              <h6 class="view-transfer-name">
                                {e.moment_name}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="view-transfer-cell">
                            {dateFormat(e.moment_date)}
                          </div>
                        </td>
                        <td>
                          <div class="view-transfer-cell">
                            {timeDiff(
                              e.moment_date.split("-")[1] +
                                "/" +
                                e.moment_date.split("-")[2] +
                                "/" +
                                e.moment_date.split("-")[0] +
                                "/",
                              e.moment_time.split("-")[0].trim(),
                              e.moment_time.split("-")[1].trim()
                            )}{" "}
                            hours
                          </div>
                        </td>
                        <td>
                          <div class="view-transfer-cell">$ {e.amount} </div>
                        </td>
                      </tr>
                    ))}
                    {/* <tr>
                      <td scope="row">
                        <div class="view-transfer-cell">
                          <div class="view-transfer-img">
                            <img
                              src="/images/transfer_2.png"
                              alt=""
                              class="img-fluid"
                            />
                            <h6 class="view-transfer-name">Go to the Beach</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="view-transfer-cell">September 10, 2022</div>
                      </td>
                      <td>
                        <div class="view-transfer-cell">2.5 hours</div>
                      </td>
                      <td>
                        <div class="view-transfer-cell">$ 20.00</div>
                      </td>
                    </tr> */}
                    {/* <tr>
                      <td scope="row">
                        <div class="view-transfer-cell">
                          <div class="view-transfer-img">
                            <img
                              src="/images/tranfer_3.png"
                              alt=""
                              class="img-fluid"
                            />
                            <h6 class="view-transfer-name">Listen to me</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="view-transfer-cell">August 28, 2022</div>
                      </td>
                      <td>
                        <div class="view-transfer-cell">2.5 hours</div>
                      </td>
                      <td>
                        <div class="view-transfer-cell">$ 20.00</div>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">
                        <div class="view-transfer-cell">
                          <div class="view-transfer-img">
                            <img
                              src="/images/transfer_4.png"
                              alt=""
                              class="img-fluid"
                            />
                            <h6 class="view-transfer-name">
                              Come to family function
                            </h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="view-transfer-cell">June 30, 2022</div>
                      </td>
                      <td>
                        <div class="view-transfer-cell">2.5 hours</div>
                      </td>
                      <td>
                        <div class="view-transfer-cell">$ 20.00</div>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">
                        <div class="view-transfer-cell">
                          <div class="view-transfer-img">
                            <img
                              src="/images/transfer_5.png"
                              alt=""
                              class="img-fluid"
                            />
                            <h6 class="view-transfer-name">Camping</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="view-transfer-cell">May 21, 2022</div>
                      </td>
                      <td>
                        <div class="view-transfer-cell">2.5 hours</div>
                      </td>
                      <td>
                        <div class="view-transfer-cell">$ 20.00</div>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">
                        <div class="view-transfer-cell">
                          <div class="view-transfer-img">
                            <img
                              src="/images/transfer_6.png"
                              alt=""
                              class="img-fluid"
                            />
                            <h6 class="view-transfer-name">Comedy Club</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="view-transfer-cell">March 23, 2022</div>
                      </td>
                      <td>
                        <div class="view-transfer-cell">2.5 hours</div>
                      </td>
                      <td>
                        <div class="view-transfer-cell">$ 20.00</div>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">
                        <div class="view-transfer-cell">
                          <div class="view-transfer-img">
                            <img
                              src="/images/transfer_7.png"
                              alt=""
                              class="img-fluid"
                            />
                            <h6 class="view-transfer-name">Tour the city</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="view-transfer-cell">February 17, 2022</div>
                      </td>
                      <td>
                        <div class="view-transfer-cell">2.5 hours</div>
                      </td>
                      <td>
                        <div class="view-transfer-cell">$ 20.00</div>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewPayment;
