import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { REACT_APP_HOST_NAME } from "../baseurl";
import Skeleton from "react-loading-skeleton";
import Header from "./Header";
export const ContractorProfile = () => {
  const selectslider3 = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 3,
    centerMode: true,
    centerPadding: "40px",
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const [loading, setLoading] = useState(true);
  const nav = useNavigate();
  const location = useLocation();
  const [detail, setDetails] = useState({});

  const handleNavigate = () => {
    nav("/scheduled");
  };

  const getDetail = () => {
    const token = localStorage.getItem("PO_TOKEN");
    axios({
      method: "get",
      url: `${REACT_APP_HOST_NAME}/apps/plus-one-detail?plus_one_id=${location.state.id}`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setDetails(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleNav = () => {
    nav("/review_and_book", {
      state: {
        id: location.state.id,
        data: location.state.data,
        detail: detail,
      },
    });
    window.scrollTo({ behavior: "smooth", top: 0 });
  };

  useEffect(() => {
    getDetail();
  }, [location.state]);

  return (
    <>
      <Header />

      <section className="selecting-profile-section">
        {!loading ? (
          <div className="selection-profile-content">
            <div className="container">
              <div className="selecting-profile-body">
                <div className="plusone-profile-body price-tab-body pb-0">
                  <div className="selecting-profile-heading selecting-body-heading timeset-body-heading">
                    <a
                      className="text-decoration-none information-form-back"
                      onClick={handleNavigate}
                    >
                      <i className="fa-solid fa-arrow-left"></i>
                    </a>
                    <h5 className="errands-map-title errands-tab-body-title mb-0">
                      Plus One Profile
                    </h5>
                  </div>
                  <div className="row">
                    <div className="col col-12 col-md-12 col-lg-7 col-xl-7">
                      <div className="plusone-profile-content">
                        <div className="plusone-profile">
                          <div className="plusone-profile-img">
                            <img
                              src={detail.profile_pic}
                              alt=""
                              className="img-fluid"
                              style={{
                                height: "150px",
                                width: "150px",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                            />
                          </div>
                          <div className="how-work-profile-details me-0">
                            <h6 className="profile-details-title how-work-profile-title">
                              {detail.first_name} {detail.last_name}
                            </h6>
                            <span className="profile-verified ms-0">
                              <img
                                src="/images/check_circle.png"
                                alt=""
                              />
                              ID Verified
                            </span>

                            <div className="profile-details-star how-work-profile-review">
                              <img
                                src="/images/group_star.png"
                                alt=""
                              />
                              <p className="mb-0 profile-details-review section-discription">
                                4.8 <span>(5 reviews)</span>{" "}
                              </p>
                            </div>
                            <p className="profile-details-discription how-work-profile-discription section-discription text-start">
                              <i className="fa-regular fa-circle-check"></i> 5
                              Moments
                            </p>
                            <p className="profile-details-discription how-work-profile-discription section-discription text-start">
                              <i className="fa-regular fa-circle-check"></i> 1
                              Repeat Client
                            </p>
                          </div>
                        </div>
                        <div className="selecting-profile-slider">
                          <Slider {...selectslider3}>
                            <div className="selecting-profile-item">
                              <img
                                src="/images/selecting_1.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div className="selecting-profile-item">
                              <img
                                src="/images/selecting_2.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div className="selecting-profile-item">
                              <img
                                src="/images/selecting_3.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div className="selecting-profile-item">
                              <img
                                src="/images/selecting_4.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div className="selecting-profile-item">
                              <img
                                src="/images/selecting_5.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div className="selecting-profile-item">
                              <img
                                src="/images/selecting_6.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </Slider>
                        </div>
                        <div className="plusone-profile-work">
                          <div className="profile-work-list">
                            <div className="profile-work-item">
                              <p className="profile-work-link">
                                <img
                                  src="/images/Icon_Time.png"
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                Minimum Hours: 2
                              </p>
                              <p className="profile-work-link">
                                <img
                                  src="/images/Icon_Gender.png"
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                Gender: {detail.gender}
                              </p>
                              <p className="profile-work-link">
                                <img
                                  src="/images/group.png"
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                Age:25
                              </p>
                            </div>
                            <div className="profile-work-item">
                              <p className="profile-work-link">
                                <img
                                  src="/images/chat-bubble-empty.png"
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                Speaks: {detail.language.join(", ")}
                              </p>
                              <p className="profile-work-link">
                                <img
                                  src="/images/User.png"
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                Ethnicity: {detail.ethnicity}
                              </p>
                              <p className="profile-work-link">
                                <img
                                  src="/images/Icon_right.png"
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                Good Company To: Everyone
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="plusone-profile-about">
                          <h6 className="plusone-profile-title">About</h6>
                          <p className="profile-about-discription selecting-profile-discription">
                            {detail.bio}
                          </p>
                        </div>
                        <div className="plusone-profile-about">
                          <h6 className="plusone-profile-title">
                            Preferred Moments
                          </h6>
                          <div className="profile-work-list profile-preferred">
                            <div className="profile-work-item profile-preferred-item">
                              <p className="profile-work-link">
                                <img
                                  src="/images/Icon_right.png"
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                Nam pulvinar blandit velit.
                              </p>
                              <p className="profile-work-link">
                                <img
                                  src="/images/Icon_right.png"
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                Pharetra ac elit eu.
                              </p>
                              <p className="profile-work-link">
                                <img
                                  src="/images/Icon_right.png"
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                Praesent ac aliquet lorem.
                              </p>
                            </div>
                            <div className="profile-work-item">
                              <p className="profile-work-link">
                                <img
                                  src="/images/Icon_right.png"
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                Nam pulvinar blandit velit.
                              </p>
                              <p className="profile-work-link">
                                <img
                                  src="/images/Icon_right.png"
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                Pharetra ac elit eu.
                              </p>
                              <p className="profile-work-link">
                                <img
                                  src="/images/Icon_right.png"
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                Good Company To: Everyone
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col col-12 col-md-12 col-lg-5 col-xl-5 ps-5">
                      <div className="plusone-profile-review">
                        <h6 className="plusone-profile-title">Reviews</h6>
                        <p className="profile-review-rating">
                          <img
                            src="/images/Icon_Star.png"
                            alt=""
                            className="img-fluid"
                          />{" "}
                          4.8
                          <span> (10 reviews)</span>
                        </p>
                        <div className="profile-review-star-rating">
                          <div className="profile-review-no">
                            <div className="profile-review-item">
                              <h6>5</h6>
                              <img
                                src="/images/Icon_Star.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div className="profile-review-item">
                              <h6>4</h6>
                              <img
                                src="/images/Icon_Star.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div className="profile-review-item">
                              <h6>3</h6>
                              <img
                                src="/images/Icon_Star.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div className="profile-review-item">
                              <h6>2</h6>
                              <img
                                src="/images/Icon_Star.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div className="profile-review-item">
                              <h6>1</h6>
                              <img
                                src="/images/Icon_Star.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div className="profile-review-progress">
                            <div className="progress selecting-profile-prograss">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: "92%" }}
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                            <div className="progress selecting-profile-prograss">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: "11%" }}
                                aria-valuenow="10"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                            <div className="progress selecting-profile-prograss">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                            <div className="progress selecting-profile-prograss">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                            <div className="progress selecting-profile-prograss">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                          <div className="profile-review-rating-no">
                            <p>(5)</p>
                            <p>(3)</p>
                            <p>(0)</p>
                            <p>(0)</p>
                            <p>(0)</p>
                          </div>
                        </div>
                      </div>

                      <div className="plusone-client-review">
                        <h6 className="client-review-title plusone-profile-title">
                          Alex R.
                          <p>
                            <span>
                              {" "}
                              <img
                                src="/images/Icon_Star.png"
                                alt=""
                                className="img-fluid"
                              />
                            </span>
                            <span>
                              {" "}
                              <img
                                src="/images/Icon_Star.png"
                                alt=""
                                className="img-fluid"
                              />
                            </span>
                            <span>
                              {" "}
                              <img
                                src="/images/Icon_Star.png"
                                alt=""
                                className="img-fluid"
                              />
                            </span>
                            <span>
                              {" "}
                              <img
                                src="/images/Icon_Star.png"
                                alt=""
                                className="img-fluid"
                              />
                            </span>
                            <span>
                              {" "}
                              <img
                                src="/images/Icon_Star.png"
                                alt=""
                                className="img-fluid"
                              />
                            </span>
                          </p>
                        </h6>
                        <p className="client-review-date">Mon, July 25, 2022</p>
                        <p className="profile-about-discription pe-0 me-0">
                          Vestibulum dictum ultrices elit a luctus. Sed in ante
                          ut leo congue posuere at sit amet ligula. Pellentesque
                          eget augue nec nisl sodales blandit sed et sem.{" "}
                        </p>
                      </div>
                      <div className="plusone-client-review">
                        <h6 className="client-review-title plusone-profile-title">
                          Mona J.
                          <p>
                            <span>
                              {" "}
                              <img
                                src="/images/Icon_Star.png"
                                alt=""
                                className="img-fluid"
                              />
                            </span>
                            <span>
                              {" "}
                              <img
                                src="/images/Icon_Star.png"
                                alt=""
                                className="img-fluid"
                              />
                            </span>
                            <span>
                              {" "}
                              <img
                                src="/images/Icon_Star.png"
                                alt=""
                                className="img-fluid"
                              />
                            </span>
                            <span>
                              {" "}
                              <img
                                src="/images/Icon_Star.png"
                                alt=""
                                className="img-fluid"
                              />
                            </span>
                            <span>
                              {" "}
                              <img
                                src="/images/Icon_Star.png"
                                alt=""
                                className="img-fluid"
                              />
                            </span>
                          </p>
                        </h6>
                        <p className="client-review-date">Mon, July 25, 2022</p>
                      </div>
                      <div className="plusone-client-review mb-0 border-bottom-0">
                        <h6 className="client-review-title plusone-profile-title">
                          Sammy M.
                          <p>
                            <span>
                              {" "}
                              <img
                                src="/images/Icon_Star.png"
                                alt=""
                                className="img-fluid"
                              />
                            </span>
                            <span>
                              {" "}
                              <img
                                src="/images/Icon_Star.png"
                                alt=""
                                className="img-fluid"
                              />
                            </span>
                            <span>
                              {" "}
                              <img
                                src="/images/Icon_Star.png"
                                alt=""
                                className="img-fluid"
                              />
                            </span>
                            <span>
                              {" "}
                              <img
                                src="/images/Icon_Star.png"
                                alt=""
                                className="img-fluid"
                              />
                            </span>
                            <span>
                              {" "}
                              <img
                                src="/images/Icon_Star.png"
                                alt=""
                                className="img-fluid"
                              />
                            </span>
                          </p>
                        </h6>
                        <p className="client-review-date">Mon, July 25, 2022</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="selection-profile-content">
            <div className="container">
              <div className="selecting-profile-body">
                <div className="plusone-profile-body price-tab-body pb-0">
                  <div className="selecting-profile-heading selecting-body-heading timeset-body-heading">
                    <a
                      className="text-decoration-none information-form-back"
                      onClick={handleNavigate}
                    >
                      <i className="fa-solid fa-arrow-left"></i>
                    </a>
                    <h5 className="errands-map-title errands-tab-body-title mb-0">
                      Plus One Profile
                    </h5>
                  </div>
                  <div className="row">
                    <div className="col col-12 col-md-12 col-lg-7 col-xl-7">
                      <div className="plusone-profile-content">
                        <div className="plusone-profile">
                          <div className="plusone-profile-img">
                            <Skeleton
                              count={1}
                              className="sk-sel-img"
                            ></Skeleton>
                          </div>
                          <div className="how-work-profile-details me-0">
                            <h6 className="profile-details-title how-work-profile-title">
                              <Skeleton
                                count={1}
                                className="sk-sel-name"
                              ></Skeleton>
                            </h6>
                            <Skeleton
                              count={3}
                              className="sk-sel-details"
                            ></Skeleton>
                          </div>
                        </div>
                        <div className="selecting-profile-slider">
                          <div className="row sk-sel-slide">
                            <div className="col col-2">
                              <Skeleton
                                count={1}
                                className="sk-sel-gal"
                              ></Skeleton>
                            </div>
                            <div className="col col-2">
                              <Skeleton
                                count={1}
                                className="sk-sel-gal"
                              ></Skeleton>
                            </div>
                            <div className="col col-2">
                              <Skeleton
                                count={1}
                                className="sk-sel-gal"
                              ></Skeleton>
                            </div>
                            <div className="col col-2">
                              <Skeleton
                                count={1}
                                className="sk-sel-gal"
                              ></Skeleton>
                            </div>
                            <div className="col col-2">
                              <Skeleton
                                count={1}
                                className="sk-sel-gal"
                              ></Skeleton>
                            </div>
                          </div>
                        </div>
                        <div className="plusone-profile-work">
                          <div className="profile-work-list">
                            <div className="profile-work-item">
                              <Skeleton
                                count={3}
                                className="sk-sel-details"
                              ></Skeleton>
                            </div>
                            <div className="profile-work-item">
                              <Skeleton
                                count={3}
                                className="sk-sel-details"
                              ></Skeleton>
                            </div>
                          </div>
                        </div>
                        <div className="plusone-profile-about">
                          <Skeleton
                            count={1}
                            className="sk-sel-about"
                          ></Skeleton>
                          <Skeleton
                            count={2}
                            className="sk-sel-info"
                          ></Skeleton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};
