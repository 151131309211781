import React, { useEffect, useState } from "react";
import axios from "axios";
import { REACT_APP_HOST_NAME } from "../baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import NavBar from "./NavBar";
import CustomModal from "../components/CustomModal";
import OtpModal from "./OtpModal";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";

const Profile = () => {
  const [user, setUser] = useState({});
  const [timeNow, setTimeNow] = useState(Date.now() + 59000);

  const nav = useNavigate();
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [editEmail, setEditEmail] = useState(true);
  const [editMobile, setEditMobile] = useState(true);
  const [toggleOtp, setToggleOtp] = useState(false);
  const [finalToggle, setFinalToggle] = useState(false);
  const [verfied, setVerfied] = useState(false);
  const [otp, setOtp] = useState();
  const [mobileToggle, setMobileToggle] = useState(false);
  const [finalMobileToggle, setFinalMobileToggle] = useState(false);
  const [data, setData] = useState({
    country_code: "",
    mobile: "",
  });
  const handleViewProfile = () => {
    nav("/view_profile", { state: { user } });
  };

  useEffect(() => {
    if (mobile) {
      setData({
        country_code: parseInt(formatPhoneNumberIntl(mobile).split(" ")[0]),
        mobile: parseInt(formatPhoneNumber(mobile).replace(/ /g, "")),
      });
    }
    return () => { };
  }, [mobile]);

  const updateProfile = () => {
    const token = localStorage.getItem("PO_TOKEN");
    const body = new URLSearchParams();
    body.append("first_name", user.first_name);
    body.append("last_name", user.last_name);
    body.append("gender", "");
    body.append("birth_date", user.birth_date);
    body.append("email", email);
    body.append("mobile", mobile);
    axios({
      method: "post",
      url: `${REACT_APP_HOST_NAME}/apps/update-personal-info`,
      headers: { Authorization: `Bearer ${token}` },
      data: body,
    })
      .then((response) => {
        // console.log(response, "boom bam");
        setUser(response.data.data);
        setEmail(response.data.data.email);
        setMobile(response.data.data.mobile.toString());
        // setEditEmail(true);
        setEditMobile(true);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = () => {
    const token = localStorage.getItem("PO_TOKEN");
    axios({
      method: "get",
      url: `${REACT_APP_HOST_NAME}/apps/view-profile`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        console.log(response, "boom bam");

        setUser(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleChange = (otp) => {
    setOtp(otp.toString());
  };

  const handleCurrentEmailVerification = () => {
    const token = localStorage.getItem("PO_TOKEN");
    axios({
      method: "get",
      url: `${REACT_APP_HOST_NAME}/apps/send-otp-for-current-email-or-mobile?type=0`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        console.log(response, "ilu ilu");
        if (response.data.success === true) {
          setOtp("");
          setToggleOtp(true);
          setTimeNow(Date.now() + 59000);
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };
  const handleOtpSubmit = (e) => {
    // e.preventDefault();
    const token = localStorage.getItem("PO_TOKEN");
    const body = new URLSearchParams();
    body.append("type", 0);
    body.append("otp", otp);

    axios({
      method: "post",
      url: `${REACT_APP_HOST_NAME}/apps/verify-otp-for-current-email-or-mobile`,
      headers: { Authorization: `Bearer ${token}` },
      data: body,
    })
      .then((response) => {
        console.log(response, "otp bn");
        toast.success(response.data.message);
        setToggleOtp(false);
        setOtp("");
        setEditEmail(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      });
  };

  const handleEmailUpdate = () => {
    if (email) {
      const token = localStorage.getItem("PO_TOKEN");
      const body = new URLSearchParams();
      body.append("type", 0);
      body.append("email", email);

      axios({
        method: "post",
        url: `${REACT_APP_HOST_NAME}/apps/send-otp-for-new-email-mobile`,
        headers: { Authorization: `Bearer ${token}` },
        data: body,
      })
        .then((response) => {
          console.log(response, "ilu ilu222");
          if (response.data.success === true) {
            setFinalToggle(true);
            setTimeNow(Date.now() + 59000);
          }
        })
        .catch((error) => {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        });
    } else {
      toast.error("Please enter email address");
    }
  };

  const handleFinalOtp = (e) => {
    // e.preventDefault();
    const token = localStorage.getItem("PO_TOKEN");
    const body = new URLSearchParams();
    body.append("type", 0);
    body.append("email", email);
    body.append("otp", otp);

    axios({
      method: "post",
      url: `${REACT_APP_HOST_NAME}/apps/verify-otp-for-new-email-mobile`,
      headers: { Authorization: `Bearer ${token}` },
      data: body,
    })
      .then((response) => {
        console.log(response, "niooo");
        toast.success(response.data.message);
        setEmail("");
        setFinalToggle(false);

        setEditEmail(true);
        getProfile();
      })
      .catch((error) => {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      });
  };

  //Current Mobile Updation
  const handleCurrentMobileVerification = () => {
    const token = localStorage.getItem("PO_TOKEN");
    axios({
      method: "get",
      url: `${REACT_APP_HOST_NAME}/apps/send-otp-for-current-email-or-mobile?type=1`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        console.log(response, "ilu ilu from mobile");
        if (response.data.success === true) {
          toast.success(response.data.message);
          setTimeNow(Date.now() + 59000);

          setOtp("");
          setMobileToggle(true);
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      });
  };

  const handleMobileOtpSubmit = (e) => {
    // e.preventDefault();
    console.log("gg");
    const token = localStorage.getItem("PO_TOKEN");
    const body = new URLSearchParams();
    body.append("type", 1);
    body.append("otp", otp);

    axios({
      method: "post",
      url: `${REACT_APP_HOST_NAME}/apps/verify-otp-for-current-email-or-mobile`,
      headers: { Authorization: `Bearer ${token}` },
      data: body,
    })
      .then((response) => {
        console.log(response, "otp bn");
        toast.success(response.data.message);
        setMobileToggle(false);
        setOtp("");
        setEditMobile("");
      })
      .catch((error) => {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      });
  };

  const handleMobileUpdate = () => {
    if (data.mobile && data.country_code) {
      const token = localStorage.getItem("PO_TOKEN");
      const body = new URLSearchParams();
      body.append("type", 1);
      body.append("mobile", data.mobile);
      body.append("country_code", data.country_code);

      axios({
        method: "post",
        url: `${REACT_APP_HOST_NAME}/apps/send-otp-for-new-email-mobile`,
        headers: { Authorization: `Bearer ${token}` },
        data: body,
      })
        .then((response) => {
          console.log(response, "ayyo");
          if (response.data.success === true) {
            setFinalMobileToggle(true);
            setTimeNow(Date.now() + 59000);
          }
        })
        .catch((error) => {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        });
    } else {
      toast.error('Please enter mobile number');
    }
  };

  const handleFinalMobileOtp = (e) => {
    // e.preventDefault();
    const token = localStorage.getItem("PO_TOKEN");
    const body = new URLSearchParams();
    body.append("type", 1);
    body.append("mobile", data.mobile);
    body.append("country_code", data.country_code);
    body.append("otp", otp);

    axios({
      method: "post",
      url: `${REACT_APP_HOST_NAME}/apps/verify-otp-for-new-email-mobile`,
      headers: { Authorization: `Bearer ${token}` },
      data: body,
    })
      .then((response) => {
        console.log(response, "niooo");
        toast.success(response.data.message);
        setTimeNow(Date.now() + 59000);

        setMobile("");
        setFinalMobileToggle(false);
        setEditMobile(true);
        getProfile();
      })
      .catch((error) => {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      });
  };

  return (
    <>
      <Header />
      {/* Profile sharing Section Start  */}
      <section className="po-sharing-section">
        <div className="po-sharing-content">
          <NavBar />
          <div className="po-sharing-form-content mx-auto">
            <div className="po-sharing-sidebar-icon d-block d-lg-none">
              <i className="fa-solid fa-bars"></i>
            </div>
            <div className="po-sharing-form-box">
              <div className="row">
                <div className="col col-12 col-md-6">
                  <div className="po-sharing-form-input">
                    <label className="form-label">Legal Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={user.first_name}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col col-12 col-md-6">
                  <div className="po-sharing-form-input">
                    <label className="form-label label-none">.</label>
                    <input
                      type="text"
                      className="form-control"
                      value={user.last_name}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col col-12 col-md-6">
                  <div className="po-sharing-form-input">
                    <label className="form-label">Gender</label>
                    <input
                      type="text"
                      className="form-control"
                      value={user.gender ? user.gender : ""}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col col-12 col-md-6">
                  <div className="po-sharing-form-input">
                    <label className="form-label">Birthdate</label>
                    <input
                      type="text"
                      className="form-control"
                      value={moment(user.birth_date).format("DD/MM/YYYY")}
                    />
                  </div>
                </div>
              </div>
              <div className="po-sharing-form-details">
                {editEmail === true ? (
                  <div className="pos-sharing-form-details-title">
                    <h6>Email</h6>
                    {user.email ? (
                      <p>
                        {user.email[0]}*****@{user.email.split("@")[1]}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div className="pos-sharing-form-details-title">
                    <h6>Email</h6>
                    <p>
                      To continue using PlusOne, we sent you a verification
                      code, please confirm.
                    </p>
                    <input
                      type="text"
                      value={email}
                      className="form-control mt-2"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <a
                      className="btn btn-warning mt-2"
                      onClick={handleEmailUpdate}
                    >
                      Update Email
                    </a>
                  </div>
                )}
                <div className="po-sharing-form-edit">
                  <button
                    className="btn btn-outline"
                    onClick={() => {
                      handleCurrentEmailVerification();
                    }}
                    disabled={editEmail ? false : true}
                  >
                    {editEmail ? "Edit" : "Close"}
                  </button>
                </div>
              </div>
              {user.type === 1 ? <div className="po-sharing-form-details border-top-0 pt-0">
                {editMobile ? (
                  <div className="pos-sharing-form-details-title">
                    <h6>Phone Number</h6>
                    <p>
                      +{user.country_code} {user.mobile}
                    </p>
                  </div>
                ) : (
                  <div className="pos-sharing-form-details-title">
                    <h6>Phone Number</h6>
                    <PhoneInput
                      placeholder="(650) 251-53-21"
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry="IN"
                      value={mobile}
                      onChange={setMobile}
                      className="mt-2 mobile-input"
                    />
                    <p>
                      we'll send you a code to verify your number. Standard
                      message and data rates apply.
                    </p>
                    <a
                      className="btn btn-warning mt-2"
                      onClick={handleMobileUpdate}
                    >
                      Update Mobile
                    </a>
                  </div>
                )}
                <div className="po-sharing-form-edit">
                  <button
                    className="btn btn-outline"
                    onClick={() => {
                      handleCurrentMobileVerification();
                    }}
                    disabled={editMobile ? false : true}
                  >
                    {editMobile ? "Edit" : "Close"}
                  </button>
                </div>
              </div> : ""}
              <div className="po-sharing-form-details border-0 p-0 m-0">
                <div className="pos-sharing-form-details-title">
                  <h6>Government ID</h6>
                  <p>US Passport</p>
                </div>
                <div className="po-sharing-form-edit">
                  <button
                    className="btn btn-outline"
                    onClick={() => {
                      handleCurrentMobileVerification();
                    }}
                    disabled={editMobile ? false : true}
                  >
                    {editMobile ? "Edit" : "Close"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Profile sharing Section End   */}
      <CustomModal
        isModalOpen={toggleOtp}
        handleOk={() => setToggleOtp(false)}
        handleCancel={() => setToggleOtp(false)}
      >
        <OtpModal
          handleOk={() => setToggleOtp(false)}
          mobile={"your registered Email"}
          letedit={false}
          handleChange={handleChange}
          otp={otp}
          handleOtpSubmit={handleOtpSubmit}
          timeNow={timeNow}
          setTimeNow={(e) => setTimeNow(e)}
        />
      </CustomModal>

      <CustomModal
        isModalOpen={finalToggle}
        handleOk={() => setFinalToggle(false)}
        handleCancel={() => setFinalToggle(false)}
      >
        <OtpModal
          handleOk={() => setFinalToggle(false)}
          mobile={"your registered Email"}
          letedit={false}
          handleChange={handleChange}
          otp={otp}
          handleOtpSubmit={handleFinalOtp}
          timeNow={timeNow}
          setTimeNow={(e) => setTimeNow(e)}
        />
      </CustomModal>

      <CustomModal
        isModalOpen={mobileToggle}
        handleOk={() => setMobileToggle(false)}
        handleCancel={() => setMobileToggle(false)}
      >
        <OtpModal
          handleOk={() => setMobileToggle(false)}
          mobile={"your registered Email"}
          letedit={false}
          handleChange={handleChange}
          otp={otp}
          handleOtpSubmit={handleMobileOtpSubmit}
          timeNow={timeNow}
          setTimeNow={(e) => setTimeNow(e)}
        />
      </CustomModal>

      <CustomModal
        isModalOpen={finalMobileToggle}
        handleOk={() => setFinalMobileToggle(false)}
        handleCancel={() => setFinalMobileToggle(false)}
      >
        <OtpModal
          handleOk={() => setFinalMobileToggle(false)}
          mobile={"your registered Email"}
          letedit={false}
          handleChange={handleChange}
          otp={otp}
          handleOtpSubmit={handleFinalMobileOtp}
          timeNow={timeNow}
          setTimeNow={(e) => setTimeNow(e)}
        />
      </CustomModal>
    </>
  );
};

export default Profile;
