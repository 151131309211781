import React, { useState, useEffect } from "react";
import axios from "axios";
import { REACT_APP_HOST_NAME } from "../baseurl";
import { useLocation, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const Selecting = () => {
  const [gender, setGender] = useState([]);
  const [age, setAge] = useState([]);
  const [isDataThere, setIsDataThere] = useState(true);

  const getContractorList = () => {
    const token = localStorage.getItem("PO_TOKEN");
    var bodyFormData = new URLSearchParams();

    bodyFormData.append("gender", JSON.stringify(gender));
    bodyFormData.append("age", JSON.stringify(age));
    bodyFormData.append("page", 1);
    bodyFormData.append("day", loc.state.day);
    bodyFormData.append("date", loc.state.date);
    bodyFormData.append("lat", loc.state.location.latitude);
    bodyFormData.append("long", loc.state.location.longitude);
    bodyFormData.append("time", loc.state.time);
    axios({
      method: "post",
      url: `${REACT_APP_HOST_NAME}/apps/filter-plus-one`,
      data: bodyFormData,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (response.data.data.length === 0) {
          setIsDataThere(false);
        }
        setList(response.data.data);
        console.log("list", response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const [list, setList] = useState([]);
  const nav = useNavigate();
  const loc = useLocation();
  const [data, setData] = useState(loc.state);
  const handleNavigate = (id, elem) => {
    nav("/selecting_profile", { state: { id, data: data, details: elem } });
    window.scrollTo({ behavior: "smooth", top: 0 });
  };

  const handleChange = (e) => {
    setList([]);
    let gen = [...gender];
    let ag = [...age];
    if (e.target.name === "gender") {
      if (e.target.checked) {
        gen.push(e.target.value);
        setGender(gen);
      } else {
        let index = gen.indexOf(e.target.value);
        gen.splice(index, 1);
        setGender(gen);
      }
    } else {
      if (e.target.checked) {
        ag.push(e.target.value);
        setAge(ag);
      } else {
        let index = ag.indexOf(e.target.value);
        ag.splice(index, 1);
        setAge(ag);
      }
    }
    const token = localStorage.getItem("PO_TOKEN");
    var bodyFormData = new URLSearchParams();

    bodyFormData.append("gender", JSON.stringify(gen));
    bodyFormData.append("age", JSON.stringify(ag));
    bodyFormData.append("page", 1);
    bodyFormData.append("day", loc.state.day);
    bodyFormData.append("date", loc.state.date);
    bodyFormData.append("lat", loc.state.location.latitude);
    bodyFormData.append("long", loc.state.location.longitude);
    bodyFormData.append("time", loc.state.time);
    axios({
      method: "post",
      url: `${REACT_APP_HOST_NAME}/apps/filter-plus-one`,
      data: bodyFormData,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setList(response.data.data);
        setIsDataThere(response.data.data.length > 0 ? true : false);
        console.log("list", response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (loc.state) {
      setData(loc.state);
    }
    getContractorList();
  }, []);
  return (
    <>
      {/* Selecting Section Start */}
      <section className="selecting-section">
        <div className="selecting-content">
          <div className="r-book-heading">
            <div className="r-book-logo information-logo">
              <img src="/images/logo.png" alt="" className="img-fluid" onClick={() => {
                nav("/home");
              }} />
            </div>
            <div className="r-book-progress information-tab-progress">
              <div className="r-book-progress-title information-progress-title">
                <h6 className="mb-0">
                  <span>Your progress:</span> 50% Complete
                </h6>
              </div>
              <div className="progress w-100">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "50%" }}
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="selecting-body-heading timeset-body-heading">
              <a
                onClick={() =>
                  nav("/set_shedule", {
                    state: {
                      location: data.location,
                      add: data.add,
                      time: data.time,
                      topRatedId: data.topRatedId,
                      date: data.date,
                    },
                  })
                }
                className="text-decoration-none information-form-back"
              >
                <i className="fa-solid fa-arrow-left"></i>
              </a>
              <h5 className="errands-map-title errands-tab-body-title  mb-0">
                Select a Plus One
              </h5>
            </div>
          </div>
          <p className="price-heading-discription">
            You're in good company. We background check every Plus One.
            <img src="/images/Badge_Health.png" alt="" className="img-fluid" />
          </p>
          <div className="container">
            <div className="selecting-body">
              <div className="price-tab-body">
                <div className="row">
                  <div className="col col-12 col-md-12 col-lg-4 col-xl-4">
                    <div className="price-content">
                      <div className="price-age-range price-type">
                        <h5 className="price-type-title">Age Range</h5>
                        <div className="form-check price-type-checkbox">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="age"
                            value="18 - 29"
                            id="Checktype6"
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Checktype6"
                          >
                            18 - 29
                          </label>
                        </div>
                        <div className="form-check price-type-checkbox">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="age"
                            value="30 - 39"
                            id="Checktype8"
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Checktype8"
                          >
                            30 - 39
                          </label>
                        </div>
                        <div className="form-check price-type-checkbox">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="age"
                            value="40 - 49"
                            id="Checktype2"
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Checktype2"
                          >
                            40 - 49
                          </label>
                        </div>
                        <div className="form-check price-type-checkbox">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="age"
                            value="49 - 100"
                            id="Checktype3"
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Checktype3"
                          >
                            49+
                          </label>
                        </div>
                      </div>
                      <div className="price-type border-0 pt-4">
                        <h5 className="price-type-title">
                          I’m looking for a...
                        </h5>
                        <div className="form-check price-type-checkbox">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="Checktype7"
                            onChange={handleChange}
                            name="gender"
                            value="Male"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Checktype7"
                          >
                            Male
                          </label>
                        </div>
                        <div className="form-check price-type-checkbox">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="Checktype4"
                            onChange={handleChange}
                            name="gender"
                            value="Female"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Checktype4"
                          >
                            Female
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="price-helth">
                      <img
                        src="/images/price_health.png"
                        alt=""
                        className="img-fluid"
                      />
                      <p className="price-helth-discription">
                        Always have a peace of mind. All Plus Ones undergo ID
                        and criminal background checks.{" "}
                        <a href="javascript:void(0)">Learn More</a>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col col-12 col-md-12 col-lg-8 col-xl-8">
                    {list.length > 0 ? (
                      <div className="selecting-body-profiles">
                        <div className="row">
                          {list.map((elem) => {
                            return (
                              <div
                                className="col-12"
                                onClick={() => handleNavigate(elem._id, elem)}
                                style={{ cursor: "pointer" }}
                              >
                                <div className="price-profile-content">
                                  <div className="price-profile">
                                    <div className="price-profile-review">
                                      <div className="price-profile-img">
                                        <img
                                          src={elem.profile_pic}
                                          alt=""
                                          className="img-fluid"
                                          style={{
                                            height: "150px",
                                            width: "150px",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="price-profile-details">
                                      <div className="price-profile-name">
                                        <div className="how-work-profile-details">
                                          <h6 className="profile-details-title how-work-profile-title mb-0">
                                            {elem.first_name}{" "}
                                            {elem.last_name[0]}.
                                            <span className="profile-verified">
                                              <img
                                                src="/images/check_circle.png"
                                                alt=""
                                                className="img-fluid"
                                              />
                                              ID Verified
                                            </span>
                                          </h6>
                                          <p className="profile-about-disctipion">
                                            {elem.quote}
                                          </p>
                                          <p className="profile-details-discription how-work-profile-discription section-discription text-start mt-2">
                                            <i className="fa-regular fa-circle-check"></i>{" "}
                                            {elem.completed_moment} Moments
                                          </p>
                                          <p className="profile-details-discription how-work-profile-discription section-discription text-start">
                                            <i className="fa-regular fa-circle-check"></i>{" "}
                                            {elem.repeative_client} Repeat
                                            Client
                                          </p>
                                        </div>
                                        <div className="price-details-price">
                                          <h4 className="mb-0">
                                            ${elem.hourly_rate}/hr
                                            <img
                                              src="/images/price_arrow.png"
                                              alt=""
                                              className="img-fluid"
                                            />
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        {list.length > 10 ? (
                          <a
                            href="javascript:void(0)"
                            className="slecting-body-profile-more"
                          >
                            Load more
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : isDataThere ? (
                      <div className="selecting-body-profiles">
                        <div className="row">
                          <div className="col-12">
                            <div className="price-profile-content">
                              <div className="price-profile">
                                <div className="price-profile-review">
                                  <div className="price-profile-img">
                                    <Skeleton
                                      count={1}
                                      className="sk-sel-img"
                                    ></Skeleton>
                                  </div>
                                </div>
                                <div className="price-profile-details">
                                  <div className="price-profile-name">
                                    <div className="how-work-profile-details">
                                      <h6 className="profile-details-title how-work-profile-title mb-0">
                                        <Skeleton
                                          count={1}
                                          className="sk-sel-name"
                                        ></Skeleton>
                                      </h6>
                                      <Skeleton
                                        count={3}
                                        className="sk-sel-details"
                                      ></Skeleton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="price-profile-content">
                              <div className="price-profile">
                                <div className="price-profile-review">
                                  <div className="price-profile-img">
                                    <Skeleton
                                      count={1}
                                      className="sk-sel-img"
                                    ></Skeleton>
                                  </div>
                                </div>
                                <div className="price-profile-details">
                                  <div className="price-profile-name">
                                    <div className="how-work-profile-details">
                                      <h6 className="profile-details-title how-work-profile-title mb-0">
                                        <Skeleton
                                          count={1}
                                          className="sk-sel-name"
                                        ></Skeleton>
                                      </h6>
                                      <Skeleton
                                        count={3}
                                        className="sk-sel-details"
                                      ></Skeleton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="price-profile-content">
                              <div className="price-profile">
                                <div className="price-profile-review">
                                  <div className="price-profile-img">
                                    <Skeleton
                                      count={1}
                                      className="sk-sel-img"
                                    ></Skeleton>
                                  </div>
                                </div>
                                <div className="price-profile-details">
                                  <div className="price-profile-name">
                                    <div className="how-work-profile-details">
                                      <h6 className="profile-details-title how-work-profile-title mb-0">
                                        <Skeleton
                                          count={1}
                                          className="sk-sel-name"
                                        ></Skeleton>
                                      </h6>
                                      <Skeleton
                                        count={3}
                                        className="sk-sel-details"
                                      ></Skeleton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="price-profile-content">
                              <div className="price-profile">
                                <div className="price-profile-review">
                                  <div className="price-profile-img">
                                    <Skeleton
                                      count={1}
                                      className="sk-sel-img"
                                    ></Skeleton>
                                  </div>
                                </div>
                                <div className="price-profile-details">
                                  <div className="price-profile-name">
                                    <div className="how-work-profile-details">
                                      <h6 className="profile-details-title how-work-profile-title mb-0">
                                        <Skeleton
                                          count={1}
                                          className="sk-sel-name"
                                        ></Skeleton>
                                      </h6>
                                      <Skeleton
                                        count={3}
                                        className="sk-sel-details"
                                      ></Skeleton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="price-profile-content">
                              <div className="price-profile">
                                <div className="price-profile-review">
                                  <div className="price-profile-img">
                                    <Skeleton
                                      count={1}
                                      className="sk-sel-img"
                                    ></Skeleton>
                                  </div>
                                </div>
                                <div className="price-profile-details">
                                  <div className="price-profile-name">
                                    <div className="how-work-profile-details">
                                      <h6 className="profile-details-title how-work-profile-title mb-0">
                                        <Skeleton
                                          count={1}
                                          className="sk-sel-name"
                                        ></Skeleton>
                                      </h6>
                                      <Skeleton
                                        count={3}
                                        className="sk-sel-details"
                                      ></Skeleton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="text-center" style={{ fontSize: "20px" }}>
                        No Plusone Available
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Selecting Section End */}
    </>
  );
};

export default Selecting;
