import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { REACT_APP_HOST_NAME } from "../baseurl";
import Header from "./Header";

const PaymentMethod = () => {
  const nav = useNavigate();
  const location = useLocation();
  const data = location.state.data;
  const id = location.state.id;
  const detail = location.state.detail;
  const total = parseInt(location.state.taxedTotalAmount);
  const [voucher, setVouchers] = useState([]);
  const [voucherId, setVoucherId] = useState("");
  const [selectedVoucher, setSelectedVoucher] = useState({});

  const getVouchers = () => {
    const token = localStorage.getItem("PO_TOKEN");
    var body = new URLSearchParams();
    body.append("moment_amount", total);
    axios({
      method: "post",
      url: `${REACT_APP_HOST_NAME}/apps/applicable-voucher`,
      data: body,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        console.log(response.data.data, "hamra data");
        setVouchers(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    console.log(voucherId);
  }, [voucherId]);

  const handleVoucher = (elem) => {
    setVoucherId(elem.id);
    setSelectedVoucher(elem);
  };

  const applyVoucher = () => {
    nav("/review_and_book2", { state: { selectedVoucher, id, data, detail } });
  };
  return (
    <>
      {/* Payment Method Section Start  */}
      <section className="p-method-section">
        <div className="p-method-content">
          <div className="r-book-heading">
            <div className="r-book-logo information-logo">
              <img src="/images/logo.png" alt="" className="img-fluid" onClick={() => {
                nav("/home");
              }} />
            </div>
            <div className="r-book-progress information-tab-progress">
              <div className="r-book-progress-title information-progress-title">
                <h6 className="mb-0">
                  <span>Your progress:</span> 70% Complete
                </h6>
              </div>
              <div className="progress w-100">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "70%" }}
                  aria-valuenow="70"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="plusone-payment-body price-tab-body">
              <div className="plusone-payment-heading plusone-profile-heading">
                <a
                  href="javascript:void(0)"
                  className="text-decoration-none information-form-back mb-0"
                >
                  <i className="fa-solid fa-arrow-left"></i>
                </a>
                <h2 className="plusone-heading-title">Payment Method</h2>
              </div>
              <div className="plusone-payment-method plusone-profile-price">
                <div
                  className="payment-method-link"
                  onClick={() => {
                    nav("/review_and_book2", {
                      state: { id, data, detail },
                    });
                  }}
                >
                  <div className="payment-card-img">
                    <a>
                      <div className="payment-card-img">
                        <a href="#">
                          <svg
                            width="52"
                            height="31"
                            viewBox="0 0 52 31"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.943359"
                              y="0.108093"
                              width="50.5883"
                              height="30"
                              rx="2"
                              fill="#FFE49C"
                            />
                            <rect
                              x="36.7197"
                              y="6.12033"
                              width="9.46521"
                              height="6.76086"
                              rx="1"
                              fill="#FFBB00"
                            />
                            <rect
                              x="6.29053"
                              y="18.6873"
                              width="8.11303"
                              height="5.40869"
                              rx="1"
                              fill="white"
                            />
                            <rect
                              x="16.8843"
                              y="18.6872"
                              width="8.11303"
                              height="5.40869"
                              rx="1"
                              fill="white"
                            />
                            <rect
                              x="27.4785"
                              y="18.6872"
                              width="8.11303"
                              height="5.40869"
                              rx="1"
                              fill="white"
                            />
                            <rect
                              x="38.0723"
                              y="18.6872"
                              width="8.11303"
                              height="5.40869"
                              rx="1"
                              fill="white"
                            />
                          </svg>
                        </a>
                      </div>
                    </a>
                  </div>
                  <div
                    className="payment-card-name"
                    onClick={() =>
                      nav("/review_and_book2", { state: { id, data, detail } })
                    }
                  >
                    <a
                      href="javascript:void(0)"
                      className="text-decoration-none"
                    >
                      <h5>Credit or Debit Card</h5>
                    </a>
                  </div>

                  <div className="payment-card-icon">
                    <a href="javascript:void(0)">
                      <img
                        src="/images/right_arrow.png"
                        alt=""
                        className="img-fluid"
                      />
                    </a>
                  </div>
                </div>
                <div className="payment-method-link">
                  <div className="payment-card-img">
                    <a href="javascript:void(0)">
                      <img
                        src="/images/benefits_2.png"
                        alt=""
                        className="img-fluid w-100"
                      />
                    </a>
                  </div>
                  <div className="payment-card-name">
                    <a
                      href="javascript:void(0)"
                      className="text-decoration-none"
                    >
                      <h5>Commuter Benefits</h5>
                    </a>
                  </div>
                  <div className="payment-card-icon">
                    <a href="javascript:void(0)">
                      <img
                        src="/images/right_arrow.png"
                        alt=""
                        className="img-fluid"
                      />
                    </a>
                  </div>
                </div>
                <div className="payment-method-link">
                  <div className="payment-card-img">
                    <a href="javascript:void(0)">
                      <img
                        src="/images/paypal.png"
                        alt=""
                        className="img-fluid w-100"
                      />
                    </a>
                  </div>
                  <div className="payment-card-name">
                    <a
                      href="javascript:void(0)"
                      className="text-decoration-none"
                    >
                      <h5>Paypal</h5>
                    </a>
                  </div>
                  <div className="payment-card-icon">
                    <a href="javascript:void(0)">
                      <img
                        src="/images/right_arrow.png"
                        alt=""
                        className="img-fluid"
                      />
                    </a>
                  </div>
                </div>
                <div className="payment-method-promo" onClick={getVouchers}>
                  <a
                    href="javascript:void(0)"
                    data-bs-toggle="modal"
                    data-bs-target="#voucherModal"
                  >
                    <b> Select Vouchers</b>
                  </a>
                  {/* <a href="javascript:void(0)">Add Code</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Payment Method Section End  */}

      {/* Voucher Modal Start  */}
      <div
        className="modal fade voucher-modal"
        id="voucherModal"
        tabIndex="-1"
        aria-labelledby="voucherModal"
        aria-hidden="true"
      >
        <div className="modal-dialog voucher-modal-dialog">
          <div className="modal-content rounded-0 p-3">
            <div class="modal-header" style={{ border: "none" }}>
              <h2 className="voucher-modal-title section-title m-0">
                Select a Voucher
              </h2>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body voucher-modal-body">
              {voucher.length > 0 ? (
                voucher.map((vou) => (
                  <div className="voucher-modal-details">
                    <input
                      className="form-check-input voucher-modal-radio"
                      type="radio"
                      name="flexRadioDefault"
                      id="voucherRadio2"
                      // value={vou.id}
                      onChange={() => handleVoucher(vou)}
                    />
                    <div className="voucher-modal-view-details">
                      <div className="voucher-modal-customerr">
                        <h6 className="voucher-details-title massage-review-title">
                          {vou.voucher_name}
                        </h6>
                        {/* <span className="vouche-details-subtitle">
                            New customers
                          </span> */}
                        <small className="voucher-valid-date">
                          Valid Till - {vou.expiry_date}
                        </small>
                        {/* <a
                            href="javascript:void(0)"
                            className="voucher-details-btn"
                          >
                            View Details
                          </a> */}
                      </div>
                      <div className="voucher-price">
                        <h6 className="voucher-details-title massage-review-title">
                          $ {vou.voucher_amount.toFixed(2)}
                        </h6>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="d-flex justify-content-center">
                  <div className="">
                    <img
                      src="/images/no-voucher.png"
                      alt="gud"
                      style={{ height: "100px", width: "200px" }}
                    />
                    <p> No Applicable Vouchers</p>
                  </div>
                </div>
              )}

              <div className="voucher-modal-btn text-center">
                <button
                  className="btn btn-warning"
                  data-bs-dismiss="modal"
                  onClick={applyVoucher}
                  disabled={voucherId === "" ? true : false}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Voucher Modal End </div> */}
    </>
  );
};

export default PaymentMethod;
