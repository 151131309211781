import React, { useState, useEffect, useRef, useCallback } from "react";
import SimpleReactValidator from "simple-react-validator";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import CustomModal from "../../components/CustomModal";
import OtpModal from "../../components/OtpModal";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GoogleLogin, { useGoogleLogout } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { REACT_APP_HOST_NAME } from "../../baseurl";
import { gapi } from 'gapi-script';

const Login = ({ handleNavigate }) => {
  const nav = useNavigate();
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: "1010227444615-ichhbkuqatclbbk7mrtik0i8sh387sgk.apps.googleusercontent.com",
        scope: 'email',
      });
    }
    gapi.load('client:auth2', start);
  }, []);

  const { signOut } = useGoogleLogout({
    clientId: "1010227444615-ichhbkuqatclbbk7mrtik0i8sh387sgk.apps.googleusercontent.com",
  })

  const handleEmail = () => {
    nav("/login_email");
  };
  const [timeNow, setTimeNow] = useState(Date.now() + 59000);

  const [data, setData] = useState({
    country_code: "",
    mobile: "",
  });
  const [mobile, setMobile] = useState();
  const validator = useRef(new SimpleReactValidator({}));
  const [toggleOtp, setToggleOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [load, setLoad] = useState({ facebook: false, google: false });
  const [user, setUser] = useState({
    id: "",
    email: "",
  });
  const [time, setTime] = useState(59);
  const [status, setStatus] = useState('Log In');

  const responseFacebook = (response) => {
    console.log(response);
    setStatus('Processing')
    let payload = { email: response.email, social_id: response.userId, device_token: '123435' };
    axios
      .post(`${REACT_APP_HOST_NAME}/apps/login-with-social`, payload, {
        headers: {
          // "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        setToggleOtp(false);
        handleNavigate("/home");
        localStorage.setItem("PO_TOKEN", response.data.data.token);
        localStorage.setItem("PO_USER_ID", response.data.data._id);
      })
      .catch((error) => {
        console.log("error", error);
        let payload = {
          email: response.email, social_id: response.userId, device_token: '123435',
          first_name: response.name.split(" ")[0], last_name: response.name.split(" ")[1], profile_pic: response.picture.data.url, type: 3
        };
        axios
          .post(`${REACT_APP_HOST_NAME}/apps/signup-with-social`, payload, {
            headers: {
              // "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
          .then((response) => {
            // setToggleOtp(false);
            handleNavigate("/home");
            localStorage.setItem("PO_TOKEN", response.data.data.token);
            localStorage.setItem("PO_USER_ID", response.data.data._id);
          })
          .catch((error) => {
            console.log("error", error);
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            signOut();
          });
      });
  };
  useEffect(() => {
    if (mobile) {
      setData({
        country_code: parseInt(formatPhoneNumberIntl(mobile).split(" ")[0]),
        mobile: parseInt(formatPhoneNumber(mobile).replace(/ /g, "")),
      });
    }
    return () => { };
  }, [mobile]);

  const handleChange = (otp) => {
    setOtp(otp.toString());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setError("");
      axios
        .post(`${REACT_APP_HOST_NAME}/apps/login-with-phone`, data, {
          headers: {
            // "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setTime(59);
          // setToggleSignUp(false);
          setTimeNow(Date.now() + 59000);
          setToggleOtp(true);
          setUser({
            id: response.data.data.user_id,
            email: response.data.data.user_email,
          });
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log("error", error.response.data.message);
        });
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };

  const handleOtpSubmit = (e) => {
    e.preventDefault();
    if (validOTP()) {
      setMessage("");
      const { country_code, mobile } = data;
      const user_id = user.id;
      const user_email = user.email;
      let payload = { country_code, mobile, otp, user_id, user_email, device_token: '123435' };
      axios
        .post(`${REACT_APP_HOST_NAME}/apps/login-verify-otp`, payload, {
          headers: {
            // "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          setToggleOtp(false);
          handleNavigate("/home");
          localStorage.setItem("PO_TOKEN", response.data.data.token);
          localStorage.setItem("PO_USER_ID", response.data.data._id);
        })
        .catch((error) => {
          console.log("error", error);
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };
  const [error, setError] = useState("");
  const validate = () => {
    let isvalid = true;
    if (!mobile) {
      setError("Please Enter Mobile Number");
      isvalid = false;
    }
    return isvalid;
  };
  const responseGoogle = (response) => {
    console.log(response);
    setStatus('Processing')
    let payload = { email: response.profileObj.email, social_id: response.profileObj.googleId, device_token: '123435' };
    axios
      .post(`${REACT_APP_HOST_NAME}/apps/login-with-social`, payload, {
        headers: {
          // "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        setToggleOtp(false);
        handleNavigate("/home");
        localStorage.setItem("PO_TOKEN", response.data.data.token);
        localStorage.setItem("PO_USER_ID", response.data.data._id);
      })
      .catch((error) => {
        let payload = {
          email: response.profileObj.email, social_id: response.profileObj.googleId, device_token: '123435',
          first_name: response.profileObj.givenName, last_name: response.profileObj.familyName, profile_pic: response.profileObj.imageUrl, type: 2
        };
        axios
          .post(`${REACT_APP_HOST_NAME}/apps/signup-with-social`, payload, {
            headers: {
              // "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
          .then((response) => {
            // setToggleOtp(false);
            handleNavigate("/home");
            localStorage.setItem("PO_TOKEN", response.data.data.token);
            localStorage.setItem("PO_USER_ID", response.data.data._id);
          })
          .catch((error) => {
            console.log("error", error);
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            signOut();
          });
      });
  };
  const [message, setMessage] = useState("");
  const validOTP = () => {
    let isvalid = true;
    if (!otp) {
      setMessage("Please Enter OTP");
      isvalid = false;
    }
    return isvalid;
  };

  return (
    <>
      {/* Site Content Start */}
      <main className="po-site-content">
        {/* Login Section Start */}
        <section className="po-login-section">
          <div className="container-fluid">
            <div className="po-login-content">
              <div className="row ">
                <div className="col col-12 col-md-12 col-lg-6 col-xl-6 p-0">
                  <div className="login-img">
                    <img
                      src="/images/login_img.png"
                      alt=""
                      className="img-fluid m-0"
                    />
                  </div>
                </div>
                <div className="col col-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="login-form-area">
                    <div className="login-body-content">
                      <div className="login-logo">
                        <img
                          src="/images/logo.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="login-input">
                        <label htmlFor="form-label">
                          Enter your mobile number
                        </label>
                        <div
                          className="login-country-input"
                          style={{
                            width: "100%",
                            borderBottom: "0.5px solid #959da3",
                          }}
                        >
                          <PhoneInput
                            placeholder="(650) 251-53-21"
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="IN"
                            value={mobile}
                            onChange={setMobile}
                          />
                        </div>
                        <div
                          style={{
                            color: "red",
                            fontSize: "13px",
                            marginTop: "10px",
                          }}
                        >
                          <div
                            style={{
                              color: "red",
                              fontSize: "13px",
                              marginLeft: "30px",
                            }}
                          >
                            {error}
                          </div>
                        </div>
                      </div>
                      <div className="login-btn text-center">
                        <button
                          type="submit"
                          className="btn btn-warning"
                          data-bs-toggle="modal"
                          data-bs-target="#loginModal"
                          onClick={handleSubmit}
                        >
                          {status}
                        </button>
                      </div>
                      <p className="login-forgot">
                        <span className="po-login-forgot-pass-text">
                          &nbsp; Forgot password? &nbsp;
                        </span>
                        <Link
                          className="po-login-forgot-link"
                          to="/forget_password"
                        >
                          Reset it
                        </Link>
                      </p>
                      <div className="login-other-option">
                        <span className="login-other-title">
                          or continue with
                        </span>
                        <div
                          className="login-option-list"
                          onClick={() => {
                            setLoad({ ...load, facebook: true });
                          }}
                        >
                          <div className="login-option-item">
                            <a
                              // href="javascript:void(0)"
                              className="login-option-link"
                            // onClick={() => {
                            //   setFacebookLoad(true);
                            // }}
                            >
                              <FacebookLogin
                                appId="592951159190226"
                                // autoLoad={load.facebook}
                                fields="name,email,picture"
                                // onClick={componentClicked}
                                callback={responseFacebook}
                                cssClass="p-0 m-0 border-0 bg-transparent"
                                textButton="Continue with Facebook"
                              />
                            </a>
                          </div>
                          <div
                            className="login-option-item"
                            onClick={() => {
                              setLoad({ ...load, google: true });
                            }}
                          >
                            <a className="login-option-link">
                              <GoogleLogin
                                clientId="1010227444615-ichhbkuqatclbbk7mrtik0i8sh387sgk.apps.googleusercontent.com"
                                buttonText="Login"
                                // autoLoad={load.google}
                                onSuccess={responseGoogle}
                                onFailure={signOut}
                                isSignedIn='true'
                                prompt='select_account'
                                render={(renderProps) => (
                                  <button
                                    onClick={renderProps.onClick}
                                    disabled={renderProps.disabled}
                                    className="p-0 m-0 border-0 bg-transparent"
                                  >
                                    Continue with Gmail
                                  </button>
                                )}
                              // cookiePolicy={"single_host_origin"}
                              />
                            </a>
                          </div>

                          <div
                            className="login-option-item"
                            onClick={handleEmail}
                          >
                            <a
                              // href="login_email.html"
                              className="login-option-link"
                            >
                              Continue with Email
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Login Section End */}
      </main>

      {/* Login Modal Start */}

      {/* Otp Modal Start */}
      <CustomModal
        isModalOpen={toggleOtp}
        handleOk={() => {
          setTime(59);
          setToggleOtp(false);
        }}
        handleCancel={() => {
          setTime(59);
          setToggleOtp(false);
        }}
      >
        <OtpModal
          country_code={data.country_code}
          mobile={data.mobile}
          handleChange={handleChange}
          handleOtpSubmit={handleOtpSubmit}
          otp={otp}
          handleOk={() => {
            setTime(59);
            setToggleOtp(false);
          }}
          message={message}
          handleSubmit={handleSubmit}
          timeNow={timeNow}
          setTimeNow={(e) => setTimeNow(e)}
          url="login-with-phone"
        />
      </CustomModal>
    </>
  );
};

export default Login;
