import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { REACT_APP_HOST_NAME } from "../baseurl";
import Header from "./Header";
import NavBar from "./NavBar";
import { usePaymentInputs } from "react-payment-inputs";
import { toast } from "react-toastify";

const AddPaymentMethod = () => {
  const nav = useNavigate();
  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs();
  const [cardNo, setCardNo] = useState();
  const [expiration, setExpiration] = useState();
  const [cvc, setCVC] = useState();
  const [postCode, setPostCode] = useState();
  const [country, setCountry] = useState("US");
  const [error, setError] = useState({});
  const [disable, setDisable] = useState(false);
  const [getDefault, setGetDefault] = useState([]);
  function valid() {
    let isValid = true;

    if (!cardNo || cardNo.length != 19) {
      isValid = false;
    }
    if (!expiration) {
      isValid = false;
    } else {
      if (expiration.split("/").length > 1) {
        var today, someday;
        today = new Date();
        someday = new Date();
        someday.setFullYear(
          String(today.getFullYear()).slice(0, 2) +
          expiration.split("/")[1].trim(),
          expiration.split("/")[0].trim() - 1,
          1
        );
        if (someday < today) isValid = false;
      } else isValid = false;
    }
    if (!cvc) {
      isValid = false;
    }
    if (!postCode) {
      isValid = false;
    }
    if (!country) {
      isValid = false;
    }
    return isValid;
  }
  function validation() {
    let error = {},
      isValid = true;

    if (!cardNo || cardNo.length != 19) {
      error["card_number"] = "Enter Valid Card Number";
      isValid = false;
    }
    if (!expiration) {
      error["card_exp"] = "Enter Valid Card Expiration";
      isValid = false;
    } else {
      var today, someday;
      today = new Date();
      someday = new Date();
      someday.setFullYear(
        String(today.getFullYear()).slice(0, 2) +
        expiration.split("/")[1].trim(),
        expiration.split("/")[0].trim() - 1,
        1
      );
      if (someday < today) {
        error["card_exp"] = "Enter Valid Card Expiration";
        isValid = false;
      }
    }
    if (!cvc) {
      error["card_cvc"] = "Enter Valid Card CVC";
      isValid = false;
    }
    if (!postCode) {
      error["postcode"] = "Enter Valid Card Postcode";
      isValid = false;
    }
    if (!country) {
      error["country"] = "Enter Valid Card country";
      isValid = false;
    }
    setError(error);
    return isValid;
  }
  function detectCardType(number) {
    var re = {
      electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
      maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
      dankort: /^(5019)\d+$/,
      interpayment: /^(636)\d+$/,
      unionpay: /^(62|88)\d+$/,
      visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      mastercard: /^5[1-5][0-9]{14}$/,
      amex: /^3[47][0-9]{13}$/,
      diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}$/
    }

    for (var key in re) {
      if (re[key].test(number)) {
        return key
      }
    }
  }
  const addCard = () => {
    setDisable(true);
    if (validation()) {
      const token = localStorage.getItem("PO_TOKEN");
      const cardType = detectCardType(cardNo.replaceAll(" ", ""));
      var body = new URLSearchParams();
      body.append("card_number", cardNo);
      body.append("expiry_month", expiration.split("/")[0].trim());
      body.append("expiry_year", expiration.split("/")[1].trim());
      body.append("cvv", cvc);
      body.append("postal_code", postCode);
      body.append("country", country);
      body.append('card_brand', cardType);

      axios({
        method: "post",
        url: `${REACT_APP_HOST_NAME}/apps/add-card`,
        data: body,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          setDisable(false);
          setCVC(undefined);
          setCardNo(undefined);
          setExpiration(undefined);
          setCountry("US");
          setPostCode(undefined);
          listCard();
          toast.success("Card Added Successfully.");
          console.log(response);
        })
        .catch((error) => {
          console.log("nigga");
          console.log("error", error);
          toast.error(error?.response?.data?.message);
          setDisable(false);
        });
    } else {
      setDisable(false);
    }
  };

  const listCard = () => {
    const token = localStorage.getItem("PO_TOKEN");

    axios({
      method: "get",
      url: `${REACT_APP_HOST_NAME}/apps/list-card`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setGetDefault(response.data.data);
        console.log(response);
      })
      .catch((error) => {
        console.log("nigga");
        console.log("error", error);
      });
  };

  function GetCardType2(number) {
    // visa
    var re = new RegExp("^4");
    if (number.match(re) != null) return "images/visa-card.png";

    // Mastercard
    // Updated for Mastercard 2017 BINs expansion
    if (
      /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
        number
      )
    )
      return "images/master-card.png";

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) != null) return "images/amex-card.png";

    // Discover
    re = new RegExp(
      "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
    );
    if (number.match(re) != null) return "images/discover-card.png";

    // Diners
    re = new RegExp("^36");
    if (number.match(re) != null) return "images/diners-club-card.png";

    // Diners - Carte Blanche
    re = new RegExp("^30[0-5]");
    if (number.match(re) != null) return "Diners - Carte Blanche";

    // JCB
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.match(re) != null) return "images/jcb-card.png";

    // Visa Electron
    re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
    if (number.match(re) != null) return "Visa Electron";

    return "";
  }

  function GetCardType(number) {
    // visa
    var re = new RegExp("^4");
    if (number.match(re) != null) return "Visa";

    // Mastercard
    // Updated for Mastercard 2017 BINs expansion
    if (
      /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
        number
      )
    )
      return "Mastercard";

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) != null) return "AMEX";

    // Discover
    re = new RegExp(
      "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
    );
    if (number.match(re) != null) return "Discover";

    // Diners
    re = new RegExp("^36");
    if (number.match(re) != null) return "Diners";

    // Diners - Carte Blanche
    re = new RegExp("^30[0-5]");
    if (number.match(re) != null) return "Diners - Carte Blanche";

    // JCB
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.match(re) != null) return "JCB";

    // Visa Electron
    re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
    if (number.match(re) != null) return "Visa Electron";

    return "";
  }
  useEffect(() => {
    listCard();
  }, []);

  const setDefault = (id) => {
    const token = localStorage.getItem("PO_TOKEN");

    axios({
      method: "get",
      url: `${REACT_APP_HOST_NAME}/apps/set-card-defualt?card_id=${id}`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        listCard();
        console.log(response);
      })
      .catch((error) => {
        console.log("nigga");
        console.log("error", error);
      });
  }
  return (
    <>
      <Header />
      {/* <!-- Site Header End --> */}

      {/* <!-- Profile sharing Section Start --> */}
      <section class="po-sharing-section">
        <div class="po-sharing-content">
          <NavBar />
          <div class="privacy-sharing-body" data-scrollbar>
            <div class="po-sharing-sidebar-icon d-block d-lg-none">
              <i class="fa-solid fa-bars"></i>
            </div>
            <div class="privacy-sharing payment-new-content">
              <div class="privacy-sharing-tabs">
                <h2 class="privacy-sharing-title notification-title">
                  Payments
                </h2>
                <div class="payment-method-new">
                  <h4 class="payment-new-title r-book-celebration-title">
                    Payment methods
                  </h4>
                  <p class="payment-new-discription refer-work-discription">
                    Add a payment method using our secure payment <br /> system,
                    then start planning your next trip.
                  </p>
                  {getDefault && getDefault.map((elem) =>
                    <div class="payment-new-card" style={{ cursor: 'pointer' }} onClick={() => setDefault(elem.id)}>
                      <a href="#">
                        <img
                          src={GetCardType2(
                            elem.card_number.replaceAll(/\s/g, "")
                          )}
                          alt=""
                          class="img-fluid"
                        />
                      </a>
                      {/* <p class="profile-work-link">Mastercard 4567</p> */}
                      <p class="profile-work-link">
                        {elem?.card_number ? (
                          <>
                            {GetCardType(
                              elem.card_number.replaceAll(/\s/g, "")
                            ) +
                              " " +
                              elem.card_number?.slice(-4)}
                          </>
                        ) : null}
                      </p>

                      {elem.defualt_card === true ? <span>default</span> : ''}
                    </div>
                  )}
                  <a
                    href="#"
                    class="btn btn-warning"
                    data-bs-toggle="modal"
                    data-bs-target="#carddetails"
                  >
                    Add payment method
                  </a>
                </div>
                <div class="payment-method-new">
                  <h4 class="payment-new-title r-book-celebration-title">
                    Your payments
                  </h4>
                  <p class="payment-new-discription refer-work-discription">
                    Keep track of all your payments and refunds.
                  </p>
                  <a
                    onClick={() => {
                      nav("/view_payment");
                    }}
                    class="btn btn-warning"
                  >
                    View payments
                  </a>
                </div>
                <div class="payment-method-new">
                  <h4 class="payment-new-title r-book-celebration-title">
                    Credit & coupons
                  </h4>
                  <p class="payment-new-discription refer-work-discription">
                    Add a coupon and save on your Moment.
                  </p>
                  <p class="payment-coupons profile-work-link">
                    Your coupons <span>0</span>
                  </p>
                  <a href="#" class="btn btn-warning add-coupon">
                    Add coupon
                  </a>
                  <div class="payment-coupon-input">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter coupon code"
                    />
                    <a href="#" class="btn btn-warning">
                      Redeem coupon
                    </a>
                    <a
                      href="#"
                      class="btn privacy-deactivate cancel-coupon m-0"
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
              <div class="privacy-sharing-Committed payment-new-term">
                <img src="/images/money_bag.png" alt="" class="img-fluid" />
                <h6 class="privacy-committed-title privacy-request-title">
                  Make all payments through PlusOne
                </h6>
                <p class="privacy-committed-discription profile-work-link m-0 d-inline">
                  Always pay and communicate through PlusOne to ensure you're
                  protected under our{" "}
                  <a href="#"> Terms of Service, Payments Terms of Service,</a>{" "}
                  cancellation, and other safeguards. <a href="#">Learn more</a>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Profile sharing Section End --> */}

      {/* <!-- Card Details Modal Start --> */}
      <div
        class="modal fade card-details-modal"
        id="carddetails"
        tabindex="-1"
        aria-labelledby="carddetails"
        aria-hidden="true"
      >
        <div class="modal-dialog card-details-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="carddetails">
                Add card details
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2002_7896)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.05541 4.21168C4.35701 3.91008 4.846 3.91008 5.1476 4.21168L9.99999 9.06406L14.8524 4.21168C15.154 3.91008 15.643 3.91008 15.9446 4.21168C16.2462 4.51328 16.2462 5.00227 15.9446 5.30386L11.0922 10.1562L15.9446 15.0086C16.2462 15.3102 16.2462 15.7992 15.9446 16.1008C15.643 16.4024 15.154 16.4024 14.8524 16.1008L9.99999 11.2484L5.1476 16.1008C4.846 16.4024 4.35701 16.4024 4.05541 16.1008C3.75381 15.7992 3.75381 15.3102 4.05541 15.0086L8.9078 10.1562L4.05541 5.30386C3.75381 5.00227 3.75381 4.51328 4.05541 4.21168Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2002_7896">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(0 0.15625)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
            <div class="modal-body">
              <div class="card-details-logos">
                <img src="/images/mastercard.png" alt="" class="img-fluid" />
                <img src="/images/visa-logo.png" alt="" class="img-fluid" />
                <img src="/images/amex_logo.png" alt="" class="img-fluid" />
                <img src="/images/discover.png" alt="" class="img-fluid" />
              </div>
              <div class="payment-card-input payment-card-no">
                <input
                  {...getCardNumberProps({
                    onChange: (e) => {
                      setCardNo(e.target.value);
                    },
                  })}
                  class="form-control border-top-0 border-end-0 border-start-0 card-lock"
                  placeholder="Card number"
                  value={cardNo}
                />

                <div class="payment-card-input payment-card-Expiration mb-0">
                  <input
                    {...getExpiryDateProps({
                      onChange: (e) => setExpiration(e.target.value),
                    })}
                    class="form-control border-0"
                    placeholder="Expiration (mm/yy)"
                    value={expiration}
                  />

                  <input
                    {...getCVCProps({
                      onChange: (e) => setCVC(e.target.value),
                    })}
                    class="form-control border-top-0 border-end-0 border-bottom-0"
                    placeholder="CVV"
                    value={cvc}
                  />
                </div>
              </div>
              <div className="text-danger">{error.card_number}</div>
              <div className="text-danger">{error.card_exp}</div>

              <div className="text-danger">{error.card_cvc}</div>

              <div class="payment-card-input">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Post Code"
                  value={postCode}
                  onChange={(e) => setPostCode(e.target.value)}
                />
                <div className="text-danger">{error.postcode}</div>
              </div>
              <div class="payment-card-input payment-card-select-country">
                <span>Country/Region</span>
                <select
                  class="form-select form-control"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <option selected value="US">
                    United States of America
                  </option>
                  <option value="UK">United Kingdom</option>
                  <option value="IN">India</option>
                </select>
                <div className="text-danger">{error.country}</div>
              </div>
            </div>
            <div class="modal-footer card-details-footer">
              <a
                href=""
                class="btn"
                data-bs-dismiss="modal"
                onClick={() => {
                  setCVC(undefined);
                  setCardNo(undefined);
                  setExpiration(undefined);
                  setCountry("United States of America");
                  setPostCode(undefined);
                }}
              >
                Cancel
              </a>
              <a
                href=""
                class="btn btn-warning"
                onClick={addCard}
                data-bs-dismiss={
                  cardNo && expiration && cvc && postCode && country && valid()
                    ? "modal"
                    : ""
                }
              >
                {disable ? "Adding..." : "Done"}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPaymentMethod;
