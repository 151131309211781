import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import Select, { StylesConfig } from "react-select";
import { REACT_APP_HOST_NAME } from "../baseurl";
import axios from "axios";
import { useEffect } from "react";
import NavBar from "./NavBar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <section className="po-sharing-section">
        <div className="po-sharing-content">
          <NavBar />
          <div className="policy-details-content" data-scrollbar>
            <div className="po-sharing-sidebar-icon d-block d-lg-none">
              <i className="fa-solid fa-bars"></i>
            </div>
            <div className="row">
              <div className="col col-12 col-lg-12 col-xl-7">
                <div className="policy-rules">
                  {/* <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="#" className="policy-rules-subtitle">Legal Terms ></a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page"> Privacy Policy</li>
                                </ol>
                            </nav>  */}
                  <h2 className="policy-rules-main-title view-profile-details-title">
                    Plus One’s Privacy
                  </h2>
                  <div className="policy-img">
                    <img
                      src="/images/Privacy Policy Image 1.png"
                      alt="lo"
                      className="img-fluid"
                    />
                  </div>
                  <h5 className="policy-rules-title">Privacy Policy</h5>
                  <p className="policy-rukes-discription">
                    Our Privacy Policy explains what personal information we
                    collect, how we use personal information, how personal
                    information is shared, and privacy rights.
                  </p>
                  <div className="policy-rules-country">
                    <h5 className="policy-rules-title">
                      North America (excluding Mexico)
                    </h5>
                    <ul>
                      <li>
                        <a href="#" className="policy-rukes-discription">
                          Privacy Policy for the United States
                        </a>
                      </li>
                      <li>
                        <a href="#" className="policy-rukes-discription">
                          Privacy Policy for outside of US (English)
                        </a>
                      </li>
                      <li>
                        <a href="#" className="policy-rukes-discription">
                          Privacy Policy for outside of US (French)
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="policy-rules-country">
                    <h5 className="policy-rules-title">
                      Latin America (including Mexico, Central and South America
                      and the Caribbean)
                    </h5>
                    <ul>
                      <li>
                        <a href="#" className="policy-rukes-discription">
                          Privacy Policy for Latin America (English)
                        </a>
                      </li>
                      <li>
                        <a href="#" className="policy-rukes-discription">
                          Privacy Policy for Latin America (Spanish)
                        </a>
                      </li>
                      <li>
                        <a href="#" className="policy-rukes-discription">
                          Privacy Policy for Latin America (Portuguese)
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="policy-rules-country">
                    <h5 className="policy-rules-title">
                      Europe, Middle East, and Africa
                    </h5>
                    <ul>
                      <li>
                        <a href="#" className="policy-rukes-discription">
                          Privacy Policy for the Europe, Middle East, Africa and
                          other countries
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="policy-rules-country">
                    <h5 className="policy-rules-title">Asia Pacific</h5>
                    <ul>
                      <li>
                        <a href="#" className="policy-rukes-discription">
                          Privacy Policy for Asia Pacific (excluding China)
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="policy-rules-country">
                    <h5 className="policy-rules-title">Asia Pacific</h5>
                    <ul>
                      <li>
                        <a href="#" className="policy-rukes-discription">
                          Privacy Policy for China
                        </a>
                      </li>
                    </ul>
                  </div>
                  <p className="policy-rukes-discription">
                    Please review the supplemental privacy policies linked
                    within the privacy policy documents, such as for certain
                    Airbnb services, that may be applicable to you.
                  </p>
                  <div className="policy-rules-country">
                    <h5 className="policy-rules-title">
                      Supplemental Privacy Policy Documents:
                    </h5>
                    <ul>
                      <li>
                        <a href="#" className="policy-rukes-discription">
                          Outside of United States
                        </a>
                      </li>
                      <li>
                        <a href="#" className="policy-rukes-discription">
                          California and Vermont
                        </a>
                      </li>
                      <li>
                        <a href="#" className="policy-rukes-discription">
                          Cookie Policy
                        </a>
                      </li>
                      <li>
                        <a href="#" className="policy-rukes-discription">
                          Enterprise Customers and Airbnb for Work
                        </a>
                      </li>
                      <li>
                        <a href="#" className="policy-rukes-discription">
                          Colombia Only
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col col-12 col-lg-12 col-xl-5">
                <div className="policy-contact m-0">
                  <h5 className="policy-rules-title">Need to get in touch?</h5>
                  <p className="policy-rukes-discription">
                    We’ll start with some questions and get you the right place.
                  </p>
                  <a href="#" className="btn policy-contact-btn">
                    Contact Us
                  </a>
                  <p className="policy-rukes-discription">
                    You can also give us feedback.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
