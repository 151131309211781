import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const SetShedule = () => {
  const apiKey = "AIzaSyC3MDh4g3G1POPU4PBb2LoCz0YpPyg1InM";
  const mapApiJs = "https://maps.googleapis.com/maps/api/js";
  const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";
  const containerStyle = {
    width: "400px",
    height: "400px",
  };
  const nav = useNavigate();
  const loc = useLocation();
  let topRatedId = loc.state.topRatId;
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [add, setAdd] = useState("");
  const location = {
    latitude,
    longitude,
  };
  const searchInput = useRef(null);

  function loadAsyncScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      Object.assign(script, {
        type: "text/javascript",
        async: true,
        src,
      });
      script.addEventListener("load", () => resolve(script));
      document.head.appendChild(script);
    });
  }

  const initMapScript = () => {
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };

  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    let arr = place.address_components.map((elem) => {
      return elem.long_name;
    });
    console.log(place);
    setAdd(arr.join(", "));
    setLatitude(place.geometry.location.lat());
    setLongitude(place.geometry.location.lng());
  };

  const initAutocomplete = () => {
    if (!searchInput.current) return;
    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current
    );
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  };

  useEffect(() => {
    initMapScript().then(() => initAutocomplete());
  }, []);

  const dd = new Date().getDate();
  const mm = new Date().getMonth() + 1;
  const yy = new Date().getFullYear();
  const [date, setDate] = useState();
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [error, setError] = useState({});
  const handleSubmit = () => {
    if (validate()) {
      const weekday = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const d = new Date(date);
      let day = weekday[d.getDay()];
      const time = changeTime(startTime) + " - " + changeTime(endTime);
      const duration = parseInt(endTime) - parseInt(startTime);
      nav("/selecting", {
        state: { location, add, date, day, time, duration, topRatedId },
      });
    }
  };

  const changeTime = (time) => {
    const [hourString, minute] = time.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
  };

  const changeTime2 = (time) => {
    const amPm = time.split(" ")[1];
    const hour = time.split(" ")[0].split(":")[0];
    const min = time.split(" ")[0].split(":")[1];
    if (amPm == "PM") {
      return parseInt(hour) + 12 + ":" + min;
    } else {
      return time.split(" ")[0];
    }
  };

  const validate = () => {
    let duration = parseInt(endTime) - parseInt(startTime);
    let err = {};
    let isValid = false;
    console.log(date);

    if (!date) {
      err["date_err"] = "Please Select Date";
    }
    if (!add) {
      err["address"] = "Please Enter Address";
    }
    if (!startTime) {
      err["sTime_err"] = "Please Select Start Time";
    }
    if (!endTime) {
      err["eTime_err"] = "Please Select End Time";
    }
    if (duration < 1) {
      err["seTime_err"] = "Please select valid time";
    } else if (startTime && endTime) {
      if (startTime < endTime) {
        isValid = true;
      } else {
        err["seTime_err"] = "End time not Greater than Start Time";
      }
    }
    setError(err);
    return isValid;
  };

  useEffect(() => {
    if (loc.state.location) {
      setLongitude(loc.state.location.longitude);
      setLatitude(loc.state.location.latitude);
      setAdd(loc.state.add);
      setDate(loc.state.date);
      setStartTime(changeTime2(loc.state.time.split(" - ")[0]));
      setEndTime(changeTime2(loc.state.time.split(" - ")[1]));
      topRatedId = loc.state.topRatedId;
    }
  }, []);

  return (
    <>
      {/* Time Set Section Start  */}
      <section className="timeset-section">
        <div className="timeset-content">
          <div className="r-book-heading">
            <div className="r-book-logo information-logo">
              <img src="/images/logo.png" alt="" className="img-fluid" onClick={() => {
                nav("/home");
              }} />
            </div>
            <div className="r-book-progress information-tab-progress">
              <div className="r-book-progress-title information-progress-title">
                <h6 className="mb-0">
                  <span>Your progress:</span> 30% Complete
                </h6>
              </div>
              <div className="progress w-100">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "30%" }}
                  aria-valuenow="30"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
          <div className="set-schedule-content">
            <div className="container">
              <div className="timeset-body-heading">
                <a
                  className="text-decoration-none
                        information-form-back set-schedule-back"
                  onClick={() => {
                    nav("/home");
                  }}
                >
                  <i
                    className="fa-solid
                            fa-arrow-left"
                  ></i>
                </a>
                <h5 className="errands-map-title errands-address-title set-schedule-title mb-0">
                  Select the location, date, and time where
                  <br /> you'd like to meet with your PlusOne
                </h5>
              </div>
              <div className="set-schedule-content-description">
                <div className="row">
                  <div className="col col-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="set-schedule-description">
                      <div className="set-schedule-input">
                        <label className="set-schedule-input-title">
                          <span>1</span>
                          Address
                          <img
                            src="assets/images/cancel-icon.png"
                            alt=""
                            className="img-fluid ms-auto"
                          />
                        </label>
                        <input
                          ref={searchInput}
                          type="text"
                          className="form-control"
                          placeholder="Search location"
                          id="address"
                          value={add}
                          onChange={(e) => setAdd(e.target.value)}
                        />
                        <span
                          style={{
                            color: "red",
                            fontWeight: "500",
                            fontSize: "12px",
                          }}
                        >
                          {error.address}
                        </span>
                      </div>

                      <div className="set-schedule-input">
                        <label className="set-schedule-input-title">
                          <span>2</span>
                          Set Date
                        </label>
                        {/* <DatePicker
                          selected={date}
                          onChange={(date) => setDate(date)}
                          dateFormat="dd MMM yyyy"
                          className="form-control"
                          placeholder="dd-mm-yyyy"
                        /> */}
                        <input
                          type="date"
                          className="form-control"
                          value={date}
                          onChange={(e) => {
                            setDate(e.target.value);
                          }}
                          min={moment().format('YYYY-MM-DD')}
                        />

                        <span
                          style={{
                            color: "red",
                            fontWeight: "500",
                            fontSize: "12px",
                          }}
                        >
                          {error.date_err}
                        </span>
                      </div>
                      <div className="set-schedule-input">
                        <label className="set-schedule-input-title">
                          <span>3</span>
                          Set Time
                        </label>
                        <div className="set-schedule-input">
                          <div className="row timeset-times">
                            <div className="col col-6 timeset-time-input">
                              <label className="form-label">Start Time</label>
                              <input
                                type="time"
                                className="form-control"
                                value={startTime}
                                onChange={(e) => setStartTime(e.target.value)}
                              />
                              <span
                                style={{
                                  color: "red",
                                  fontWeight: "500",
                                  fontSize: "12px",
                                }}
                              >
                                {error.sTime_err}
                              </span>
                            </div>
                            <div className="col col-6 timeset-time-input">
                              <label className="form-label">End Time</label>
                              <input
                                type="time"
                                className="form-control"
                                value={endTime}
                                onChange={(e) => setEndTime(e.target.value)}
                              />
                              <span
                                style={{
                                  color: "red",
                                  fontWeight: "500",
                                  fontSize: "12px",
                                }}
                              >
                                {error.eTime_err}
                              </span>
                            </div>
                          </div>
                          <span
                            style={{
                              color: "red",
                              fontWeight: "500",
                              fontSize: "12px",
                            }}
                          >
                            {error.seTime_err}
                          </span>
                        </div>
                      </div>
                      <div className="set-schedule-button">
                        <button
                          type="submit"
                          className="btn btn-warning"
                          onClick={handleSubmit}
                        >
                          Continue To PlusOne Selection
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col col-12 col-md-6 col-lg-6 col-xl-6">
                    {latitude && longitude ? (
                      <div
                        className="set-schedule-map"
                        style={{ height: "200px", width: "200px" }}
                      >
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          center={{
                            lat: location.latitude,
                            lng: location.longitude,
                          }}
                          zoom={18}
                        >
                          <Marker
                            lat={location.latitude}
                            lng={location.longitude}
                          />
                        </GoogleMap>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Time Set Section End  */}

      {/* Modal  */}
      <div
        className="modal fade timing-modal"
        id="timingModal"
        tabIndex="-1"
        aria-labelledby="timingModal"
        aria-hidden="true"
      >
        <div className="modal-dialog timing-modal-dialog">
          <div className="modal-content">
            <div className="modal-body timing-modal-body">
              <h4 className="timing-modal-title">
                You're going to <br /> Run Errands on:
              </h4>
              <div className="riming-modal-address">
                <p className="order-address-link">
                  <img
                    src="/images/Calendar.png"
                    alt=""
                    className="img-fluid"
                  />
                  Thursday - Aug 18, 2022
                </p>
                <p className="order-address-link">
                  <img
                    src="/images/Icon_Time.png"
                    alt=""
                    className="img-fluid"
                  />
                  9:30 am - 10:30 am <br /> Duration: 1 Hour
                </p>
                <p className="order-address-link">
                  <img
                    src="/images/pin_location.png"
                    alt=""
                    className="img-fluid"
                  />
                  IKEA Furniture, Orlando
                </p>
              </div>
              <a href="" className="btn btn-warning w-100">
                Book Your Moment
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetShedule;
