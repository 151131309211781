import React from "react";
import Header from "./Header";

const PwdSuceesModal = () => {
  return (
    <>
      {/* Password Success Modal Start  */}

      <div className="modal-dialog password-dialog">
        <div className="modal-content">
          <div className="modal-body password-modal-body text-center">
            <div className="password-modal-img">
              <img src="/images/password.png" alt="" className="img-fluid" />
            </div>
            <h2 className="password-modal-title">Password reset successful!</h2>
            <p className="password-modal-discription">
              You have successfully reset your password. Please use your new
              password when logging in.
            </p>
          </div>
        </div>
      </div>
      {/* Password Success Modal End  */}
    </>
  );
};

export default PwdSuceesModal;
