import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import Countdown from "react-countdown";
import { toast } from "react-toastify";
import { REACT_APP_HOST_NAME } from "../../baseurl";
import axios from "axios";
const ForgotModal = ({
  country_code,
  mobile,
  handleChange,
  handleOtpSubmit,
  otp,
  message,
  handleOk,
  timeNow,
  setTimeNow,
}) => {
  const [change, setChange] = useState(false);

  useEffect(() => {
    setChange(false);
  }, [
    country_code,
    mobile,
    handleChange,
    handleOtpSubmit,
    otp,
    handleOk,
    message,
    timeNow,
    setTimeNow,
  ]);
  const [reflect, setReflect] = useState(false);
  const handleResend = () => {
    axios
      .post(
        `${REACT_APP_HOST_NAME}/apps/forgot-password-with-phone`,
        {
          country_code: country_code,
          mobile: mobile,
        },
        {
          headers: {
            // "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )

      .then((response) => {
        setChange(false);

        setTimeNow(Date.now() + 59000);
        setReflect(!reflect);
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log("error", error.response.data.message);
      });
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      setChange(true);
    } else {
      // Render a countdown
      return <span>{seconds}</span>;
    }
  };
  return (
    <>
      {/* Forgot Modal Start  */}
      {/* <div
        className="modal fade forgot-modal"
        id="forgotModal"
        tabIndex="-1"
        aria-labelledby="forgotModal"
        aria-hidden="true"
      > */}
      <div className="modal-dialog forgot-modal-dialog">
        <div className="modal-content">
          <div className="modal-content login-modal-close">
            <a
              className="d-flex justify-content-end"
              onClick={handleOk}
              style={{
                padding: "0px 15px",
                fontSize: "25px",
                textDecoration: "none",
                color: "black",
              }}
            >
              x
            </a>
          </div>
          <div className="modal-body forgot-modal-body">
            <h5 className="forgot-modal-title">Enter 4-digit recovery code</h5>
            <p className="forgot-modal-discription">
              The recovery code was sent to your mobile number. Please enter
              code:
            </p>
            <div className="forgot-modal-input">
              <OtpInput
                // className="form-control"
                inputStyle={{ width: "none" }}
                isInputNum
                value={otp}
                onChange={handleChange}
                numInputs={4}
                separator={<span></span>}
              />
            </div>
            <span style={{ color: "red", fontSize: "15px" }}>{message}</span>
            <div className="login-otp-resend">
              {!change ? (
                <a>
                  Resend code in&nbsp;
                  <Countdown date={timeNow} renderer={renderer} /> seconds
                </a>
              ) : (
                <a>
                  <h6 style={{ color: "#008ef9" }} onClick={handleResend}>
                    Resent
                  </h6>
                </a>
              )}
            </div>
            <button
              type="submit"
              className="btn btn-warning forgot-pass-btn w-100"
              onClick={(e) => handleOtpSubmit(e)}
            >
              Verify
            </button>
            {/* <a
                href="javascript:void(0)"
                className="btn btn-warning forgot-pass-btn w-100"
                data-bs-dismiss="modal"
              >
                Verify
              </a> */}
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* Forgot Modal End  */}
    </>
  );
};

export default ForgotModal;
