import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import Select, { StylesConfig } from "react-select";
import { REACT_APP_HOST_NAME } from "../baseurl";
import axios from "axios";
import { useEffect } from "react";
import NavBar from "./NavBar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TermService = () => {
  return (
    <>
      <Header />
      <section className="po-sharing-section">
        <div className="po-sharing-content">
          <NavBar />
          <div className="policy-details-content" data-scrollbar>
            <div className="po-sharing-sidebar-icon d-block d-lg-none">
              <i className="fa-solid fa-bars"></i>
            </div>
            <div className="row">
              <div className="col col-12 col-lg-12 col-xl-7">
                <div className="policy-rules terms-services">
                  {/* <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="#" className="policy-rules-subtitle">Legal Terms ></a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Terms of Service</li>
                                </ol>
                            </nav>  */}
                  <h2 className="policy-rules-main-title view-profile-details-title">
                    Terms of Service
                  </h2>
                  <div className="terms-discription">
                    <div className="terms-globe-img">
                      <img
                        src="images/Icon_Globe.png"
                        alt=""
                        className="w-100"
                      />
                    </div>
                    <div className="terms-discription-point">
                      <p className="policy-rukes-discription terms-rules-discription">
                        If your country of residence or establishment is within
                        the European Economic Area (“EEA”), Switzerland or the
                        United Kingdom, the Terms of Service for European Users
                        apply to you.
                      </p>
                      <p className="policy-rukes-discription terms-rules-discription">
                        If your country of residence or establishment is outside
                        of the EEA, Switzerland, and the United Kingdom, the
                        Terms of Service for Non-European Users apply to you.
                      </p>
                    </div>
                  </div>
                  <div className="terms-service-user">
                    <h5 className="policy-rules-title terms-rules-title">
                      Terms of Service for Non-European Users
                    </h5>
                    <p className="policy-rukes-discription">
                      Section 23 of these Terms contains an arbitration
                      agreement and class action waiver that apply to all claims
                      brought against Airbnb in the United States. Please read
                      them carefully.
                    </p>
                    <p className="policy-rukes-discription">
                      Last Updated: February 10, 2022
                    </p>
                    <p className="policy-rukes-discription">
                      Thank you for using Airbnb!
                    </p>
                    <p className="policy-rukes-discription">
                      These Terms of Service (“Terms”) are a binding legal
                      agreement between you and Airbnb that govern your right to
                      use the websites, applications, and other offerings from
                      Airbnb (collectively, the “Airbnb Platform”). When used in
                      these Terms, “Airbnb,” “we,” “us,” or “our” refers to the
                      Airbnb entity set out on Schedule 1 with whom you are
                      contracting.
                    </p>
                    <p className="policy-rukes-discription">
                      The Airbnb Platform offers an online venue that enables
                      users (“Members”) to publish, offer, search for, and book
                      services. Members who publish and offer services are
                      “Hosts” and Members who search for, book, or use services
                      are “Guests.” Hosts offer accommodations
                      (“Accommodations”), moments, excursions, and events
                      (“Experiences”), and a variety of travel and other
                      services (collectively, “Host Services,” and each Host
                      Service offering, a “Listing”). You must register an
                      account to access and use many features of the Airbnb
                      Platform, and must keep your account information accurate.
                      As the provider of the Airbnb Platform, Airbnb does not
                      own, control, offer or manage any Listings or Host
                      Services. Airbnb is not a party to the contracts entered
                      into directly between Hosts and Guests, nor is Airbnb a
                      real estate broker, travel agency, or insurer. Airbnb is
                      not acting as an agent in any capacity for any Member,
                      except as specified in the Payments Terms of Service
                      (“Payment Terms”). To learn more about Airbnb’s role see
                      Section 16.
                    </p>
                    <p className="policy-rukes-discription terms-service-discription">
                      We maintain other terms and policies that supplement these
                      Terms like our Privacy Policy, which describes our
                      collection and use of personal data, and our Payments
                      Terms, which govern any payment services provided to
                      Members by the Airbnb payment entities (collectively
                      "Airbnb Payments").
                      {/* </b> */}
                    </p>
                    <p className="policy-rukes-discription">
                      If you are a Host, you are responsible for understanding
                      and complying with all laws, rules, regulations and
                      contracts with third parties that apply to your Host
                      Services.
                    </p>

                    <p className="tetms-interim">Interim</p>
                  </div>
                </div>
              </div>
              <div className="col col-12 col-lg-12 col-xl-5">
                <div className="policy-contact m-0">
                  <h5 className="policy-rules-title">Need to get in touch?</h5>
                  <p className="policy-rukes-discription">
                    We’ll start with some questions and get you the right place.
                  </p>
                  <a href="#" className="btn policy-contact-btn">
                    Contact Us
                  </a>
                  <p className="policy-rukes-discription">
                    You can also give us feedback.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermService;
