import React from "react";

const LoginFacebook = () => {
    return (
        <>
            {/* Login Facebook Section Start  */}
            <section className="login-facebook-section">
                <div className="login-facebook-header">
                    <h5>
                        <i className="fa-brands fa-facebook-f"></i> facebook
                    </h5>
                </div>
                <div className="login-facebook-content">
                    <div className="login-facebook-logo">
                        <img
                            src="/images/facebook_login.png"
                            alt=""
                            className="img-fluid"
                        />
                    </div>
                    <p className="login-facebook-discription">
                        You are previously logged in to Plus One with Facebook.
                    </p>
                    <p className="login-facebook-discription mb-0">
                        Would you like to continue?
                    </p>
                    <p className="login-facebook-discription login-facebook-post">
                        This doesn’t let the app post to Facebook.
                    </p>
                    <div className="login-facebook-btn">
                        <a href="javascript:void(0)" className="btn btn-primary">
                            Continue
                        </a>
                        <a href="javascript:void(0)" className="btn login-facebook-cancel">
                            Cancel
                        </a>
                    </div>
                    <p className="login-facebook-policy">
                        By, continuing, Plus One will receive ongoing acess to the
                        information you share and Facebook will record when Plus One
                        accesses it. <a href="javascript:void(0)">Learn more</a> about this sharing and the
                        settings you have.{" "}
                    </p>
                    <p className="login-facebook-policy">
                        Plus One’s <a href="javascript:void(0)">Privacy Policy</a> and <a href="javascript:void(0)">Terms</a>
                    </p>
                </div>
            </section>
            {/* Login Facebook Section End */}
        </>
    );
};

export default LoginFacebook;
