import React, { useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import TakeProfileImg from "./TakeProfileImg";
import ProfileComplete from "./ProfileComplete";
import PaymentMethods from "./PaymentMethods";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faTimes,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { REACT_APP_HOST_NAME } from "../../baseurl";

const element = <FontAwesomeIcon icon={faEye} />;
// import { axiosAPI } from '../helper'

const Signup = () => {
  const location = useLocation();
  const mob = location.state.data;
  const [step, setStep] = useState(1);
  const [processPipline, setProcessPipline] = useState(25);
  const [data, setData] = useState({
    email: "",
    pwd: "",
    fname: "",
    lname: "",
    bod: "",
    country_code: mob.country_code,
    mobile: mob.mobile,
    profile_pic: "",
  });
  const [pwd, setPwd] = useState();
  const [show, setShow] = useState(false);
  const [pwdCondition, setPwdCondition] = useState({
    con1: true,
    con2: false,
    con3: false,
  });
  const [error, setError] = useState("");
  const valid = (item, v_icon, inv_icon) => {
    let text = document.querySelector(`#${item}`);
    text.style.opacity = 1;
    // text.style.border = '2px solid black' ;
    let valid_icon = document.querySelector(`#${item} .${v_icon}`);
    valid_icon.style.opacity = 1;
    let invalid_icon = document.querySelector(`#${item} .${inv_icon}`);
    invalid_icon.style.opacity = 0;
  };

  const invalid = (item, v_icon, inv_icon) => {
    let text = document.querySelector(`#${item}`);
    text.style.opacity = 0.5;
    let valid_icon = document.querySelector(`#${item} .${v_icon}`);
    valid_icon.style.opacity = 0;
    let invalid_icon = document.querySelector(`#${item} .${inv_icon}`);
    invalid_icon.style.opacity = 1;
  };

  // const userInfoStep = [
  // {
  //   pageName: 'Personal Information',
  //   form: <Step1 />,
  //   _back: stepsManager(1),
  //   _next: stepsManager(2),
  // },
  // {
  //   pageName: 'Personal Information',
  //   form: <Step2 />,
  //   _back: stepsManager(1),
  //   _next: stepsManager(2),
  // }
  // ]
  const validator = useRef(
    new SimpleReactValidator({
      validators: {
        email: {
          message: "Please enter valid email address",
          // eslint-disable-next-line
          rule: (val, params, validator) =>
            validator.helpers.testRegex(
              val.trim(),
              /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i
            ),
          required: true, // optional
        },
        // password: {
        //   message:
        //     "Password must be at least 8 characters and include one lowercase letter, one uppercase letter, and one digit.",
        //   // eslint-disable-next-line
        //   rule: (val, params, validator) =>
        //     validator.helpers.testRegex(
        //       val.trim(),
        //       /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/
        //     ),
        //   required: true, // optional
        // },
      },
    })
  );
  const [, updateState] = useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const handleChange = (e, url) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const validEmail = () => {
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("email", data.email);
    axios({
      method: "post",
      url: `${REACT_APP_HOST_NAME}/apps/check-email`,
      data: bodyFormData,
    })
      .then((response) => {
        setProcessPipline(66);
        setStep(2);
        localStorage.setItem("userInfo", JSON.stringify(data));
      })
      .catch((error) => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setError(error.response.data.message);
      });
  };

  const handleImg = (url) => {
    setData({ ...data, profile_pic: url });
  };
  const handleChangePassword = (e) => {
    const txt = e.target.value;
    setPwd(e.target.value);
    if (txt.length > 0) {
      // console.log(txt.length>=8);
      if (txt.length >= 8) {
        console.log(pwdCondition.con1);
        setPwdCondition({ ...pwdCondition, con1: true });
      }
      if (txt.match(/^[a-zA-Z]*$/) != null) {
        setPwdCondition({ ...pwdCondition, con2: true });
      }
      if (txt.match(/[0-9]/) != null) {
        setPwdCondition({ ...pwdCondition, con3: true });
      }
    } else {
      setPwdCondition({
        ...pwdCondition,
        con1: false,
        con2: false,
        con3: false,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      validEmail();
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };
  const stepsManager = (num = 1) => {
    setStep(num);
  };

  const handleShowhide = () => {
    setShow(!show);
  };

  return (
    <>
      {/* Personal information Section Start */}
      <section className="information-section">
        <div className="information-content">
          <div className="information-nav">
            <div className="information-logo">
              <img src="/images/logo.png" alt="" className="img-fluid" />
            </div>
            <ul
              className="nav nav-tabs information-nav-tabs border-0"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item information-nav-item" role="presentation">
                <button
                  className="nav-link active information-nav-link"
                  id="personal-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#personal"
                  type="button"
                  role="tab"
                  aria-controls="personal"
                  aria-selected="true"
                >
                  <i className="fa-solid fa-check"></i>
                </button>
              </li>
              <li className="nav-item information-nav-item" role="presentation">
                <button
                  className={`nav-link information-nav-link ${step >= 2 && "active"
                    }`}
                  id="upload-tab"
                >
                  <i className="fa-solid fa-check"></i>
                  Upload Photo
                </button>
              </li>
              {/* <li className="nav-item information-nav-item" role="presentation">
                <button
                  className={`nav-link information-nav-link ${step >= 3 && "active"
                    }`}
                  id="method-tab"
                >
                  <i className="fa-solid fa-check"></i>
                  Payment Methods
                </button>
              </li> */}
              <li className="nav-item information-nav-item" role="presentation">
                <button
                  className={`nav-link information-nav-link ${step === 4 && "active"
                    }`}
                  id="profile-tab"
                >
                  <i className="fa-solid fa-check"></i>
                  Profile Complete
                </button>
              </li>
            </ul>
          </div>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="personal"
              role="tabpanel"
              aria-labelledby="personal-tab"
            >
              <div className="information-tab-body">
                <div className="container">
                  <div className="information-tab-progress">
                    <div className="information-progress-title">
                      <h6 className="mb-0">
                        <span>Your progress:</span> {processPipline}% Complete
                      </h6>
                    </div>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${processPipline}%` }}
                        aria-valuenow="00"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
                {step === 1 && (
                  <div className="information-form-content">
                    <div className="container">
                      <div className="information-form-box">
                        {/* <a
                          href="javascript:void(0)"
                          className="text-decoration-none information-form-back"
                        >
                          <i className="fa-solid fa-arrow-left"></i>
                        </a> */}
                        <div className="information-form">
                          <h2 className="information-form-title section-title">
                            Personal Information
                          </h2>
                          <div className="row">
                            <div className="col col-12">
                              <div className="information-form-input">
                                <label className="form-label">
                                  Email Address
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="sample@domain.com"
                                  value={data.email}
                                  name="email"
                                  onChange={handleChange}
                                />
                                <span style={{ fontSize: 13, color: "red" }}>
                                  {validator.current.message(
                                    `email`,
                                    data.email,
                                    "required|email"
                                  )}
                                </span>
                                <span style={{ fontSize: 13, color: "red" }}>
                                  {error}
                                </span>
                              </div>
                            </div>
                            <div className="col col-12">
                              <div className="information-form-input">
                                <label className="form-label">Password</label>
                                <input
                                  type={show ? "text" : "password"}
                                  className="form-control"
                                  placeholder="********"
                                  value={data.pwd}
                                  // value={pwd}
                                  name="pwd"
                                  onChange={handleChange}
                                // onChange={handleChangePassword}
                                />
                                {show ? (
                                  <FontAwesomeIcon
                                    icon={faEye}
                                    id="show_hide"
                                    onClick={handleShowhide}
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faEyeSlash}
                                    id="show_hide"
                                    onClick={handleShowhide}
                                  />
                                )}
                                <p
                                  style={{
                                    fontSize: 13,
                                    color: "red",
                                    marginTop: "-20px",
                                  }}
                                >
                                  {validator.current.message(
                                    `password`,
                                    data.pwd,
                                    "required"
                                  )}
                                </p>
                                <div style={{ marginTop: "10px" }}></div>
                              </div>
                            </div>
                            <div className="col col-12">
                              <div className="information-form-input">
                                <label className="form-label">
                                  What’s your name?
                                </label>
                                <div className="row">
                                  <div className="col-md-6">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="First Name"
                                      value={data.fname}
                                      name="fname"
                                      onChange={handleChange}
                                    />
                                    <span
                                      style={{ fontSize: 13, color: "red" }}
                                    >
                                      {validator.current.message(
                                        `first name`,
                                        data.fname,
                                        "required"
                                      )}
                                    </span>
                                  </div>
                                  <div className="col-md-6">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Last Name"
                                      value={data.lname}
                                      name="lname"
                                      onChange={handleChange}
                                    />
                                    <span
                                      style={{ fontSize: 13, color: "red" }}
                                    >
                                      {validator.current.message(
                                        `last name`,
                                        data.lname,
                                        "required"
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col col-12">
                              <div className="information-form-input">
                                <label className="form-label mb-0">
                                  When’s your birthday?
                                </label>
                                <span>
                                  Your birthday won’t be shown publicly
                                </span>
                                <input
                                  type="date"
                                  className="form-control"
                                  value={data.bod}
                                  name="bod"
                                  onChange={handleChange}
                                />
                                <span style={{ fontSize: 13, color: "red" }}>
                                  {validator.current.message(
                                    `Date Of Birth`,
                                    data.bod,
                                    "required"
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="col col-12">
                              <button
                                className="btn btn-warning information-btn w-100"
                                onClick={(e) => handleSubmit(e)}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                          <p className="information-policy">
                            By clicking “Next”, you agree to our{" "}
                            <a href="javascript:void(0)">Terms of Service</a>{" "}
                            and have read and aknowledge our{" "}
                            <a href="javascript:void(0)">Privacy Policy.</a>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {step === 2 && (
                  <TakeProfileImg
                    setBack={stepsManager}
                    data={data}
                    setData={setData}
                    setStep={setStep}
                    handleImg={handleImg}
                    processPipline={processPipline}
                    setProcessPipline={setProcessPipline}
                  />
                )}
                {step === 3 && (
                  <PaymentMethods
                    setBack={stepsManager}
                    setStep={setStep}
                    processPipline={processPipline}
                    setProcessPipline={setProcessPipline}
                  />
                )}
                {step === 4 && (
                  <ProfileComplete setBack={stepsManager} data={data} />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Personal information Section End */}
    </>
  );
};

// const Step2 = () => {
//   return <div>Step 2</div>;
// };
export default Signup;
