import React, { useRef, useState } from "react";
import Header from "../../components/Header";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import CustomModal from "../../components/CustomModal";
// import ForgotModal from "../../components/OtpModal";
import ForgotModal from "./ForgotModal";
import { useLocation, useNavigate } from "react-router-dom";
import { REACT_APP_HOST_NAME } from "../../baseurl";

const ForgetPasswordEmail = ({ handleNavigate }) => {
  const [userId, setUserId] = useState("");
  const nav = useNavigate();
  const [email, setEmail] = useState();
  const [otp, setOtp] = useState();
  const [toggleOtp, setToggleOtp] = useState(false);
  const [err, setErr] = useState({ message: "", success: false });
  const [otpmessage, setOtpMessage] = useState();

  const validate = () => {
    let isValid = true;
    if (!email) {
      isValid = false;
      setErr({ message: "Please Enter Your Email!" });
    }
    return isValid;
  };

  const handleChange = (otp) => {
    setOtp(otp.toString());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setErr({ message: "" });
      console.log("in the API");
      const payload = { email: email };
      axios
        .post(
          `${REACT_APP_HOST_NAME}/apps/forgot-password-with-email`,
          payload,
          {
            headers: {
              // "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          if (response.data.success) {
            setToggleOtp(true);
            setUserId(response.data.data.user_id);
          }
        })
        .catch((error) => {
          setErr({ message: error.response.data.message });
        });
      // handleNavigate("/forget_password_new");
    }
  };

  const handleOtpSubmit = (e) => {
    e.preventDefault();
    if (validOTP()) {
      setMessage('');
      let payload = { user_email: email, otp: otp };

      axios
        .post(`${REACT_APP_HOST_NAME}/apps/verify-otp-mail`, payload, {
          headers: {
            // "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          setToggleOtp(false);
          nav("/forget_password_new", {
            state: { userId },
          });
          console.log(userId);
          localStorage.setItem("PO_TOKEN", response.data.data.token);
        })
        .catch((error) => {
          setOtpMessage(error.response.data.message);
          setMessage(error.response.data.message);
        });
    }
  };

  const [message, setMessage] = useState('');
  const validOTP = () => {
    let isvalid = true;
    if (!otp) {
      setMessage('Please Enter OTP');
      isvalid = false;
    }
    return isvalid;
  }

  return (
    <>
      {/* Forgot Psaaword Section Start   */}
      <section className="forgot-pass-section">
        <div className="container">
          <div className="forgot-pass-content">
            <div className="forgot-pass-heading">
              <a onClick={() => { nav('/login') }}>
                <img
                  src="/images/left_arrow.png"
                  alt=""
                  className="img-fluid"
                />
              </a>
              <h6>Forgot Password?</h6>
            </div>
            <div className="forgot-pass-body">
              <p className="forgot-pass-discription">
                Enter the email address associated with your account, and we’ll
                email you a link to reset your password.
              </p>
              <div className="forgot-pass-email">
                <input
                  type="email"
                  className="form-control"
                  placeholder="sample@domain.com"
                  value={email}
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <span style={{ fontSize: 13, color: "red" }}>
                  {err.message}
                </span>
              </div>
              <button
                type="submit"
                className="btn btn-warning forgot-pass-btn w-100"
                onClick={(e) => handleSubmit(e)}
              >
                Send reset link
              </button>
              {/* <a href="javascript:void(0)" className="btn btn-warning forgot-pass-btn w-100">
                        Send reset link
                    </a> */}
            </div>
          </div>
        </div>
        <CustomModal
          isModalOpen={toggleOtp}
          handleOk={() => setToggleOtp(false)}
          handleCancel={() => setToggleOtp(false)}
        >
          <ForgotModal
            handleChange={handleChange}
            handleOtpSubmit={(e) => handleOtpSubmit(e)}
            otp={otp}
            message={message}
            handleOk={() => setToggleOtp(false)}
          />
        </CustomModal>
      </section>

      {/* Forgot Psaaword Section Start  */}
    </>
  );
};

export default ForgetPasswordEmail;
