import axios from "axios";
import React from "react";
import { useState } from "react";
import PhoneInput, {
  formatPhoneNumberIntl,
  formatPhoneNumber,
} from "react-phone-number-input";
import { REACT_APP_HOST_NAME } from "../baseurl";

const ContractorLogin = () => {
  const [mobile, setMobile] = useState();
  const [res, setRes] = useState();
  const [sent, setSent] = useState(false);
  const [err, setErr] = useState();

  const validate = () => {
    let isValid = true;
    if (!mobile) {
      setErr("Please Enter Your Mobile Number");
      setRes("");
      isValid = false;
    }
    return isValid;
  };
  
  const handleSubmit = () => {
    if (validate()) {
      setErr("");
      setSent(true);

      const data = {
        mobile: parseInt(formatPhoneNumber(mobile).replace(/ /g, "")),
        country_code: parseInt(formatPhoneNumberIntl(mobile).split(" ")[0]),
      };
        var body = new URLSearchParams();
        body.append("mobile",parseInt(formatPhoneNumber(mobile).replace(/ /g, "")));
        body.append("country_code",parseInt(formatPhoneNumberIntl(mobile).split(" ")[0])); 
        axios({
          method: "post",
          url: `${REACT_APP_HOST_NAME}/apps/web/Account-App-Link`,
          data: body,
        })
        .then((response) => {
          setSent(false);
          setRes(response.data.message);
        });
    }
  };

  return (
    <div>
      <div className="co-login-section">
        <div className="co-login-content">
          <div className="row g-0 justify-content-end">
            <div className="col col-12 col-md-12 col-lg-6 col-xl-5">
              <div className="co-login-form" data-scrollbar>
                <div className="co-login-logo">
                  <img
                    src="images/footer_logo.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="co-login-form-content">
                  <h2 className="co-login-title">
                    Make a living being <br className="d-none d-md-block" />{" "}
                    there for others
                  </h2>
                  <p className="co-login-discription">
                    Sign up today. No skills necessary. You only have to be a
                    good listener, empathetic, and have a good attitude!
                  </p>
                  <div className="co-login-input">
                    <label htmlFor="form-label">Enter your mobile number</label>
                    <div className="co-login-input-box">
                      <div className="co-phone-input me-4">
                        <div
                          className="login-country-input"
                          style={{ width: "100%" }}
                        >
                          <PhoneInput
                            placeholder="(650) 251-53-21"
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="IN"
                            value={mobile}
                            onChange={setMobile}
                          />
                        </div>
                      </div>

                      <div className="co-login-btn">
                        <a
                          className="btn btn-warning w-100"
                          onClick={handleSubmit}
                        >
                          Get the App
                        </a>
                      </div>
                    </div>
                    <div
                      style={{
                        color: "red",
                        fontSize: "15px",
                        margin: "15px 0",
                      }}
                    >
                      {err}
                    </div>
                    {sent ? (
                      <div
                        style={{
                          color: "black",
                          fontSize: "15px",
                          margin: "15px 0",
                        }}
                      >
                        Sending Link...
                      </div>
                    ) : (
                      <div
                        style={{
                          color: "black",
                          fontSize: "15px",
                          margin: "15px 0",
                        }}
                      >
                        {res}
                      </div>
                    )}

                    <div className="co-login-app-store">
                      <img
                        src="images/app_store.png"
                        alt=""
                        className="img-fluid ms-0"
                      />
                      <img
                        src="images/play_store.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col col-12 col-md-12 col-lg-6 col-xl-6">
              <div className="co-login-img">
                <img
                  src="images/co_login.png"
                  alt=""
                  className="img-fluid m-0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractorLogin;
