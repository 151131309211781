import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const TermsAndService = () => {
  return (
    <>
      <Header />
      {/* Privacy Policy Section Start  */}
      <section className="policy-section">
        <div className="container">
          <div className="policy-content">
            <div className="row">
              <div className="col col-12 col-lg-7">
                <div className="policy-rules terms-services">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="javascript:void(0)" className="policy-rules-subtitle">
                          Legal Terms &#62;
                        </a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Terms of Service
                      </li>
                    </ol>
                  </nav>
                  <h2 className="policy-rules-main-title view-profile-details-title">
                    Terms of Service
                  </h2>
                  <div className="terms-discription">
                    <div className="terms-globe-img">
                      <img
                        src="/images/Icon_Globe.png"
                        alt=""
                        className="img-fluid w-100"
                      />
                    </div>
                    <div className="terms-discription-point">
                      <p className="policy-rukes-discription terms-rules-discription">
                        If your country of residence or establishment is within
                        the European Economic Area <b>(“EEA”)</b>,Switzerland or
                        the United Kingdom, the{" "}
                        <a href="javascript:void(0)">Terms of Service for European Users</a>{" "}
                        apply to you.
                      </p>
                      <p className="policy-rukes-discription terms-rules-discription">
                        If your country of residence or establishment is outside
                        of the EEA, Switzerland, and the United Kingdom, the{" "}
                        <a href="javascript:void(0)">Terms of Service for Non-European Users</a>{" "}
                        apply to you.
                      </p>
                    </div>
                  </div>
                  <div className="terms-service-user">
                    <h5 className="policy-rules-title terms-rules-title">
                      Terms of Service for Non-European Users
                    </h5>
                    <p className="policy-rukes-discription fw-bolder">
                      Section 23 of these Terms contains an arbitration
                      agreement and class action waiver that apply to all claims
                      brought against Airbnb in the United States. Please read
                      them carefully.
                    </p>
                    <p className="policy-rukes-discription">
                      Last Updated: February 10, 2022
                    </p>
                    <p className="policy-rukes-discription">
                      Thank you for using Airbnb!
                    </p>
                    <p className="policy-rukes-discription">
                      These Terms of Service <b> (“Terms”)</b> are a binding
                      legal agreement between you and Airbnb that govern your
                      right to use the websites, applications, and other
                      offerings from Airbnb (collectively, the{" "}
                      <b> “Airbnb Platform”</b>). When used in these Terms,{" "}
                      <b>“Airbnb,” “we,” “us,”</b> or <b>“our”</b> refers to the
                      Airbnb entity set out on Schedule 1 with whom you are
                      contracting.
                    </p>
                    <p className="policy-rukes-discription">
                      The Airbnb Platform offers an online venue that enables
                      users <b>(“Members”)</b> to publish, offer, search for,
                      and book services. Members who publish and offer services
                      are <b> “Hosts”</b> and Members who search for, book, or
                      use services are “Guests.” Hosts offer accommodations{" "}
                      <b>(“Accommodations”)</b> , moments, excursions, and
                      events <b>(“Experiences”)</b> , and a variety of travel
                      and other services (collectively, <b>“Host Services,”</b>
                      and each Host Service offering, a <b> “Listing”).</b> You
                      must register an account to access and use many features
                      of the Airbnb Platform, and must keep your account
                      information accurate. As the provider of the Airbnb
                      Platform, Airbnb does not own, control, offer or manage
                      any Listings or Host Services. Airbnb is not a party to
                      the contracts entered into directly between Hosts and
                      Guests, nor is Airbnb a real estate broker, travel agency,
                      or insurer. Airbnb is not acting as an agent in any
                      capacity for any Member, except as specified in the{" "}
                      <b>Payments Terms of Service (“Payment Terms”).</b> To
                      learn more about Airbnb’s role see Section 16.
                    </p>
                    <p className="policy-rukes-discription terms-service-discription">
                      We maintain other <b>terms</b> and <b> policies</b> that
                      supplement these Terms like our <b> Privacy Policy</b>,
                      which describes our collection and use of personal data,
                      and our <b> Payments Terms</b>, which govern any payment
                      services provided to Members by the Airbnb payment
                      entities (collectively <b> "Airbnb Payments").</b>
                    </p>
                    <p className="policy-rukes-discription">
                      If you are a Host, you are responsible for understanding
                      and complying with all laws, rules, regulations and
                      contracts with third parties that apply to your Host
                      Services.
                    </p>

                    <p className="tetms-interim">Interim</p>
                  </div>
                </div>
              </div>
              <div className="col col-12 col-lg-5">
                <div className="policy-contact">
                  <h5 className="policy-rules-title">Need to get in touch?</h5>
                  <p className="policy-rukes-discription">
                    We’ll start with some questions and get you the right place.
                  </p>
                  <a href="javascript:void(0)" className="btn policy-contact-btn">
                    Contact Us
                  </a>
                  <p className="policy-rukes-discription">
                    {" "}
                    You can also{" "}
                    <a href="javascript:void(0)" className="policy-contact-feedback">
                      give us feedback.
                    </a>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Privacy Policy Section End  */}
      <Footer/>
    </>
  );
};

export default TermsAndService;
