import React from "react";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";
import "react-loading-skeleton/dist/skeleton.css";
import NavBar from "./NavBar";
import axios from "axios";
import { REACT_APP_HOST_NAME } from "../baseurl";
import parse from "html-react-parser";
import { useEffect } from "react";
import { useState } from "react";

const HowPlusOneWorks = () => {
  const [details, setDetails] = useState([]);
  const getDetails = () => {
    const token = localStorage.getItem("PO_TOKEN");
    axios({
      method: "get",
      url: `${REACT_APP_HOST_NAME}/apps/list-plus-one-work-step`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        console.log(response.data.data[0].title, "boom bam");
        setDetails(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <>
      <Header />
      {/* Profile sharing Section Start  */}
      <section className="po-sharing-section">
        <div className="po-sharing-content">
          <NavBar />
          <div className="privacy-sharing-body" data-scrollbar>
            <div className="po-sharing-sidebar-icon d-block d-lg-none">
              <i className="fa-solid fa-bars"></i>
            </div>
            <div className="howwork-video-content">
              <div className="row">
                <div className="col col-12 col-lg-12 col-xl-6">
                  <div className="howwork-video-item">
                    <h2 className="howwork-video-main-title notification-title">
                      You’re just 3 steps away from your next Moment
                    </h2>
                  </div>
                  <div>
                    {details.map((elem, i) => (
                      <div className="howwork-video-item">
                        <h5 className="howwork-video-title po-sharring-setting-title">
                          {i + 1}. {elem.title}
                        </h5>
                        <p className="howwork-video-discription privacy-activity-subtitle">
                          {parse(elem.description)}
                        </p>
                        <div className="howwork-video">
                          <video
                            src={elem.video}
                            className="w-100 second-video"
                            controls
                          ></video>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col col-12 col-lg-12 col-xl-6">
                  <div className="howwork-litora">
                    <div className="howwork-questions">
                      <h4 className="howwork-questions-title forgot-pass-new-title">
                        Class aptent taciti sociosqu, Ad litora torquent per{" "}
                      </h4>
                      <div className="howwork-questions-points">
                        <div className="howwork-questions-img">
                          <img
                            src="assets/images/health.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="howwork-question-details">
                          <p className="howwork-questions-subtitle">
                            Phasellus porttitor, justo eu ultrices vulputate,
                          </p>
                          <p className="howwork-questions-discription">
                            Donec rutrum dapibus massa, vel tempor nulla porta
                            id.
                          </p>
                        </div>
                      </div>
                      <div className="howwork-questions-points">
                        <div className="howwork-questions-img">
                          <img
                            src="assets/images/bag.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="howwork-question-details">
                          <p className="howwork-questions-subtitle">
                            Phasellus porttitor, justo eu ultrices vulputate,
                          </p>
                          <p className="howwork-questions-discription">
                            Donec rutrum dapibus massa, vel tempor nulla porta
                            id.
                          </p>
                        </div>
                      </div>
                      <div className="howwork-questions-points howwork-comment-question">
                        <div className="howwork-questions-img">
                          <img
                            src="assets/images/comment.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="howwork-question-details">
                          <p className="howwork-questions-subtitle">
                            Phasellus porttitor, justo eu ultrices vulputate,
                          </p>
                          <p className="howwork-questions-discription">
                            Donec rutrum dapibus massa, vel tempor nulla porta
                            id.
                          </p>
                        </div>
                      </div>
                      <h4 className="howwork-questions-title forgot-pass-new-title">
                        Questions?
                      </h4>
                      <div className="accordion" id="accordionquestions">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="questionhead1">
                            <button
                              className="accordion-button howwork-questions-subtitle"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#questioncol1"
                              aria-expanded="true"
                              aria-controls="questioncol1"
                            >
                              Phasellus porttitor, justo eu ultrices?
                            </button>
                          </h2>
                          <div
                            id="questioncol1"
                            className="accordion-collapse collapse show"
                            aria-labelledby="questionhead1"
                            data-bs-parent="#accordionquestions"
                          >
                            <div className="accordion-body">
                              <p className="howwork-questions-discription">
                                Aenean gravida turpis nisi, consequat dictum
                                risus dapibus a. Duis felis ante, varius in
                                neque eu, tempor suscipit sem.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="questionhead2">
                            <button
                              className="accordion-button collapsed howwork-questions-subtitle"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#questioncol2"
                              aria-expanded="false"
                              aria-controls="questioncol2"
                            >
                              Phasellus porttitor, justo eu ultrices?
                            </button>
                          </h2>
                          <div
                            id="questioncol2"
                            className="accordion-collapse collapse"
                            aria-labelledby="questionhead2"
                            data-bs-parent="#accordionquestions"
                          >
                            <div className="accordion-body">
                              <p className="howwork-questions-discription">
                                Aenean gravida turpis nisi, consequat dictum
                                risus dapibus a. Duis felis ante, varius in
                                neque eu, tempor suscipit sem.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="questionhead3">
                            <button
                              className="accordion-button collapsed howwork-questions-subtitle"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#questioncol3"
                              aria-expanded="false"
                              aria-controls="questioncol3"
                            >
                              Phasellus porttitor, justo eu ultrices?
                            </button>
                          </h2>
                          <div
                            id="questioncol3"
                            className="accordion-collapse collapse"
                            aria-labelledby="questionhead3"
                            data-bs-parent="#accordionquestions"
                          >
                            <div className="accordion-body">
                              <p className="howwork-questions-discription">
                                Aenean gravida turpis nisi, consequat dictum
                                risus dapibus a. Duis felis ante, varius in
                                neque eu, tempor suscipit sem.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="questionhead4">
                            <button
                              className="accordion-button collapsed howwork-questions-subtitle"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#questioncol4"
                              aria-expanded="false"
                              aria-controls="questioncol4"
                            >
                              Phasellus porttitor, justo eu ultrices?
                            </button>
                          </h2>
                          <div
                            id="questioncol4"
                            className="accordion-collapse collapse"
                            aria-labelledby="questionhead4"
                            data-bs-parent="#accordionquestions"
                          >
                            <div className="accordion-body">
                              <p className="howwork-questions-discription">
                                Aenean gravida turpis nisi, consequat dictum
                                risus dapibus a. Duis felis ante, varius in
                                neque eu, tempor suscipit sem.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="questionhead5">
                            <button
                              className="accordion-button collapsed howwork-questions-subtitle"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#questioncol5"
                              aria-expanded="false"
                              aria-controls="questioncol5"
                            >
                              Phasellus porttitor, justo eu ultrices?
                            </button>
                          </h2>
                          <div
                            id="questioncol5"
                            className="accordion-collapse collapse"
                            aria-labelledby="questionhead5"
                            data-bs-parent="#accordionquestions"
                          >
                            <div className="accordion-body">
                              <p className="howwork-questions-discription">
                                Aenean gravida turpis nisi, consequat dictum
                                risus dapibus a. Duis felis ante, varius in
                                neque eu, tempor suscipit sem.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item border-0">
                          <h2 className="accordion-header" id="questionhead6">
                            <button
                              className="accordion-button collapsed howwork-questions-subtitle"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#questioncol6"
                              aria-expanded="false"
                              aria-controls="questioncol6"
                            >
                              Phasellus porttitor, justo eu ultrices?
                            </button>
                          </h2>
                          <div
                            id="questioncol6"
                            className="accordion-collapse collapse"
                            aria-labelledby="questionhead6"
                            data-bs-parent="#accordionquestions"
                          >
                            <div className="accordion-body">
                              <p className="howwork-questions-discription">
                                Aenean gravida turpis nisi, consequat dictum
                                risus dapibus a. Duis felis ante, varius in
                                neque eu, tempor suscipit sem.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="howwork-turpis">
                      <h4 className="howwork-questions-title forgot-pass-new-title text-center">
                        Vestibulum et turpis??
                      </h4>
                      <p className="howwork-turpis-btn text-center mb-0">
                        <a href="#" className="btn privacy-deactivate">
                          Book Now
                        </a>
                      </p>
                      <div className="row">
                        <div className="col col-6 col-md-4">
                          <div className="howwork-turpis-img">
                            <img
                              src="/images/turpis_1.svg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="col col-6 col-md-4">
                          <div className="howwork-turpis-img">
                            <img
                              src="assets/images/turpis_3.svg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="col col-6 col-md-4">
                          <div className="howwork-turpis-img">
                            <img
                              src="assets/images/turpis_2.svg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="col col-6 col-md-4">
                          <div className="howwork-turpis-img">
                            <img
                              src="assets/images/turpis_4.svg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="col col-6 col-md-4">
                          <div className="howwork-turpis-img">
                            <img
                              src="assets/images/turpis_5.svg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="col col-6 col-md-4">
                          <div className="howwork-turpis-img">
                            <img
                              src="assets/images/turpis_6.svg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HowPlusOneWorks;
