import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import Select, { StylesConfig } from "react-select";
import { REACT_APP_HOST_NAME } from "../baseurl";
import axios from "axios";
import { useEffect } from "react";
import NavBar from "./NavBar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactSupport = () => {
  const [issue, setIssue] = useState({
    issueMessage: "",
    date: "",
    full_name: "",
    email: "",
  });

  const sendIssue = () => {
    const token = localStorage.getItem("PO_TOKEN");
    let data = {
      message: issue.issueMessage,
      date: issue.date,
      name: issue.full_name,
      email: issue.email,
    };
    axios({
      method: "post",
      url: `${REACT_APP_HOST_NAME}/apps/submit-issue`,
      data: data,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        // setTopRated(response.data.data);
        toast.success(response.data.message);
        console.log(response);
        setIssue({
          issueMessage: "",
          date: "",
          full_name: "",
          email: "",
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  return (
    <>
      <Header />
      <section className="po-sharing-section">
        <div className="po-sharing-content">
          <NavBar />
          <div className="privacy-sharing-body" data-scrollbar>
            <div className="po-sharing-sidebar-icon d-block d-lg-none">
              <i className="fa-solid fa-bars"></i>
            </div>
            <div className="privacy-sharing safety-center">
              <div className="privacy-sharing-tabs safety-center-content contact-support-content">
                <h2 className="privacy-sharing-title notification-title">
                  Moment Support
                </h2>
                <p className="section-discription">
                  Etiam pulvinar purus vitae justo pharetra consequat. Mauris id
                  mi ut arcu feugiat <br className="d-none d-md-block" />{" "}
                  maximus amet. <a href="#">Contact PlusOne Support.</a>{" "}
                </p>
                <div className="contact-support-img">
                  <img
                    src="/images/contact_support.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="safety-tips">
                  <div className="contact-emergencie">
                    <h4 className="safety-tips-title refer-work-item-title">
                      For emergencies
                    </h4>
                    <h4 className="contact-emergencie-title po-sharring-setting-title">
                      <span>
                        <img
                          src="/images/Icon - Emergency.png"
                          alt=""
                          className="img-fluid"
                        />
                      </span>{" "}
                      Nam eget mi in purus lobortis?
                    </h4>
                    <p className="section-discription">
                      Sed nec ante dictum sem condimentum ullamcorper quis
                      venenatis nisi. <a href="#">Call 911</a>{" "}
                    </p>
                    <small>
                      Morbi a eleifend magna. Nam metus lacus, porttitor eu
                      mauris a, blandit ultrices nibh. Mauris sit{" "}
                      <br className="d-none d-md-block" /> amet magna non ligula
                      vestibulum eleifend.{" "}
                      <a href="#">Local emergency services policy.</a>{" "}
                    </small>
                  </div>
                  <div className="contact-emergencie">
                    <h4 className="safety-tips-title refer-work-item-title">
                      For urgent Moment situations
                    </h4>
                    <h4 className="contact-emergencie-title po-sharring-setting-title">
                      <span>
                        <img
                          src="/images/Frame.png"
                          alt=""
                          className="img-fluid"
                        />
                      </span>
                      Nam eget mi in purus lobortis?
                    </h4>
                    <p className="section-discription">
                      Sed nec ante dictum sem condimentum ullamcorper quis
                      venenatis nisi. <br className="d-none d-md-block" />{" "}
                      <a href="#">Request a call</a>{" "}
                    </p>
                  </div>
                  <div className="contact-emergencie">
                    <h4 className="safety-tips-title refer-work-item-title mb-2">
                      For urgent Moment situations
                    </h4>
                    <p className="section-discription">
                      Send us a message using the form below and we’ll reply via
                      email.
                    </p>
                  </div>
                </div>
                <div className="contact-support-form">
                  <h5 className="contactmassage-title po-sharring-setting-title">
                    Write a message for your PlusOne
                  </h5>
                  <p className="view-profile-details-edit mb-0">
                    Sed nec ante dictum sem condimentum ullamcorper quis
                    venenatis nisi.
                  </p>
                  <div className="contact-support-massage-box">
                    <label className="form-label">
                      Tell us about your concern and how we’ll be able to help:
                    </label>
                    <textarea
                      className="form-control"
                      cols="30"
                      rows="4"
                      placeholder="Your message..."
                      value={issue.issueMessage}
                      onChange={(e) =>
                        setIssue({ ...issue, issueMessage: e.target.value })
                      }
                    ></textarea>
                  </div>
                  <div className="contact-support-massage-box mt-0">
                    <label className="form-label">
                      When did the issue take place?
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder=""
                      value={issue.date}
                      onChange={(e) =>
                        setIssue({ ...issue, date: e.target.value })
                      }
                    />
                  </div>
                  <div className="contact-support-massage-box contact-support-details my-0">
                    <h6 className="contact-support-details-title po-sharing-profile-view">
                      Your details
                    </h6>
                    <label className="form-label">
                      Add contact info here - we’ll send you an email to verify
                      your address. We can’t move forward with your concern
                      until you’ve verified your email.
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Full Name"
                      value={issue.full_name}
                      onChange={(e) =>
                        setIssue({ ...issue, full_name: e.target.value })
                      }
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email Address"
                      value={issue.email}
                      onChange={(e) =>
                        setIssue({ ...issue, email: e.target.value })
                      }
                    />
                    <a
                      href="#"
                      className="btn btn-warning contact-support-btn"
                      onClick={sendIssue}
                    >
                      Submit
                    </a>
                  </div>
                </div>
              </div>
              <div className="safety-contact">
                <h6 className="privacy-committed-title privacy-request-title">
                  Need to get in touch?
                </h6>
                <p className="privacy-committed-discription profile-work-link m-0 d-inline">
                  We’ll start with some questions and get you <br /> the right
                  place.
                </p>
                <p className="howwork-turpis-btn">
                  <a href="#" className="btn privacy-deactivate">
                    Contact Us
                  </a>
                </p>
                <p className="section-discription safety-feedback">
                  You can also give us feedback.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactSupport;
