import React from "react";
import { useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { REACT_APP_HOST_NAME } from "../baseurl";
import { useState } from "react";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import Header from "./Header";
import CustomModal from "./CustomModal";
import { ViewDetailModal } from "./ViewDetailModal";
import { CheckoutModal } from "./CheckoutModal";
import { CheckInModal } from "./CheckInModal";
import ReviewModal from "./ReviewModal";
import { toast } from "react-toastify";

const Scheduled = () => {
  const nav = useNavigate();
  const [count, setCount] = useState(1);
  const [sceduleData, setSceduleData] = useState([]);
  const [completeData, setCompleteData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [momentData, setMomentData] = useState({});
  const [reviewPopup, setReviewPopup] = useState(false);
  const [selectedMomentId, setSelectedMomentId] = useState("");
  const [userId, setUserId] = useState();
  const [plusOneId, setPlusOneId] = useState();
  const [showDetail, setShowDetail] = useState(false);
  const [showCheckIn, setShowCheckIn] = useState(false);
  const [showCheckOut, setShowCheckOut] = useState(false);

  const handleNavigate = (id, elem) => {
    console.log(id, "id");
    nav("/contractor_profile", { state: { id: id, details: elem } });
  };

  const handleScedule = () => {
    const token = localStorage.getItem("PO_TOKEN");
    var body = new URLSearchParams();
    body.append("is_plus_one", "false");
    body.append("page", count);
    axios({
      method: "post",
      url: `${REACT_APP_HOST_NAME}/apps/list-of-schedule-moment`,
      headers: { Authorization: `Bearer ${token}` },
      data: { is_plus_one: false, page: count },
    })
      .then((res) => {
        console.log(res, "boom baam");
        setSceduleData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err.response.data.message);
      });
  };

  const handleComplate = () => {
    const token = localStorage.getItem("PO_TOKEN");
    var body = new URLSearchParams();
    body.append("is_plus_one", false);
    body.append("page", count);
    axios({
      method: "post",
      url: `${REACT_APP_HOST_NAME}/apps/list-of-complete-moment`,
      headers: { Authorization: `Bearer ${token}` },
      data: { is_plus_one: false, page: count },
    })
      .then((res) => {
        setCompleteData(res.data.data);
        setLoading1(false);
      })
      .catch((err) => {
        console.log("err", err.res.data.message);
      });
  };

  const handleMoment = (moment_id) => {
    setSelectedMomentId(moment_id);
    const current = new Date();
    const time = current.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    const token = localStorage.getItem("PO_TOKEN");
    var body = new URLSearchParams();
    body.append("time", time);
    body.append("moment_id", moment_id);
    body.append("type", 0);

    axios({
      method: "post",
      url: `${REACT_APP_HOST_NAME}/apps/check-moment-status`,
      headers: { Authorization: `Bearer ${token}` },
      data: body,
    })
      .then((res) => {
        // console.log(res.data.data, "apna data");
        setMomentData(res.data.data);
        if (res.data.data.current_moment_status === 0) {
          setShowDetail(true);
        }
        if (res.data.data.current_moment_status === 1) {
          setShowCheckIn(true);
        }
        if (res.data.data.current_moment_status === 2) {
          setShowCheckOut(true);
        }
      })
      .catch((err) => {
        console.log(err.response.data.message, "Catch error");
      });
  };

  const add3Dots = (string, limit) => {
    var dots = "...";
    if (string.length > limit) {
      string = string.substring(0, limit) + dots;
    }
    return string;
  };

  const handleCompletedMoment = (moment) => {
    setReviewPopup(true);
    setMomentData(moment);
  };

  const getDay = (date) => {
    let day = moment(date).day();
    if (day === 1) return "Monday";
    if (day === 2) return "Tuesday";
    if (day === 3) return "Wednesday";
    if (day === 4) return "Thursday";
    if (day === 5) return "Friday";
    if (day === 6) return "Saturday";
    if (day === 7) return "Sunday";
  };

  const openChat = (elem) => {
    nav("/conversation", { state: elem });
  };

  useEffect(() => {
    handleScedule();
    handleComplate();
  }, []);

  return (
    <>
      <Header />
      {/* Scheduled Section Start */}
      <section className="scheduled-section">
        <div className="scheduled-content">
          <div className="scheduled-tabs-content">
            <h2 className="scheduled-title information-profile-title">
              Moments
            </h2>
            <ul
              className="nav nav-tabs scheduled-tabs border-0"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link scheduled-nav-link active"
                  id="scheduled-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#scheduled"
                  type="button"
                  role="tab"
                  aria-controls="scheduled"
                  aria-selected="true"
                >
                  Scheduled
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link scheduled-nav-link"
                  id="completed-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#completed"
                  type="button"
                  role="tab"
                  aria-controls="completed"
                  aria-selected="false"
                >
                  Completed
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="scheduled"
                role="tabpanel"
                aria-labelledby="scheduled-tab"
              >
                {!loading ? (
                  <div className="scheduled-tab-body">
                    {sceduleData.map((elem) => (
                      <div className="scheduled-details-content">
                        <div className="scheduled-details">
                          <div className="scheduled-details-img">
                            <img
                              src={
                                elem.profile_pic
                                  ? elem.profile_pic
                                  : `http://54.204.70.36:3000/uploads/a24a0a95e91a691502adc84e477b5f38.jpg`
                              }
                              alt=""
                              className="img-fluid"
                              style={{
                                height: "120px",
                                width: "120px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                              onClick={() =>
                                handleNavigate(elem.plus_one_id, elem)
                              }
                            />
                            <h6 className="scheduled-details-name text-center">
                              {elem.first_name + " " + elem.last_name}
                            </h6>
                          </div>
                          <div className="profile-order-address">
                            <p className="scheduled-details-link order-address-link">
                              <img
                                src="/images/Calendar.png"
                                alt=""
                                className="img-fluid"
                              />
                              {getDay(elem.date)} -{" "}
                              {moment(elem.date).format("MMM DD, YYYY")}
                            </p>
                            <p className="scheduled-details-link order-address-link">
                              <img
                                src="/images/Icon_Time.png"
                                alt=""
                                className="img-fluid"
                              />
                              {elem.time["new"]} <br /> Duration:{" "}
                              {elem.duration} hour
                            </p>
                            <p className="scheduled-details-link order-address-link">
                              <img
                                src="/images/pin_location.png"
                                alt=""
                                className="img-fluid"
                              />
                              {add3Dots(elem.address, 30)}
                            </p>
                          </div>
                        </div>
                        <div className="scheduled-view-details">
                          <a
                            style={{ color: "#008EF9" }}
                            onClick={() => openChat(elem)}
                          >
                            Chat
                          </a>
                          <a
                            style={{ color: "#008EF9" }}
                            onClick={() => handleMoment(elem._id)}
                          >
                            View Details
                          </a>
                        </div>
                      </div>
                    ))}

                    <div className="scheduled-new-moment" onClick={() => nav('/home')}>
                      <a >
                        <img
                          src="/images/plus.png"
                          alt=""
                          className="img-fluid"
                        />
                      </a>
                      <h2 className="schediled-new-moment-title errands-tab-body-title">
                        Book A New Moment
                      </h2>
                    </div>
                    <div className="sheduled-serach-bar">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="What do you want to do?"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="scheduled-tab-body">
                    <div className="scheduled-details-content">
                      <div className="scheduled-details ">
                        <div className="scheduled-details-img">
                          <Skeleton
                            count={1}
                            className="sk-schedule-img"
                          ></Skeleton>
                          <Skeleton
                            count={1}
                            className="sk-schedule-name m-0"
                          ></Skeleton>
                        </div>
                        <div className="profile-order-address">
                          <Skeleton
                            count={4}
                            className="sk-schedule-name"
                          ></Skeleton>
                        </div>
                      </div>
                      <div className="scheduled-view-details">
                        <Skeleton
                          count={1}
                          className="sk-schedule-name my-3"
                        ></Skeleton>
                        <Skeleton
                          count={1}
                          className="sk-schedule-name my-3"
                        ></Skeleton>
                      </div>
                    </div>
                    <div className="scheduled-details-content">
                      <div className="scheduled-details ">
                        <div className="scheduled-details-img">
                          <Skeleton
                            count={1}
                            className="sk-schedule-img"
                          ></Skeleton>
                          <Skeleton
                            count={1}
                            className="sk-schedule-name m-0"
                          ></Skeleton>
                        </div>
                        <div className="profile-order-address">
                          <Skeleton
                            count={4}
                            className="sk-schedule-name"
                          ></Skeleton>
                        </div>
                      </div>
                      <div className="scheduled-view-details">
                        <Skeleton
                          count={1}
                          className="sk-schedule-name my-3"
                        ></Skeleton>
                        <Skeleton
                          count={1}
                          className="sk-schedule-name my-3"
                        ></Skeleton>
                      </div>
                    </div>
                    <div className="scheduled-details-content">
                      <div className="scheduled-details ">
                        <div className="scheduled-details-img">
                          <Skeleton
                            count={1}
                            className="sk-schedule-img"
                          ></Skeleton>
                          <Skeleton
                            count={1}
                            className="sk-schedule-name m-0"
                          ></Skeleton>
                        </div>
                        <div className="profile-order-address">
                          <Skeleton
                            count={4}
                            className="sk-schedule-name"
                          ></Skeleton>
                        </div>
                      </div>
                      <div className="scheduled-view-details">
                        <Skeleton
                          count={1}
                          className="sk-schedule-name my-3"
                        ></Skeleton>
                        <Skeleton
                          count={1}
                          className="sk-schedule-name my-3"
                        ></Skeleton>
                      </div>
                    </div>
                    <div className="scheduled-details-content">
                      <div className="scheduled-details ">
                        <div className="scheduled-details-img">
                          <Skeleton
                            count={1}
                            className="sk-schedule-img"
                          ></Skeleton>
                          <Skeleton
                            count={1}
                            className="sk-schedule-name m-0"
                          ></Skeleton>
                        </div>
                        <div className="profile-order-address">
                          <Skeleton
                            count={4}
                            className="sk-schedule-name"
                          ></Skeleton>
                        </div>
                      </div>
                      <div className="scheduled-view-details">
                        <Skeleton
                          count={1}
                          className="sk-schedule-name my-3"
                        ></Skeleton>
                        <Skeleton
                          count={1}
                          className="sk-schedule-name my-3"
                        ></Skeleton>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className="tab-pane fade"
                id="completed"
                role="tabpanel"
                aria-labelledby="completed-tab"
              >
                {!loading1 ? (
                  <div className="scheduled-tab-body">
                    {completeData.map((elem) => (
                      <div className="scheduled-details-content">
                        <div className="scheduled-details border-bottom-0">
                          <div className="scheduled-details-img">
                            {elem.profile_pic ? (
                              <img
                                src={elem.profile_pic}
                                alt=""
                                className="img-fluid"
                                style={{
                                  height: "120px",
                                  width: "120px",
                                  borderRadius: "50%",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <div
                                style={{
                                  height: "120px",
                                  width: "120px",
                                  borderRadius: "50%",
                                  fontSize: "60px",
                                  background: "#F3F3F3",
                                  textAlign: "center",
                                  color: "black",
                                  paddingTop: "15px",
                                }}
                              >
                                S
                              </div>
                            )}
                            <h6 className="scheduled-details-name text-center">
                              {elem.name}
                            </h6>
                          </div>
                          <div className="profile-order-address">
                            <p className="scheduled-details-link order-address-link">
                              <img
                                src="/images/Calendar.png"
                                alt=""
                                className="img-fluid"
                              />
                              {elem.day} -{" "}
                              {moment(elem.date).format("MMM DD, YYYY")}
                            </p>
                            <p className="scheduled-details-link order-address-link">
                              <img
                                src="/images/Icon_Time.png"
                                alt=""
                                className="img-fluid"
                              />
                              {elem.time["new"]} <br /> Duration:{" "}
                              {elem.duration} hour
                            </p>
                            <p className="scheduled-details-link order-address-link">
                              <img
                                src="/images/pin_location.png"
                                alt=""
                                className="img-fluid"
                              />
                              {add3Dots(elem.address, 30)}
                            </p>
                          </div>
                        </div>
                        <div className="scheduled-view-details border-0">
                          <a
                            className="btn btn-warning w-100"
                            onClick={() => handleCompletedMoment(elem)}
                          >
                            Review & Tip
                          </a>
                        </div>
                      </div>
                    ))}
                    <div className="scheduled-new-moment" onClick={() => nav('/home')}>
                      <a >
                        <img
                          src="/images/plus.png"
                          alt=""
                          className="img-fluid"
                        />
                      </a>
                      <h2 className="schediled-new-moment-title errands-tab-body-title">
                        Book A New Moment
                      </h2>
                    </div>
                    <div className="sheduled-serach-bar">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="What do you want to do?"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="scheduled-tab-body">
                    <div className="scheduled-details-content">
                      <div className="scheduled-details border-bottom-0">
                        <div className="scheduled-details-img">
                          <Skeleton
                            count={1}
                            className="sk-sel-gal"
                            style={{ borderRadius: "50%" }}
                          ></Skeleton>
                          <h6 className="scheduled-details-name text-center">
                            <Skeleton
                              count={1}
                              className="sk-cat-name"
                            ></Skeleton>
                          </h6>
                        </div>
                        <div className="profile-order-address">
                          <Skeleton
                            count={4}
                            className="sk-sel-details"
                          ></Skeleton>
                        </div>
                      </div>
                      <div className="scheduled-view-details border-0">
                        <a
                          className="btn btn-warning w-100"
                          data-bs-toggle="modal"
                          data-bs-target="#reviewModal"
                        >
                          Loading...
                        </a>
                      </div>
                    </div>
                    <div className="scheduled-details-content">
                      <div className="scheduled-details border-bottom-0">
                        <div className="scheduled-details-img">
                          <Skeleton
                            count={1}
                            className="sk-sel-gal"
                            style={{ borderRadius: "50%" }}
                          ></Skeleton>
                          <h6 className="scheduled-details-name text-center">
                            <Skeleton
                              count={1}
                              className="sk-cat-name"
                            ></Skeleton>
                          </h6>
                        </div>
                        <div className="profile-order-address">
                          <Skeleton
                            count={4}
                            className="sk-sel-details"
                          ></Skeleton>
                        </div>
                      </div>
                      <div className="scheduled-view-details border-0">
                        <a
                          className="btn btn-warning w-100"
                          data-bs-toggle="modal"
                          data-bs-target="#reviewModal"
                        >
                          Loading...
                        </a>
                      </div>
                    </div>
                    <div className="scheduled-details-content">
                      <div className="scheduled-details border-bottom-0">
                        <div className="scheduled-details-img">
                          <Skeleton
                            count={1}
                            className="sk-sel-gal"
                            style={{ borderRadius: "50%" }}
                          ></Skeleton>
                          <h6 className="scheduled-details-name text-center">
                            <Skeleton
                              count={1}
                              className="sk-cat-name"
                            ></Skeleton>
                          </h6>
                        </div>
                        <div className="profile-order-address">
                          <Skeleton
                            count={4}
                            className="sk-sel-details"
                          ></Skeleton>
                        </div>
                      </div>
                      <div className="scheduled-view-details border-0">
                        <a
                          className="btn btn-warning w-100"
                          data-bs-toggle="modal"
                          data-bs-target="#reviewModal"
                        >
                          Loading...
                        </a>
                      </div>
                    </div>
                    <div className="scheduled-details-content">
                      <div className="scheduled-details border-bottom-0">
                        <div className="scheduled-details-img">
                          <Skeleton
                            count={1}
                            className="sk-sel-gal"
                            style={{ borderRadius: "50%" }}
                          ></Skeleton>
                          <h6 className="scheduled-details-name text-center">
                            <Skeleton
                              count={1}
                              className="sk-cat-name"
                            ></Skeleton>
                          </h6>
                        </div>
                        <div className="profile-order-address">
                          <Skeleton
                            count={4}
                            className="sk-sel-details"
                          ></Skeleton>
                        </div>
                      </div>
                      <div className="scheduled-view-details border-0">
                        <a
                          className="btn btn-warning w-100"
                          data-bs-toggle="modal"
                          data-bs-target="#reviewModal"
                        >
                          Loading...
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Scheduled Section End */}
      <CustomModal
        isModalOpen={showDetail}
        handleOk={() => setShowDetail(false)}
        handleCancel={() => setShowDetail(false)}
      >
        <ViewDetailModal
          moment_data={momentData}
          handleScedule={handleScedule}
          handleCancel={() => setShowDetail(false)}
        />
      </CustomModal>

      <CustomModal
        isModalOpen={showCheckOut}
        handleOk={() => setShowCheckOut(false)}
        handleCancel={() => setShowCheckOut(false)}
      >
        <CheckoutModal moment_id={selectedMomentId} moment_data={momentData} />
      </CustomModal>
      <CustomModal
        isModalOpen={showCheckIn}
        handleOk={() => setShowCheckIn(false)}
        handleCancel={() => setShowCheckIn(false)}
      >
        <CheckInModal moment_id={selectedMomentId} moment_data={momentData} />
      </CustomModal>

      {/*=========================== Review Modal Start ===========================*/}
      <CustomModal
        isModalOpen={reviewPopup}
        handleOk={() => setReviewPopup(false)}
        handleCancel={() => setReviewPopup(false)}
      >
        <ReviewModal moment_data={momentData} />
      </CustomModal>
    </>
  );
};

export default Scheduled;
