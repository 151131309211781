import React from "react";

const UploadImgModal = ({ handleSubmit }) => {
  return (
    <div className="modal-dialog upload-dialog">
      <div className="modal-content upload-modal-content ">
        <div className="modal-body upload-modal-body">
          <div className="upload-modal-img">
            <img src="/images/term_img.png" alt="" className="img-fluid" />
          </div>
          <p className="upload-modal-discription">
            By tapping the button below, you agree to Plus One’s Terms of Use
            and acknowledge that you have read the Privacy Policy
          </p>
          <p className="upload-modal-discription upload-modal-policy">
            To learn more, see our{" "}
            <a href="javascript:void(0)">Terms of Use </a> and{" "}
            <a href="javascript:void(0)"> Privacy Policy.</a>
          </p>
          <div className="upload-modal-btn">
            <button
              // href="/payment_methods"
              type="submit"
              //   data-bs-toggle="modal"
              //   data-bs-target="#loginModal"
              className="btn btn-warning information-btn"
              onClick={handleSubmit}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadImgModal;
