import React from "react";

const PaymentMethod2 = () => {
  return (
    //  Review Book Section Start
    <section className="r-book-section">
      <div className="r-book-content">
        <div className="r-book-heading">
          <div className="r-book-logo information-logo">
            <img src="/images/logo.png" alt="" className="img-fluid" />
          </div>
          <div className="r-book-progress information-tab-progress">
            <div className="r-book-progress-title information-progress-title">
              <h6 className="mb-0">
                <span>Your progress:</span> 90% Complete
              </h6>
            </div>
            <div className="progress w-100">
              <div
                className="progress-bar"
                role="progressbar"
                style={{width:"90%"}}
                aria-valuenow="90"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="r-book-body">
            <a href="javascript:void(0)" className="text-decoration-none information-form-back">
              <i className="fa-solid fa-arrow-left"></i>
            </a>
            <div className="r-book-payment-content">
              <h2 className="p-method-title information-profile-title">
                Review & Book
              </h2>
              <p className="p-method-headline r-book-payment-headline information-policy">
                <img src="/images/card.png" alt="" className="img-fluid" /> You
                won't be billed until you're done with your Moment.
              </p>
              <div className="p-method-body r-book-payment">
                <div className="r-book-payment-heading">
                  <div className="r-book-payment-details">
                    <h5 className="r-book-details-title">
                      Moment Details{" "}
                      <img src="/images/edit.png" alt="" className="img-fluid" />
                    </h5>
                    <h5 className="r-book-details-subtitle">Run Errands</h5>
                    <div className="r-book-addres massage-update-moment">
                      <p className="order-address-link">
                        <img
                          src="/images/Calendar.png"
                          alt=""
                          className="img-fluid"
                        />
                        Wednesday - Jul 27, 2022
                      </p>
                      <p className="order-address-link">
                        <img
                          src="/images/Icon_Time.png"
                          alt=""
                          className="img-fluid"
                        />
                        9:30 am
                      </p>
                      <p className="order-address-link">
                        <img
                          src="/images/pin_location.png"
                          alt=""
                          className="img-fluid"
                        />
                        IKEA Furniture, Orlando
                      </p>
                    </div>
                  </div>
                  <div className="r-book-payment-profile conversation-profile-img w-auto me-0 text-center">
                    <img
                      src="/images/price_1.png"
                      alt=""
                      className="img-fluid rounded-circle"
                    />
                    <h6 className="conversation-profile-name">George C.</h6>
                    <p className="how-work-profile-elite mb-0 mt-0">
                      <i className="fa-solid fa-circle-check"></i>
                      <span className="section-discription">ID Verified</span>
                    </p>
                    <p className="r-book-profile-star order-address-link">
                      <img
                        src="/images/Icon_Star.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      4.8
                    </p>
                  </div>
                </div>
                <div className="r-book-payment-map massage-momemt-map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4278.640508915216!2d-81.42805854881419!3d28.48157429793721!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e77c1d5ca10127%3A0x4cf6daa1d9944d18!2sIKEA!5e1!3m2!1sen!2sin!4v1661769942248!5m2!1sen!2sin"
                    width="100%"
                    height="150"
                    style={{border:"0"}}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
                <div className="r-book-payment-card">
                  <h5 className="r-book-details-title mb-0">Payment</h5>
                  <div className="r-book-card">
                    <div className="r-book-card-details">
                      <a href="javascript:void(0)" className="text-decoration-none">
                        <p className="r-book-payment-order order-address-link">
                          <span>
                            <img
                              src="/images/ApplePay.png"
                              alt=""
                              className="img-fluid"
                            />
                          </span>{" "}
                          Apple Pay
                        </p>
                        <p className="r-book-payment-order mb-0">Visa (***1234)</p>
                      </a>
                    </div>
                    <a href="javascript:void(0)" className="r-book-card-more">
                      <i className="fa-solid fa-angle-right"></i>
                    </a>
                  </div>
                </div>
                <div className="r-book-price">
                  <h5 className="r-book-price-title r-book-details-title">
                    Price Details
                  </h5>
                  <p className="r-book-price-point order-address-link">
                    <span>Hourly Rate</span> <span>$19/hr</span>
                  </p>
                  <p className="r-book-price-point order-address-link">
                    <span>
                      Trust & Support Fee{" "}
                      <img
                        src="/images/information.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                    </span>{" "}
                    <span>$8.00</span>
                  </p>
                </div>
                <div className="p-method-total r-book-total-payment">
                  <h5 className="r-book-details-title mb-0">Total</h5>
                  <h5 className="r-book-details-title mb-0">
                    <del className="order-address-link">$27.00</del> $17.00
                  </h5>
                </div>
                <div className="p-method-footer">
                  <div className="p-method-promocode">
                    <div className="p-method-promo">
                      <a
                        href="javascript:void(0)"
                        className="p-method-code-title order-address-link"
                      >
                        Promo Code{" "}
                        <img src="/images/edit.png" alt="" className="img-fluid" />
                      </a>
                      <span className="p-method-code-link order-address-link">
                        LABORDAY20
                      </span>
                    </div>
                    <h6 className="p-method-code-discount order-address-link">
                      $10.00
                    </h6>
                  </div>
                  <p className="r-book-payment-policy">
                    You may see a temporary hold on your payment method in the
                    amou of your Plus One’s hourly rate of $35. You can cancel
                    ata ny time. Moments canceled less than 24 hours before the
                    start time may be billed a <a href="javascript:void(0)">cancellation fee </a>{" "}
                    of one hour. Moments have a pne hour minimum.
                  </p>
                  <p className="r-book-payment-policy mb-0">
                    Please follow all public health regulations in your area.{" "}
                    <a href="javascript:void(0)">Learn more</a>{" "}
                  </p>
                  <div className="p-method-btn r-book-payment-btn text-center">
                    <a href="javascript:void(0)" className="btn btn-warning">
                      Book Your Moment
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    //   Review Book Section End
  );
};

export default PaymentMethod2;
