import axios from "axios";
import React from "react";
import { REACT_APP_HOST_NAME } from "../baseurl";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

export const CheckoutModal = (props) => {
  const { moment_id, moment_data } = props;
  console.log(moment_id, "momentId");
  const handleCheckOut = () => {
    const token = localStorage.getItem("PO_TOKEN");
    var body = new URLSearchParams();
    axios({
      method: "get",
      url: `${REACT_APP_HOST_NAME}/apps/user-check-out?moment_id=${moment_id}`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        alert("your moment is checked out! have a good day!!");
      })
      .catch((err) => {
        console.log("Catch error");
      });
  };

  const containerStyle = {
    width: "100%",
    height: "200px",
    border: "1px solid grey",
    borderRadius: "8px",
  };
  return (
    <>
      <div className="modal-dialog checkin-modal-dialog">
        <div className="modal-content checkin-modal-content">
          <div className="modal-body checkin-modal-body">
            <div className="">
              <div className="checkin-modal-profile massage-moment-profile">
                <h4 className=" details-modal-title mb-3">
                  This Moment is about to End!
                </h4>

                <div className="message-po-profile d-flex mt-2">
                  <img
                    src="/images/featured_1.png"
                    height={50}
                    width={50}
                    alt=""
                    srcset=""
                  />

                  <h5
                    className="ms-4 message-po-profile-heading"
                    style={{ color: "#17146B" }}
                  >
                    Your Plus One George Will meet you here:
                  </h5>
                </div>
              </div>
              {/* <div className="checkin-modal-image massage-moment-image">
                <img
                  src="/images/Illus_Run Errands.png"
                  alt=""
                  className="img-fluid w-100"
                />
              </div> */}
            </div>
            <div className="checkin-modal-map massage-momemt-map">
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={{
                  lat: moment_data.lat,
                  lng: moment_data.long,
                }}
                zoom={18}
              >
                <Marker
                  position={{
                    lat: moment_data.lat,
                    lng: moment_data.long,
                  }}
                />
              </GoogleMap>
            </div>
            <div className="plus-one-moment-detail d-flex justify-content-center">
              <div className="text-align-center">
                <p className="order-address-link">
                  <img
                    src="/images/Calendar.png"
                    alt=""
                    className="img-fluid"
                  />
                  Thursday - Aug 18, 2022
                </p>
                <p className="order-address-link">
                  <img
                    src="/images/Icon_Time.png"
                    alt=""
                    className="img-fluid"
                  />
                  9:30 am - 10:30 am
                </p>
                <p className="order-address-link">
                  <img
                    src="/images/pin_location.png"
                    alt=""
                    className="img-fluid"
                  />
                  IKEA Furniture, Orlando
                </p>
              </div>
            </div>
            <div className="">
              <div className="" style={{ textAlign: "center" }}>
                <button
                  type="button"
                  className="btn btn-warning w-100 my-2"
                  onClick={handleCheckOut}
                >
                  Click To Check Out
                </button>
                <a href="" className="checkout-c-btn">
                  Extend Moment
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
