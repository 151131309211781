import React, { useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { REACT_APP_HOST_NAME } from "../baseurl";

function StripePayment() {
  const stripe = useStripe();
  const elements = useElements();
  const [details, setDetails] = useState({
    name: "",
    postal_code: "",
    country: "",
  });

  const handleChange = (e) => {
    const newData = { ...details };
    newData[e.target.name] = e.target.value;
    setDetails(newData);
  };
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        // lineHeight: "27px",
        // color: "#A5A5A5",
        // "::placeholder": {
        //     color: "#aab7c4",
        // },
        // backgroundColor: '#FFFFFF',
        border: "0.5px solid #959da3",
      },
      // invalid: {
      //     color: "#fa755a",
      //     iconColor: "#fa755a",
      // },
    },
  };
  const [err, setErr] = useState("");
  // border: 0.5px solid #959da3;
  // padding: 12px 16px;
  // font-weight: 400;
  // font-size: 15px;
  // line-height: 20px;
  // color: #646667;

  const validate = () => {
    let isValid = true;
    return isValid;
  };
  const handleConfirm = () => {
    if (validate()) {
      const token = localStorage.getItem("PO_TOKEN");
      axios({
        method: "get",
        url: `${REACT_APP_HOST_NAME}/apps/checkout-booking`,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          // setTopRated(response.data.data);
          console.log(response.data.data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };
  return (
    <div>
      <div className="payment-card-address">
        <div className="payment-card-input">
          <input
            type="text"
            className="form-control"
            placeholder="Card Holder Name"
            name="name"
            onChange={handleChange}
          />
        </div>
        <div className="payment-card-input payment-card-no">
          <CardNumberElement
            id="cc-number"
            className="form-control border-top-0 border-end-0 border-start-0 "
            options={CARD_ELEMENT_OPTIONS}
          />
          {/* <input type="text" className="form-control border-top-0 border-end-0 border-start-0 card-lock" placeholder="Card number" /> */}
          <div className="payment-card-input payment-card-Expiration mb-0">
            <CardExpiryElement
              id="expiry"
              className="form-control border-0"
              options={CARD_ELEMENT_OPTIONS}
            />
            {/* <input type="text" className="form-control border-0" placeholder="Expiration" /> */}
            <CardCvcElement
              id="cvc"
              className="form-control border-top-0 border-end-0 border-bottom-0"
              options={CARD_ELEMENT_OPTIONS}
            />
            {/* <input type="text" className="form-control border-top-0 border-end-0 border-bottom-0" placeholder="CVV" /> */}
          </div>
        </div>

        <div className="payment-card-input">
          <input
            type="text"
            className="form-control"
            placeholder="Postcode"
            name="postal_code"
            onChange={handleChange}
          />
        </div>
        <div className="payment-card-input payment-card-select-country">
          <span>Country/Region</span>
          <select
            className="form-select form-control"
            name="country"
            onChange={handleChange}
          >
            <option>United States of America</option>
            <option>United Kingdom</option>
            <option>Afghanistan</option>
            <option>Algeria</option>
          </select>
        </div>
        <div style={{ color: "red" }}>{err}</div>
      </div>
      <div className="payment-book-btn text-center">
        <a
          onClick={handleConfirm}
          className="btn btn-warning"
          data-bs-toggle="modal"
          data-bs-target="#bookModal"
        >
          Confirm Payment Method
        </a>
      </div>
    </div>
  );
}

export default StripePayment;
