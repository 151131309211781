import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import {
  faEye,
  faEyeSlash,
  faTimes,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { REACT_APP_HOST_NAME } from "../../baseurl";
const LoginEmail = () => {
  const nav = useNavigate();
  const [load, setLoad] = useState({ facebook: false, google: false });

  const handleNavigate = () => {
    nav("/");
  };
  useEffect(() => {
    if (
      localStorage.getItem("PO_TOKEN") !== null &&
      localStorage.getItem("PO_TOKEN") !== ""
    ) {
      handleNavigate("/home");
    }
  }, []);

  const [show, setShow] = useState(false);

  const handleShowhide = () => {
    setShow(!show);
  };

  const [details, setDetails] = useState({
    email: "",
    password: "",
  });

  const handleEmail = (e) => {
    setDetails({ ...details, email: e.target.value });
    setemailErr("");
  };
  const handlePassword = (e) => {
    setDetails({ ...details, password: e.target.value });
    setpwdErr("");
  };
  const validate = () => {
    let isValid = true;
    if (!details.email) {
      setemailErr("Please Enter Your Email");
      isValid = false;
    }

    if (!details.password) {
      setpwdErr("Please Enter Password");
      isValid = false;
    }
    return isValid;
  };
  const handleDetails = (e) => {
    e.preventDefault();
    if (validate()) {
      // setToggleOtp(true);
      const data = {
        email: details.email,
        password: details.password,
        device_token: '123435'
      };
      axios
        .post(`${REACT_APP_HOST_NAME}/apps/login-with-email`, data, {
          headers: {
            // "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          nav("/home");
          localStorage.setItem("PO_TOKEN", response.data.data.token);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log("error", error.response.data.message);
        });
    }
  };
  const responseGoogle = (response) => {
    console.log(response);
  };
  const [emailErr, setemailErr] = useState("");
  const [pwdErr, setpwdErr] = useState("");

  return (
    <>
      {/* Site Content Start */}
      <main className="po-site-content">
        {/* Login Section Start */}
        <section className="po-login-section">
          <div className="container-fluid">
            <div className="po-login-content">
              <div className="row ">
                <div className="col col-12 col-md-12 col-lg-6 col-xl-6 p-0">
                  <div className="login-img">
                    <img
                      src="/images/login_img.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col col-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="login-form-area">
                    <div className="login-body-content login-email-body">
                      <div className="login-logo">
                        <img
                          src="/images/logo.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="login-input">
                        <label htmlFor="form-label">
                          What’s your email address
                        </label>
                        <div className="login-email-input">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="sample@domain.com"
                            id="email"
                            onChange={handleEmail}
                          />
                          {/* <a href="javascript:void(0)" className="login-email-cancel">
                                                    <img src="/images/Cancel.png" alt="" className="img-fluid"/>
                                                </a> */}
                        </div>
                        <p
                          className="err"
                          id="errEmail"
                          style={{ color: "red", marginTop: "10px" }}
                        >
                          {emailErr}
                        </p>
                      </div>
                      <div className="login-input">
                        <label htmlFor="form-label">What’s your password</label>
                        <div className="login-email-input">
                          <input
                            type={show ? "text" : "password"}
                            className="form-control"
                            placeholder="********"
                            id="pwd"
                            onChange={handlePassword}
                          />
                          {/* <a
                            href="javascript:void(0)"
                            className="login-email-cancel"
                          >
                            <img
                              src="/images/Cancel.png"
                              alt=""
                              className="img-fluid"
                            />

                            
                          </a> */}

                          {show ? (
                            <FontAwesomeIcon
                              icon={faEye}
                              id="show_hide_email"
                              onClick={handleShowhide}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faEyeSlash}
                              id="show_hide_email"
                              onClick={handleShowhide}
                            />
                          )}
                        </div>
                        <p
                          className="err errPass"
                          id="errPassword"
                          style={{ color: "red" }}
                        >
                          {pwdErr}
                        </p>
                      </div>
                      <div className="login-btn text-center">
                        <button
                          type="submit"
                          className="btn btn-warning"
                          //   data-bs-toggle="modal"
                          //   data-bs-target="#gmailModal"
                          onClick={handleDetails}
                        >
                          Log In
                        </button>
                      </div>
                      <p className="login-forgot">
                        <span className="po-login-forgot-pass-text">
                          Forgot password?
                        </span>{" "}
                        <a
                          className="po-login-forgot-link"
                          onClick={() => nav("/forget_password")}
                        >
                          Reset it
                        </a>
                      </p>
                      <div className="login-other-option">
                        <span className="login-other-title">
                          or continue with
                        </span>
                        <div className="login-option-list">
                          <div className="login-option-item">
                            <a
                              // href="javascript:void(0)"
                              className="login-option-link"
                            // onClick={() => {
                            //   setFacebookLoad(true);
                            // }}
                            >
                              <FacebookLogin
                                appId="673974744452514"
                                autoLoad={load.facebook}
                                fields="name,email,picture"
                                // onClick={componentClicked}
                                cssClass="p-0 m-0 border-0 bg-transparent"
                                callback={() => {
                                  nav("/home");
                                }}
                                textButton="Continue with Facebook"
                              />
                            </a>
                          </div>
                          <div
                            className="login-option-item"
                            onClick={() => {
                              setLoad({ ...load, google: true });
                            }}
                          >
                            <a className="login-option-link">
                              <GoogleLogin
                                clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
                                buttonText="Login"
                                autoLoad={load.google}
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                render={(renderProps) => (
                                  <button
                                    onClick={renderProps.onClick}
                                    disabled={renderProps.disabled}
                                    className="p-0 m-0 border-0 bg-transparent"
                                  >
                                    Continue with Gmail
                                  </button>
                                )}
                              // cookiePolicy={"single_host_origin"}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Login Section End */}
      </main>
      {/* Site Content End */}

      {/* Gmail Modal Start */}
      <div
        className="modal fade gmail-modal"
        id="gmailModal"
        tabIndex="-1"
        aria-labelledby="gmailModal"
        aria-hidden="true"
      >
        <div className="modal-dialog gmail-modal-dialog">
          <div className="modal-content">
            <div className="modal-header gmail-modal-header">
              <h6 className="modal-title gmail-modal-title" id="gmailModal">
                <img
                  src="/images/Icon_Google.png"
                  alt=""
                  className="img-fluid"
                />{" "}
                Sign In with Google
              </h6>
            </div>
            <div className="modal-body gmail-modal-body">
              <div className="gmail-modal-plus text-center">
                <img
                  src="/images/gmail_plus.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <h4 className="gmail-modal-body-title">Choose an account</h4>
              <h6 className="gmail-modal-body-subtitle">
                to continue to <a href="javascript:void(0)">Plus One</a>
              </h6>
              <div className="gmail-modal-choose-account">
                <a href="javascript:void(0)">
                  <div className="gmail-account-img">
                    <img
                      src="/images/gmail_profile.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="gmail-account-id">
                    <h5>Stacy Ann Michaels</h5>
                    <span>stacyann@gmail.com</span>
                  </div>
                </a>
              </div>
              <div className="gmail-modal-choose-account">
                <a href="javascript:void(0)">
                  <div className="gmail-account-img">
                    <img
                      src="/images/gmail_profile_1.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="gmail-account-id">
                    <h5>Stacy Ann Michaels</h5>
                    <span>stacy@workdomain.com</span>
                  </div>
                </a>
              </div>
              <div className="gmail-modal-choose-account">
                <a href="javascript:void(0)">
                  <div className="gmail-account-img w-auto h-75">
                    <img
                      src="/images/Icon_Add User.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="gmail-account-id">
                    <h5>Add another account</h5>
                  </div>
                </a>
              </div>
            </div>
            <div className="modal-footer border-0 gmail-footer-modal">
              <p className="gmail-modal-policy login-facebook-policy m-0">
                To continue, Google will share your name, email address, and
                profile picture with Plus One. Before using this app, review its{" "}
                <a href="javascript:void(0)">privacy policy </a> and{" "}
                <a href="javascript:void(0)">terms of service.</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Gmail Modal End */}
    </>
  );
};

export default LoginEmail;
