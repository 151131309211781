import React, {useState} from "react";
import ProfileComplete from "./ProfileComplete";

const PaymentMethods = ({
  step,
  setStep,
  processPipline,
  setProcessPipline,
  setBack,
  data,
}) => {
  const goBack = () => {
    setBack(2);
  };
  const [toggleProfileComplete, setToggleProfileComplete] = useState(false);

  const handleNextStep = (e) => {
    // e.preventDefault();
    console.log("data", data);
    setStep(4);
    setProcessPipline(100);
    setToggleProfileComplete(true)
  };

  return (
    <>
      <div
        className="tab-pane "
        id="method"
        role="tabpanel"
        aria-labelledby="method-tab"
      >
        <div className="information-tab-body">
          
          <div className="information-form-content">
            <div className="container">
              <div className="information-form-box">
                {/* <a
                  // href="javascript:void(0)"
                  className="text-decoration-none information-form-back"
                  onClick={() => goBack()}
                >
                  <i className="fa-solid fa-arrow-left"></i>
                </a> */}
                <div className="information-form information-payment-form">
                  <h2 className="information-payment-title information-form-title section-title">
                    Select your preferred payment methods
                  </h2>
                  <div className="payment-method-link">
                    <div className="signup-payment-card-img payment-card-img">
                      <a href="javascript:void(0)">
                        <img
                          src="/images/credit.png"
                          alt=""
                          className="img-fluid"
                        />
                      </a>
                    </div>
                    <div className="signup-card-name payment-card-name">
                      <a
                        href="javascript:void(0)"
                        className="text-decoration-none"
                      >
                        <h5>Credit or Debit Card</h5>
                      </a>
                    </div>
                    <div className="payment-card-icon">
                      <a href="javascript:void(0)">
                        <img
                          src="/images/right_arrow.png"
                          alt=""
                          className="img-fluid"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="payment-method-link">
                    <div className="signup-payment-card-img payment-card-img">
                      <a href="javascript:void(0)">
                        <img
                          src="/images/benefits.png"
                          alt=""
                          className="img-fluid"
                        />
                      </a>
                    </div>
                    <div className="signup-card-name payment-card-name">
                      <a
                        href="javascript:void(0)"
                        className="text-decoration-none"
                      >
                        <h5>Commuter Benefits</h5>
                      </a>
                    </div>
                    <div className="payment-card-icon">
                      <a href="javascript:void(0)">
                        <img
                          src="/images/right_arrow.png"
                          alt=""
                          className="img-fluid"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="payment-method-link">
                    <div className="signup-payment-card-img payment-card-img">
                      <a href="javascript:void(0)">
                        <img
                          src="/images/paypal.png"
                          alt=""
                          className="img-fluid"
                        />
                      </a>
                    </div>
                    <div className="signup-card-name payment-card-name">
                      <a
                        href="javascript:void(0)"
                        className="text-decoration-none"
                      >
                        <h5>Paypal</h5>
                      </a>
                    </div>
                    <div className="payment-card-icon">
                      <a href="javascript:void(0)">
                        <img
                          src="/images/right_arrow.png"
                          alt=""
                          className="img-fluid"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="signup-payment-promo payment-method-promo">
                    <a onClick={(e) => handleNextStep(e)}>
                      Do this later
                    </a>
                  </div>
                </div>
              </div>
              <div className="information-payment-addcard information-form">
                <h2 className="information-payment-title information-form-title section-title mb-0">
                  Add Card
                </h2>
                <div className="information-addcard-form">
                  <div className="row">
                    <div className="col col-12">
                      <div className="information-addcard-input information-form-input">
                        <label className="form-label">Card Number</label>
                        <div className="addcard-input">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="1234567890"
                          />
                          <a
                            href="javascript:void(0)"
                            className="addcard-cancel-icon"
                          >
                            <img
                              src="/images/Cancel.png"
                              alt=""
                              className="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col col-12 col-md-6">
                      <div className="information-addcard-input information-form-input">
                        <label className="form-label">Expiry Date</label>
                        <div className="addcard-input">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="8/25"
                          />
                          <a
                            href="javascript:void(0)"
                            className="addcard-cancel-icon"
                          >
                            <img
                              src="/images/Question _Mark.png"
                              alt=""
                              className="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col col-12 col-md-6">
                      <div className="information-addcard-input information-form-input">
                        <label className="form-label">CVV</label>
                        <div className="addcard-input">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="890"
                          />
                          <a
                            href="javascript:void(0)"
                            className="addcard-cancel-icon"
                          >
                            <img
                              src="/images/Question _Mark.png"
                              alt=""
                              className="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="information-addcard-input information-form-select">
                        <select className="payment-book-select">
                          <option
                            value="en"
                            className="test"
                            data-thumbnail="http://127.0.0.1:5500//images/country_flag.png"
                          >
                            United States of America
                          </option>
                          <option
                            value="au"
                            data-thumbnail="http://127.0.0.1:5500//images/country_flag.png"
                          >
                            United States of America
                          </option>
                          <option
                            value="uk"
                            data-thumbnail="http://127.0.0.1:5500//images/country_flag.png"
                          >
                            United States of America
                          </option>
                        </select>
                        <div className="payment-book-option">
                          <button
                            className="payment-book-option-btn"
                            value=""
                          ></button>
                          <div className="payment-book-list border-top-0">
                            <ul className="payment-option-list"></ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="information-addcard-input information-form-input">
                        <label className="form-label">Zip Code</label>
                        <div className="addcard-input">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="1234"
                          />
                          <a
                            href="javascript:void(0)"
                            className="addcard-cancel-icon"
                          >
                            <img
                              src="/images/Question _Mark.png"
                              alt=""
                              className="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col col-12">
                      <a
                        href="javascript:void(0)"
                        className="btn btn-warning information-btn w-100"
                        data-bs-toggle="modal"
                        data-bs-target="#uploadModal"
                      >
                        Next
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {toggleProfileComplete && <ProfileComplete handleNextStep={handleNextStep}/>}
    </>
  );
};

export default PaymentMethods;
