import React from "react";

const Footer = () => {
  return (
    <>
      <footer class="footer">
        <div class="container">
          <div class="footer-content">
            <div class="row align-items-center">
              <div class="col col-12 col-md-4 col-lg-4 col-xl-4">
                <h2 class="footer-title">
                  Get good company <br class="d-none d-md-block" /> on-demand
                </h2>
                <p class="footer-discription">Book your first Moment today</p>
                <a href="#" class="btn footer-btn btn-warning information-btn">
                  Book Your Moment
                </a>
              </div>
              <div class="col col-12 col-md-8 col-lg-8 col-xl-8">
                <div class="footer-img text-end">
                  <img src="/images/footer_img.png" alt="" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="footer-empty mb-0"></p>
        <div class="footer-copyright">
          <div class="container">
            <div class="footer-copyright-content">
              <a href="#" class="footer-copyright-logo">
                <img
                  src="assets/images/footer_logo.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
              <p class="footer-copyright-discription">
                © Copyright 2022 PlusOne. All Rights Reserved.{" "}
              </p>
              <div class="footer-app-store">
                <a href="#">
                  <img
                    src="assets/images/app_store.png"
                    alt=""
                    class="img-fluid"
                  />
                </a>
                <a href="#" class="me-0">
                  <img
                    src="assets/images/play_store.png"
                    alt=""
                    class="img-fluid"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <footer className="footer">
        <div className="footer-copyright">
            <div className="container">
                <div className="footer-copyright-content">
                    <a href="javascript:void(0)" className="footer-copyright-logo">
                        <img src="/images/footer_logo.png" alt="" className="img-fluid"/>
                    </a>
                    <p className="footer-copyright-discription">© Copyright 2022 PlusOne. All Rights Reserved. </p>
                    <div className="footer-app-store">
                        <a href="javascript:void(0)"><img src="/images/app_store.png" alt="" className="img-fluid"/></a>
                        <a href="javascript:void(0)" className="me-0"><img src="/images/play_store.png" alt="" className="img-fluid"/></a>
                    </div>
                </div>
            </div>
        </div>
    </footer> */}
    </>
  );
};

export default Footer;
