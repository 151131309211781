import React, { useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import io from "socket.io-client";
import { SERVER_URL } from "../baseurl";

// const socket = io(SERVER_URL, { transports: ["websocket"] });
const Notification = () => {
  // useEffect(() => {
  //   socket.on("received notification", (newNotification) => {
  //     console.log(newNotification);
  //   });
  // }, [socket]);

  return (
    <>
      <Header />
      {/* Notification Section Start  */}
      <section className="notification-section">
        <div className="container">
          <div className="notification-content">
            <h2 className="notification-title">Notifications</h2>
            <div className="notification-list">
              <div className="notification-item active">
                <div className="notification-icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.9875 9.83459L23.304 2.98691C23.183 1.77539 22.225 0.817313 21.0134 0.696353L14.1658 0.0128335C13.4026 -0.0639665 12.6451 0.206753 12.1027 0.749153L0.7488 12.1031C-0.2496 13.1015 -0.2496 14.721 0.7488 15.7194L8.28096 23.2516C9.27936 24.25 10.8998 24.25 11.8973 23.2516L23.2512 11.8976C23.7936 11.3552 24.0634 10.5978 23.9875 9.83459ZM19.0454 4.95395C18.529 4.43747 18.529 3.60131 19.0454 3.08483C19.561 2.56931 20.3981 2.56835 20.9146 3.08483C21.431 3.60131 21.4301 4.43843 20.9146 4.95395C20.3981 5.47043 19.5619 5.47043 19.0454 4.95395Z"
                      fill="javascript:void(0)68B238"
                    />
                  </svg>
                </div>
                <div className="notification-details">
                  <a href="javascript:void(0)" className="text-decoration-none">
                    <h6 className="notification-details-title">
                      Duis autem vel eum iriure dolor in hendrerit in vulputate
                      velit esse molestie consequat
                    </h6>
                  </a>
                  <p className="notification-discription">
                    Vel illum dolore eu feugiat nulla...
                  </p>
                  <p className="notification-time">8:00 AM</p>
                </div>
                <div className="notification-remove-icon">
                  <a href="javascript:void(0)">
                    <img
                      src="/images/Remove.png"
                      alt=""
                      className="img-fluid w-100"
                    />
                  </a>
                </div>
              </div>
              <div className="notification-item">
                <div className="notification-icon">
                  <svg
                    width="26"
                    height="24"
                    viewBox="0 0 26 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.9713 18.9437L6.06637 13.1126L7.13581 21.7402C7.28461 22.9402 6.34861 24 5.13997 24C4.12429 24 3.26893 23.2435 3.14413 22.2355L1.99501 12.9744C1.31821 12.8669 0.799805 12.2794 0.799805 11.5718V7.37088C0.799805 6.58656 1.43629 5.95104 2.22061 5.95104H5.73133V5.96064L20.9713 0V18.9437ZM22.1723 6.12288V12.8198C24.0212 12.8198 25.5198 11.3203 25.5198 9.47136C25.5198 7.6224 24.0212 6.12384 22.1723 6.12384V6.12288Z"
                      fill="javascript:void(0)75BEEA"
                    />
                  </svg>
                </div>
                <div className="notification-details">
                  <a href="javascript:void(0)" className="text-decoration-none">
                    <h6 className="notification-details-title">
                      Duis autem vel eum iriure dolor in hendrerit in vulputate
                      velit esse molestie consequat
                    </h6>
                  </a>
                  <p className="notification-discription">
                    Vel illum dolore eu feugiat nulla...
                  </p>
                  <p className="notification-time">August 12, 2022</p>
                </div>
                <div className="notification-remove-icon">
                  <a href="javascript:void(0)">
                    <img
                      src="/images/Remove.png"
                      alt=""
                      className="img-fluid w-100"
                    />
                  </a>
                </div>
              </div>
              <div className="notification-item active">
                <div className="notification-icon">
                  <svg
                    width="30"
                    height="31"
                    viewBox="0 0 30 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.1133 14.2114C22.1277 15.5429 21.7754 16.7919 21.1524 17.8623C19.817 20.1567 19.1584 21.421 19.1584 24.0754V24.7176H10.8391V24.0744C10.8391 21.3874 10.1171 20.1182 8.79714 17.7768C8.21538 16.7458 7.88418 15.5554 7.88418 14.2872C7.88418 10.3243 11.1261 7.11697 15.1015 7.17361C18.9415 7.22833 22.072 10.3695 22.1124 14.2104L22.1133 14.2114ZM19.1498 26.0866H10.8477C10.841 26.1826 10.8381 26.2786 10.8381 26.3755C10.8381 27.0975 11.0224 27.7771 11.3469 28.3694C12.0525 29.6606 13.4244 30.5362 14.9988 30.5362C16.5732 30.5362 17.945 29.6606 18.6506 28.3694C18.9751 27.7771 19.1594 27.0975 19.1594 26.3755C19.1594 26.2786 19.1565 26.1826 19.1498 26.0866ZM4.87843 15.1579C4.87843 14.9179 4.68355 14.7231 4.44355 14.7231H1.21027C0.970273 14.7231 0.775391 14.9179 0.775391 15.1579C0.775391 15.3979 0.970273 15.5928 1.21027 15.5928H4.44355C4.68355 15.5928 4.87843 15.3979 4.87843 15.1579ZM7.84291 8.00209C8.01283 7.83217 8.01283 7.55665 7.84291 7.38769L5.55715 5.10193C5.38723 4.93201 5.11171 4.93201 4.94179 5.10193C4.77187 5.27185 4.77187 5.54737 4.94179 5.71633L7.22755 8.00209C7.31299 8.08657 7.42339 8.12977 7.53475 8.12977C7.64611 8.12977 7.75747 8.08753 7.84195 8.00209H7.84291ZM15.4336 4.60273V1.36945C15.4336 1.12945 15.2388 0.93457 14.9988 0.93457C14.7588 0.93457 14.5639 1.12945 14.5639 1.36945V4.60273C14.5639 4.84273 14.7588 5.03761 14.9988 5.03761C15.2388 5.03761 15.4336 4.84273 15.4336 4.60273ZM22.77 8.00209L25.0557 5.71633C25.2256 5.54641 25.2256 5.27089 25.0557 5.10193C24.8858 4.93297 24.6103 4.93201 24.4404 5.10193L22.1546 7.38769C21.9847 7.55761 21.9847 7.83313 22.1546 8.00209C22.2391 8.08657 22.3504 8.12977 22.4618 8.12977C22.5732 8.12977 22.6845 8.08753 22.769 8.00209H22.77ZM29.2221 15.1579C29.2221 14.9179 29.0272 14.7231 28.7872 14.7231H25.554C25.314 14.7231 25.1191 14.9179 25.1191 15.1579C25.1191 15.3979 25.314 15.5928 25.554 15.5928H28.7872C29.0272 15.5928 29.2221 15.3979 29.2221 15.1579Z"
                      fill="javascript:void(0)FFCE00"
                    />
                  </svg>
                </div>
                <div className="notification-details">
                  <a href="javascript:void(0)" className="text-decoration-none">
                    <h6 className="notification-details-title">
                      Duis autem vel eum iriure dolor in hendrerit in vulputate
                      velit esse molestie consequat
                    </h6>
                  </a>
                  <p className="notification-discription">
                    Vel illum dolore eu feugiat nulla...
                  </p>
                  <p className="notification-time">August 12, 2022</p>
                </div>
                <div className="notification-remove-icon">
                  <a href="javascript:void(0)">
                    <img
                      src="/images/Remove.png"
                      alt=""
                      className="img-fluid w-100"
                    />
                  </a>
                </div>
              </div>
              <div className="notification-item active">
                <div className="notification-icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.9875 9.83459L23.304 2.98691C23.183 1.77539 22.225 0.817313 21.0134 0.696353L14.1658 0.0128335C13.4026 -0.0639665 12.6451 0.206753 12.1027 0.749153L0.7488 12.1031C-0.2496 13.1015 -0.2496 14.721 0.7488 15.7194L8.28096 23.2516C9.27936 24.25 10.8998 24.25 11.8973 23.2516L23.2512 11.8976C23.7936 11.3552 24.0634 10.5978 23.9875 9.83459ZM19.0454 4.95395C18.529 4.43747 18.529 3.60131 19.0454 3.08483C19.561 2.56931 20.3981 2.56835 20.9146 3.08483C21.431 3.60131 21.4301 4.43843 20.9146 4.95395C20.3981 5.47043 19.5619 5.47043 19.0454 4.95395Z"
                      fill="javascript:void(0)68B238"
                    />
                  </svg>
                </div>
                <div className="notification-details">
                  <a href="javascript:void(0)" className="text-decoration-none">
                    <h6 className="notification-details-title">
                      Duis autem vel eum iriure dolor in hendrerit in vulputate
                      velit esse molestie consequat
                    </h6>
                  </a>
                  <p className="notification-discription">
                    Vel illum dolore eu feugiat nulla...
                  </p>
                  <p className="notification-time">August 12, 2022</p>
                </div>
                <div className="notification-remove-icon">
                  <a href="javascript:void(0)">
                    <img
                      src="/images/Remove.png"
                      alt=""
                      className="img-fluid w-100"
                    />
                  </a>
                </div>
              </div>
              <div className="notification-item">
                <div className="notification-icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.9875 9.83459L23.304 2.98691C23.183 1.77539 22.225 0.817313 21.0134 0.696353L14.1658 0.0128335C13.4026 -0.0639665 12.6451 0.206753 12.1027 0.749153L0.7488 12.1031C-0.2496 13.1015 -0.2496 14.721 0.7488 15.7194L8.28096 23.2516C9.27936 24.25 10.8998 24.25 11.8973 23.2516L23.2512 11.8976C23.7936 11.3552 24.0634 10.5978 23.9875 9.83459ZM19.0454 4.95395C18.529 4.43747 18.529 3.60131 19.0454 3.08483C19.561 2.56931 20.3981 2.56835 20.9146 3.08483C21.431 3.60131 21.4301 4.43843 20.9146 4.95395C20.3981 5.47043 19.5619 5.47043 19.0454 4.95395Z"
                      fill="javascript:void(0)68B238"
                    />
                  </svg>
                </div>
                <div className="notification-details">
                  <a href="javascript:void(0)" className="text-decoration-none">
                    <h6 className="notification-details-title">
                      Duis autem vel eum iriure dolor in hendrerit in vulputate
                      velit esse molestie consequat
                    </h6>
                  </a>
                  <p className="notification-discription">
                    Vel illum dolore eu feugiat nulla...
                  </p>
                  <p className="notification-time">August 12, 2022</p>
                </div>
                <div className="notification-remove-icon">
                  <a href="javascript:void(0)">
                    <img
                      src="/images/Remove.png"
                      alt=""
                      className="img-fluid w-100"
                    />
                  </a>
                </div>
              </div>
              <div className="notification-item">
                <div className="notification-icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.9875 9.83459L23.304 2.98691C23.183 1.77539 22.225 0.817313 21.0134 0.696353L14.1658 0.0128335C13.4026 -0.0639665 12.6451 0.206753 12.1027 0.749153L0.7488 12.1031C-0.2496 13.1015 -0.2496 14.721 0.7488 15.7194L8.28096 23.2516C9.27936 24.25 10.8998 24.25 11.8973 23.2516L23.2512 11.8976C23.7936 11.3552 24.0634 10.5978 23.9875 9.83459ZM19.0454 4.95395C18.529 4.43747 18.529 3.60131 19.0454 3.08483C19.561 2.56931 20.3981 2.56835 20.9146 3.08483C21.431 3.60131 21.4301 4.43843 20.9146 4.95395C20.3981 5.47043 19.5619 5.47043 19.0454 4.95395Z"
                      fill="javascript:void(0)68B238"
                    />
                  </svg>
                </div>
                <div className="notification-details">
                  <a href="javascript:void(0)" className="text-decoration-none">
                    <h6 className="notification-details-title">
                      Duis autem vel eum iriure dolor in hendrerit in vulputate
                      velit esse molestie consequat
                    </h6>
                  </a>
                  <p className="notification-discription">
                    Vel illum dolore eu feugiat nulla...
                  </p>
                  <p className="notification-time">August 12, 2022</p>
                </div>
                <div className="notification-remove-icon">
                  <a href="javascript:void(0)">
                    <img
                      src="/images/Remove.png"
                      alt=""
                      className="img-fluid w-100"
                    />
                  </a>
                </div>
              </div>
              <div className="notification-item">
                <div className="notification-icon">
                  <svg
                    width="30"
                    height="31"
                    viewBox="0 0 30 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.1133 14.2114C22.1277 15.5429 21.7754 16.7919 21.1524 17.8623C19.817 20.1567 19.1584 21.421 19.1584 24.0754V24.7176H10.8391V24.0744C10.8391 21.3874 10.1171 20.1182 8.79714 17.7768C8.21538 16.7458 7.88418 15.5554 7.88418 14.2872C7.88418 10.3243 11.1261 7.11697 15.1015 7.17361C18.9415 7.22833 22.072 10.3695 22.1124 14.2104L22.1133 14.2114ZM19.1498 26.0866H10.8477C10.841 26.1826 10.8381 26.2786 10.8381 26.3755C10.8381 27.0975 11.0224 27.7771 11.3469 28.3694C12.0525 29.6606 13.4244 30.5362 14.9988 30.5362C16.5732 30.5362 17.945 29.6606 18.6506 28.3694C18.9751 27.7771 19.1594 27.0975 19.1594 26.3755C19.1594 26.2786 19.1565 26.1826 19.1498 26.0866ZM4.87843 15.1579C4.87843 14.9179 4.68355 14.7231 4.44355 14.7231H1.21027C0.970273 14.7231 0.775391 14.9179 0.775391 15.1579C0.775391 15.3979 0.970273 15.5928 1.21027 15.5928H4.44355C4.68355 15.5928 4.87843 15.3979 4.87843 15.1579ZM7.84291 8.00209C8.01283 7.83217 8.01283 7.55665 7.84291 7.38769L5.55715 5.10193C5.38723 4.93201 5.11171 4.93201 4.94179 5.10193C4.77187 5.27185 4.77187 5.54737 4.94179 5.71633L7.22755 8.00209C7.31299 8.08657 7.42339 8.12977 7.53475 8.12977C7.64611 8.12977 7.75747 8.08753 7.84195 8.00209H7.84291ZM15.4336 4.60273V1.36945C15.4336 1.12945 15.2388 0.93457 14.9988 0.93457C14.7588 0.93457 14.5639 1.12945 14.5639 1.36945V4.60273C14.5639 4.84273 14.7588 5.03761 14.9988 5.03761C15.2388 5.03761 15.4336 4.84273 15.4336 4.60273ZM22.77 8.00209L25.0557 5.71633C25.2256 5.54641 25.2256 5.27089 25.0557 5.10193C24.8858 4.93297 24.6103 4.93201 24.4404 5.10193L22.1546 7.38769C21.9847 7.55761 21.9847 7.83313 22.1546 8.00209C22.2391 8.08657 22.3504 8.12977 22.4618 8.12977C22.5732 8.12977 22.6845 8.08753 22.769 8.00209H22.77ZM29.2221 15.1579C29.2221 14.9179 29.0272 14.7231 28.7872 14.7231H25.554C25.314 14.7231 25.1191 14.9179 25.1191 15.1579C25.1191 15.3979 25.314 15.5928 25.554 15.5928H28.7872C29.0272 15.5928 29.2221 15.3979 29.2221 15.1579Z"
                      fill="javascript:void(0)FFCE00"
                    />
                  </svg>
                </div>
                <div className="notification-details">
                  <a href="javascript:void(0)" className="text-decoration-none">
                    <h6 className="notification-details-title">
                      Duis autem vel eum iriure dolor in hendrerit in vulputate
                      velit esse molestie consequat
                    </h6>
                  </a>
                  <p className="notification-discription">
                    Vel illum dolore eu feugiat nulla...
                  </p>
                  <p className="notification-time">August 12, 2022</p>
                </div>
                <div className="notification-remove-icon">
                  <a href="javascript:void(0)">
                    <img
                      src="/images/Remove.png"
                      alt=""
                      className="img-fluid w-100"
                    />
                  </a>
                </div>
              </div>
              <div className="notification-item">
                <div className="notification-icon">
                  <svg
                    width="26"
                    height="24"
                    viewBox="0 0 26 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.9713 18.9437L6.06637 13.1126L7.13581 21.7402C7.28461 22.9402 6.34861 24 5.13997 24C4.12429 24 3.26893 23.2435 3.14413 22.2355L1.99501 12.9744C1.31821 12.8669 0.799805 12.2794 0.799805 11.5718V7.37088C0.799805 6.58656 1.43629 5.95104 2.22061 5.95104H5.73133V5.96064L20.9713 0V18.9437ZM22.1723 6.12288V12.8198C24.0212 12.8198 25.5198 11.3203 25.5198 9.47136C25.5198 7.6224 24.0212 6.12384 22.1723 6.12384V6.12288Z"
                      fill="javascript:void(0)75BEEA"
                    />
                  </svg>
                </div>
                <div className="notification-details">
                  <a href="javascript:void(0)" className="text-decoration-none">
                    <h6 className="notification-details-title">
                      Duis autem vel eum iriure dolor in hendrerit in vulputate
                      velit esse molestie consequat
                    </h6>
                  </a>
                  <p className="notification-discription">
                    Vel illum dolore eu feugiat nulla...
                  </p>
                  <p className="notification-time">August 12, 2022</p>
                </div>
                <div className="notification-remove-icon">
                  <a href="javascript:void(0)">
                    <img
                      src="/images/Remove.png"
                      alt=""
                      className="img-fluid w-100"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Notification Section End  */}
      <Footer />
    </>
  );
};

export default Notification;
