import React, {useState} from 'react'
import { useLocation } from 'react-router-dom';
import Header from './Header';
import firebase from './Firebase';

const Conversation = () => {
    const location=useLocation();
    const momentDetail=location.state;
    const [msg,setMsg]=useState('');
    
    const sendMsg = (e)=>{
        e.preventDefault();
        firebase.firestore().collection('Chat_Users').doc(`${localStorage.getItem('PO_USER_ID')}-${momentDetail.plus_one_id}-${momentDetail._id}`)
        .collection('Chats').add({
          content:msg,
          created_at: Date.now(),
          delivered_at:new Date(),
          from:localStorage.getItem("PO_USER_ID"),
          read:false,
          read_at:null,
          to:momentDetail.plus_one_id,
        }).then((docRef) => {
          firebase.firestore().collection('Chat_Users').doc(`${localStorage.getItem('PO_USER_ID')}-${momentDetail.plus_one_id}-${momentDetail._id}`)
          .collection('Chats').doc(docRef.id).update({
            doc_id:docRef.id
          })
          firebase.firestore().collection('Chat_Users').doc(`${localStorage.getItem('PO_USER_ID')}-${momentDetail.plus_one_id}-${momentDetail._id}`)
          .update({
            last_message:{
              content:msg,
              from:localStorage.getItem("PO_USER_ID"),
              sent_at:new Date(),
              to:momentDetail.plus_one_id,
            },
            updated_at:new Date()
          })
          setMsg('');
        })
    }
        
  return (
    <>
        <Header/>
        {/* Conversation Section Start  */}
        <section className="conversation-section bg-color">
            <div className="container">
                <div className="conversation-content">
                    <div className="conversation-massage plusone-profile-price">
                        <div className="conversation-profile">
                            <div className="conversation-profile-img">
                                <img src={momentDetail.profile_pic} alt="" className="img-fluid rounded-circle"/>
                                <h6 className="conversation-profile-name">{momentDetail.first_name} {momentDetail.last_name[0]}.</h6>
                                <p className="how-work-profile-elite mb-0">
                                    <i className="fa-solid fa-circle-check"></i>
                                    <span className="section-discription">ID Verified</span>
                                </p>
                            </div>
                            <div className="coversation-profile-details">
                                <h5 className="coversation-profile-title">Start a conversation</h5>
                                <p className="conversation-profile-discription">Share any relevant details about (name of Moment) with your Plus One so they can make sure you have a great Moment.
                                </p>
                                <ul>
                                    <li className="conversation-profile-discription">Tell {momentDetail.first_name} a little about yourself.</li>
                                    <li className="conversation-profile-discription">Is there a specific dress code? </li>
                                    <li className="conversation-profile-discription">Do they need to bring anything?</li>
                                </ul>
                            </div>
                        </div>
                        <div className="conversation-massage-box">
                            <textarea className="form-control" cols="30" rows="9" value={msg} onChange={(e) => setMsg(e.target.value)}> 
                            </textarea>
                            <a onClick={sendMsg} className="coversation-massage-send">Send <img src="/images/send_massage.png" alt="" className="img-fluid"/></a>
                        </div>
                        <div className=" george-profile-helth mb-0">
                            <img src="/images/george_health.png" alt="" className="img-fluid"/>
                            <p className="george-profile-helth-discription">To protect your community, please ensure your moment follows all public health guidlines &amp; regulations in your area.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* Conversation Section End  */}
    </>
   
  )
}

export default Conversation