import React from 'react'
import Header from '../../components/Header'
import PwdSuceesModal from '../../components/PwdSuceesModal'

const ForgetPasswordNotMatch = () => {
  return (
    <>
        <Header/>
        {/* Forgot Psaaword Section Start  */}
        <section className="forgot-pass-section">
            <div className="container">
                <div className="forgot-pass-content">
                    <div className="forgot-pass-heading">
                        <a href="javascript:void(0)">
                            <img src="/images/left_arrow.png" alt="" className="img-fluid"/>
                        </a>
                        <h6>Password</h6>
                    </div>
                    <div className="forgot-pass-body">
                        <h4 className="forgot-pass-new-title">Create new password</h4>
                        <p className="forgot-pass-pattern"><i className="fa-regular fa-circle"></i> Must be at least 8 characters long</p>
                        <p className="forgot-pass-pattern"><i className="fa-regular fa-circle"></i> Must have one alphabet</p>
                        <p className="forgot-pass-pattern"><i className="fa-regular fa-circle"></i> Must have one digit</p>

                        <div className="forgot-pass-email forgot-pass-new-input new-password">
                            <label className="form-label">New Password</label>
                            <div className="forgot-new-password">
                                <input type="email" className="form-control" placeholder="••••••••••••••••"/>
                                <a href="javascript:void(0)" className="forgot-pass-cancel">
                                    <img src="/images/cancel-icon.png" alt="" className="img-fluid"/>
                                </a>
                            </div>
                        </div>
                        <div className="forgot-pass-email forgot-pass-new-input">
                            <label className="form-label">Confirm Password</label>
                            <div className="forgot-new-password">
                                <input type="email" className="form-control" placeholder="••••••••••••••••"/>
                                <a href="javascript:void(0)" className="forgot-pass-cancel">
                                    <img src="/images/cancel-icon.png" alt="" className="img-fluid"/>
                                </a>
                            </div>
                            <p className="forgot-pass-match forgot-pass-not-match"><i className="fa-solid fa-circle-xmark"></i> Password does not match!</p>
                        </div>
                        <a href="javascript:void(0)" className="btn btn-warning forgot-pass-btn w-100 mt-0" data-bs-toggle="modal" data-bs-target="#passwordModal">Change Password</a>
                    </div>
                </div>
            </div>
        </section>
        {/* Forgot Psaaword Section Start  */}
        <PwdSuceesModal/>

    </>
  )
}

export default ForgetPasswordNotMatch