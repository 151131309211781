import React from 'react'

const Locations = () => {
  return (
    <>
    <section className="location-section">
        <div className="location-content">
            <div className="r-book-heading">
                <div className="r-book-logo information-logo">
                    <img src="/images/logo.png" alt="" className="img-fluid"/>
                </div>
                <div className="r-book-progress information-tab-progress">
                    <div className="r-book-progress-title information-progress-title">
                        <h6 className="mb-0"><span>Your progress:</span> 30% Complete</h6>
                    </div>
                    <div className="progress w-100">
                        <div className="progress-bar" role="progressbar" style={{width:'30%'}} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="location-body">
                    <div className="location-back-arrow">
                        <a href="javascript:void(0)" className="text-decoration-none information-form-back"><i className="fa-solid fa-arrow-left"></i></a>
                    </div>
                    <div className="errands-tab-body">
                        <h2 className="errands-tab-body-title">Where are you going to Run Errands?</h2>
                        <div className="errands-body-address-box">
                            <h5 className="errands-address-title">Moment Address</h5>
                            <div className="errands-location-search">
                                <div className="errands-location-input">
                                    <input type="text" className="form-control" placeholder="Moment Location"/>
                                </div>
                                <div className="form-check errands-radio-btn p-0">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="errandsRadio1"/>
                                    <label className="form-check-label errands-radio-label" htmlFor="errandsRadio1">
                            <span>Moment Address</span>
                            <h6>1234 A Lorem Ipsum Blvd, Orlando, FL 1234</h6>
                            </label>
                                </div>
                                <div className="form-check errands-radio-btn p-0">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="errandsRadio2"/>
                                    <label className="form-check-label errands-radio-label" htmlFor="errandsRadio2">
                                <span>Moment Address</span>
                                <h6>1234 A Lorem Ipsum Blvd, Orlando, FL 1234</h6>
                            </label>
                                </div>
                                <div className="form-check errands-radio-btn p-0">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="errandsRadio3"/>
                                    <label className="form-check-label errands-radio-label" htmlFor="errandsRadio3">
                                <span>Moment Address</span>
                                <h6>1234 A Lorem Ipsum Blvd, Orlando, FL 1234</h6>
                            </label>
                                </div>
                                {/* <a href="javascript:void(0)errandsmap" className="errands-set-location">Set location on the map</a>  */}
                                <div className="errands-location-btn text-center">
                                    <a href="javascript:void(0)" className="btn btn-warning">Select & Continue</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Locations