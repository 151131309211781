import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import Select, { StylesConfig } from "react-select";
import { REACT_APP_HOST_NAME } from "../baseurl";
import axios from "axios";
import { useEffect } from "react";
import NavBar from "./NavBar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ViewProfile = () => {
  const location = useLocation();
  const [bio, setBio] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [work, setWork] = useState("");
  const [profile_pic, setProfile_pic] = useState("");
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("");
  const [languages, setLanguages] = useState([]);
  const [upload, setUpload] = useState(false);
  const [user, setUser] = useState({});
  const [update, setUpdate] = useState(true);
  const options = [
    { value: "Bahana Indonesia", label: "Bahana Indonesia" },
    { value: "Bengali", label: "Bengali" },
    { value: "English", label: "English" },
    { value: "Hindi", label: "Hindi" },
    { value: "Dansk", label: "Dansk" },
    { value: "Deutsch", label: "Deutsch" },
    { value: "Spanish", label: "Spanish" },
    { value: "French", label: "French" },
    { value: "Italian", label: "Italian" },
    { value: "Gujarati", label: "Gujarati" },
  ];

  useEffect(() => {
    const token = localStorage.getItem("PO_TOKEN");
    axios({
      method: "get",
      url: `${REACT_APP_HOST_NAME}/apps/view-profile`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setUser(response.data.data);
        let data = response.data.data;
        setBio(data.bio);
        let arr = [];
        data.language.map((elem) => {
          arr.push({ value: elem, label: elem });
        });
        setLanguages(arr);
        setEthnicity(data.location);
        setProfile_pic(data.profile_pic);
        setWork(data.work);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  const nav = useNavigate();
  const month = (date) => {
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const d = new Date(date);
    let name = month[d.getMonth()];
    return name;
  };

  const handleChange = (e) => {
    setLanguages(e);
  };

  const colorStyle: StylesConfig<ColourOption> = {
    control: (styles) => ({
      ...styles,
      padding: "5px 20px",
      border: "1px solid #ACB4BA",
      borderRadius: "8px",
    }),
  };

  const updateProfile = () => {
    document.getElementById("myFileInput").click();
  };

  const handleImage = (e) => {
    const token = localStorage.getItem("PO_TOKEN");
    let type = e.target.files[0].type;
    if (type === "image/png" || type === "image/jpg" || type === "image/jpeg") {
      setErr("");
      const myurl = `${REACT_APP_HOST_NAME}/apps/update-profilepic`;
      var bodyFormData = new FormData();
      bodyFormData.append("image", e.target.files[0]);
      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          if (response.data.success === true) {
            let url = response.data.url;
            setProfile_pic(url);
            console.log(response.data.url);
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log("Errors", error);
        });
    } else {
      setErr("Please select valid image");
    }
  };

  const handleUpdate = () => {
    const token = localStorage.getItem("PO_TOKEN");
    var body = new URLSearchParams();
    let lang = languages.map((elem) => {
      return elem.value;
    });
    body.append("bio", bio);
    body.append("location", ethnicity);
    body.append("language", JSON.stringify(lang));
    body.append("work", work);
    body.append("profile_pic", profile_pic);
    axios({
      method: "post",
      url: `${REACT_APP_HOST_NAME}/apps/update-user-about-info`,
      headers: { Authorization: `Bearer ${token}` },
      data: body,
    })
      .then((response) => {
        console.log(response);
        setUpdate(true);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  return (
    <>
      <Header />
      <section className="po-sharing-section">
        <div className="po-sharing-content">
          <NavBar />
          <div className="container view-profile">
            <div className="view-profile-content">
              <div className="view-profile-box">
                <div className="view-profile-img text-center">
                  {profile_pic ? (
                    <img
                      src={profile_pic}
                      alt=""
                      className="img-fluid rounded-circle"
                      style={{
                        width: "128px",
                        height: "128px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        width: "130px",
                        height: "130px",
                        borderRadius: "50%",
                        backgroundColor: "#F3F3F3",
                      }}
                    ></div>
                  )}
                  <img
                    src="/images/check_circle.png"
                    alt=""
                    className="img-fluid view-profile-check"
                  />
                </div>
                <input
                  type="file"
                  name=""
                  id="myFileInput"
                  onChange={handleImage}
                  hidden
                />
                <a className="view-profile-title" onClick={updateProfile}>
                  Update Profile
                </a>
                <div className="error">{err}</div>

                <h5 className="view-profile-confirmed">
                  Stacy Michaels confirmed
                </h5>
                <p className="view-profile-confirmed-item">
                  <i className="fa-solid fa-check"></i> Identification
                </p>
                <p className="view-profile-confirmed-item">
                  <i className="fa-solid fa-check"></i> Email Address
                </p>
                <p className="view-profile-confirmed-item">
                  <i className="fa-solid fa-check"></i> Phone number
                </p>
              </div>
              <div className="view-profile-details-content">
                <h2 className="view-profile-details-title">
                  Hi, I’m {user.first_name} {user.last_name}!
                </h2>
                <h6 className="view-profile-details-date">
                  Joined in {month(user?.createdAt?.split("T")[0])}{" "}
                  {user?.createdAt?.split("-")[0]}
                </h6>
                <a
                  onClick={() => setUpdate(false)}
                  className="view-profile-details-edit"
                >
                  Edit Profile
                </a>
                <div className="view-profile-about">
                  {update ? (
                    <>
                      <h5 className="view-profile-confirmed">About</h5>
                      {bio ? (
                        <p className="view-profile-confirmed-item">{bio}</p>
                      ) : (
                        ""
                      )}
                      <div className="view-profile-address">
                        {ethnicity ? (
                          <p className="view-profile-confirmed-item view-profile-address-item">
                            <img
                              src="/images/location.png"
                              alt=""
                              className="img-fluid"
                            />{" "}
                            Lives in {ethnicity}
                          </p>
                        ) : (
                          ""
                        )}
                        {user.language ? (
                          <p className="view-profile-confirmed-item view-profile-address-item">
                            <img
                              src="/images/chat-bubble-empty.png"
                              alt=""
                              className="img-fluid"
                            />{" "}
                            Speaks {user?.language?.join(",")}
                          </p>
                        ) : (
                          ""
                        )}
                        {work ? (
                          <p className="view-profile-confirmed-item view-profile-address-item">
                            <img
                              src="/images/Icon_Work.png"
                              alt=""
                              className="img-fluid"
                            />{" "}
                            Works at {work}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <h5 className="view-profile-confirmed">About</h5>
                      <textarea
                        cols="30"
                        className="form-control"
                        rows="5"
                        style={{ resize: "none" }}
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                      ></textarea>
                      <div className="view-profile-address">
                        <h5 className="view-profile-confirmed">Location</h5>
                        <input
                          type="text"
                          className="form-control"
                          style={{ border: "1px solid #ACB4BA" }}
                          value={ethnicity}
                          onChange={(e) => setEthnicity(e.target.value)}
                        />
                        <h5 className="view-profile-confirmed">
                          Language | Speak
                        </h5>
                        <Select
                          options={options}
                          isMulti
                          onChange={handleChange}
                          placeholder="Select Language"
                          value={languages}
                          styles={colorStyle}
                        ></Select>
                        <h5 className="view-profile-confirmed">Work</h5>
                        <input
                          type="text"
                          className="form-control"
                          style={{ border: "1px solid #ACB4BA" }}
                          value={work}
                          onChange={(e) => setWork(e.target.value)}
                        />
                        <div className="view-profile-button">
                          <a className="btn" onClick={() => setUpdate(true)}>
                            Cancel
                          </a>
                          <button
                            className="btn btn-warning"
                            style={{ width: "100px" }}
                            onClick={handleUpdate}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="view-profile-review">
                    <h5 className="view-profile-confirmed view-profile-review-title">
                      <img
                        src="/images/star.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      0 Reviews
                    </h5>
                  </div>
                  <a className="view-profile-review-by">Reviews by you</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewProfile;
