import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import axios from "axios";
import { REACT_APP_HOST_NAME } from "../baseurl";
import { useEffect } from "react";

const ReviewAndBook = () => {
  const location = useLocation();
  const nav = useNavigate();
  const data = location.state.data;
  const id = location.state.id;
  const detail = location.state.detail;
  const voucher = location.state.voucher;
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyC3MDh4g3G1POPU4PBb2LoCz0YpPyg1InM",
  });
  const containerStyle = {
    width: "500px",
    height: "200px",
    border: "1px solid grey",
    borderRadius: "8px",
  };
  const hourlyRate = detail.hourly_rate;
  const duration = data.duration;
  const tax = (hourlyRate * 25) / 100;
  const taxedHourlyRate = hourlyRate + tax;
  const totalAmount = duration * taxedHourlyRate;
  const externalTax = (totalAmount * 10) / 100;
  const taxedTotalAmount = totalAmount + externalTax;
  const [topRated, setTopRated] = useState([]);

  const add3Dots = (string, limit) => {
    var dots = "...";
    if (string.length > limit) {
      string = string.substring(0, limit) + dots;
    }
    return string;
  };

  const handleSubmit = () => {
    const token = localStorage.getItem("PO_TOKEN");
    var body = new URLSearchParams();
    body.append("address", data.add);
    body.append("date", data.date);
    body.append("time", data.time);
    body.append("plus_one_id", id);
    body.append("amount", taxedTotalAmount);
    body.append("week_day", data.day);
    body.append("duration", duration);
    body.append("voucher_id", "");
    body.append("payment_id", location.state.intent);
    body.append("lat", data.location.latitude);
    body.append("long", data.location.longitude);
    body.append("moment_id", data.topRatedId);
    axios({
      method: "post",
      url: `${REACT_APP_HOST_NAME}/apps/confirm-booking`,
      headers: { Authorization: `Bearer ${token}` },
      data: body,
    })
      .then((res) => {
        nav("/confirmed", {
          state: { data: data, detail: detail, res: res.data.data, id },
        });
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        console.log("err", err.res.data.message);
      });
  };

  const getTopRated = () => {
    const token = localStorage.getItem("PO_TOKEN");
    axios({
      method: "get",
      url: `${REACT_APP_HOST_NAME}/apps/all-topRatedMoments`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setTopRated(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    getTopRated();
  }, []);
  return (
    //  Review Book Section Start
    <section className="r-book-section">
      <div className="r-book-content">
        <div className="r-book-heading">
          <div className="r-book-logo information-logo">
            <img src="/images/logo.png" alt="" className="img-fluid" onClick={() => {
              nav("/home");
            }} />
          </div>
          <div className="r-book-progress information-tab-progress">
            <div className="r-book-progress-title information-progress-title">
              <h6 className="mb-0">
                <span>Your progress:</span> {location.state.payment ? 90 : 70}%
                Complete
              </h6>
            </div>
            <div className="progress w-100">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${location.state.payment ? "90%" : "70%"}` }}
                aria-valuenow={location.state.payment ? 90 : 70}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="r-book-body">
            <a
              onClick={() =>
                nav("/selecting_profile", {
                  state: { id, data: data, details: detail },
                })
              }
              className="text-decoration-none information-form-back"
            >
              <i className="fa-solid fa-arrow-left"></i>
            </a>
            <div className="r-book-payment-content">
              <h2 className="r-book-payment-title information-profile-title">
                Review & Book
              </h2>
              <p className="r-book-payment-headline information-policy">
                <img src="/images/card.png" alt="" className="img-fluid" /> You
                won't be billed until you're done with your Moment.
              </p>
              <div className="r-book-payment">
                <div className="r-book-payment-heading">
                  <div className="r-book-payment-details">
                    <h5 className="r-book-details-title">
                      Moment Details{" "}
                      {/* <img
                        src="/images/edit.png"
                        alt=""
                        className="img-fluid"
                      /> */}
                    </h5>
                    <div className="r-book-addres massage-update-moment">
                      <p className="order-address-link">
                        <img
                          src="/images/Calendar.png"
                          alt=""
                          className="img-fluid"
                        />
                        {data.day} - {moment(data.date).format("MMM DD, YYYY")}
                      </p>
                      <p className="order-address-link">
                        <img
                          src="/images/Icon_Time.png"
                          alt=""
                          className="img-fluid"
                        />
                        {data.time}
                      </p>
                      <p className="order-address-link">
                        <img
                          src="/images/pin_location.png"
                          alt=""
                          className="img-fluid"
                        />
                        {add3Dots(data.add, 30)}
                      </p>
                    </div>
                  </div>
                  <div className="r-book-payment-profile conversation-profile-img w-auto me-0 text-center">
                    <img
                      src={detail.profile_pic}
                      alt=""
                      className="img-fluid rounded-circle p-img"
                    />
                    <h6 className="conversation-profile-name">
                      {detail.first_name} {detail.last_name[0]}.
                    </h6>
                    <p className="how-work-profile-elite mb-0 mt-0">
                      <i className="fa-solid fa-circle-check"></i>
                      <span className="section-discription">ID Verified</span>
                    </p>
                    <p className="r-book-profile-star order-address-link">
                      <img
                        src="/images/Icon_Star.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      {detail.rate}
                    </p>
                  </div>
                </div>
                <div className="r-book-payment-map massage-momemt-map">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={{
                      lat: data.location.latitude,
                      lng: data.location.longitude,
                    }}
                    zoom={18}
                  >
                    <Marker
                      position={{
                        lat: data.location.latitude,
                        lng: data.location.longitude,
                      }}
                    />
                  </GoogleMap>

                  {topRated.map((elem) =>
                    elem.id === location.state.data.topRatedId ? (
                      <div className="r-book-payment-celebration">
                        <div className="r-book-celebration-img">
                          <img
                            src={elem.image}
                            style={{
                              height: "70px",
                              width: "70px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="r-book-celebration-details">
                          <h6 className="r-book-celebration-subtitle">
                            You're going to
                          </h6>
                          <h6 className="r-book-celebration-title">
                            {elem.name}
                          </h6>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  )}

                  {!location.state.payment ? (
                    <div className="r-book-payment-card">
                      <h5 className="r-book-details-title mb-0">Payment</h5>
                      <div className="r-book-card">
                        <div
                          className="r-book-card-details"
                          onClick={() => {
                            // nav('/review_and_book2', { state: { id, data, detail } })
                            nav("/payment_method", {
                              state: { id, data, detail, taxedTotalAmount },
                            });

                            // console.log(id, data, detail)
                          }}
                        >
                          <a className="text-decoration-none">
                            Add Payment Method
                          </a>
                        </div>
                        <a
                          href="javascript:void(0)"
                          className="r-book-card-more"
                        >
                          <i className="fa-solid fa-angle-right"></i>
                        </a>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="r-book-price">
                    <h5 className="r-book-price-title r-book-details-title">
                      Price Details
                    </h5>
                    <p className="r-book-price-point order-address-link">
                      <span>Hourly Rate</span> <span>${hourlyRate}/hr</span>
                    </p>
                    <p className="r-book-price-point order-address-link">
                      <span>
                        Trust & Support Fee{" "}
                        <img
                          src="/images/information.png"
                          alt=""
                          className="img-fluid"
                        />{" "}
                      </span>{" "}
                      <span>$8.00</span>
                    </p>
                  </div>
                  {voucher ? (
                    <div className="order-price-details-rate-voucher">
                      <p className="discount-text">Voucher Discount</p>
                      <p className="discount-text">
                        -${voucher.voucher_amount}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="r-book-total-payment">
                    <h5 className="r-book-details-title mb-0">Total</h5>
                    <h5 className="r-book-details-title mb-0">
                      ${" "}
                      {voucher
                        ? parseFloat(
                          taxedTotalAmount - voucher.voucher_amount
                        ).toFixed(2)
                        : parseFloat(taxedTotalAmount).toFixed(2)}
                    </h5>
                  </div>
                </div>
                <p className="r-book-payment-policy">
                  You may see a temporary hold on your payment method in the
                  amount of your Plus One’s hourly rate of ${hourlyRate}. You can cancel at
                  any time. Moments canceled less than 24 hours before the start
                  time may be billed a{" "}
                  <a href="javascript:void(0)">cancellation fee </a> of one
                  hour. Moments have a pne hour minimum.
                </p>
                <p className="r-book-payment-policy">
                  Please follow all public health regulations in your area.{" "}
                  <a href="javascript:void(0)">Learn more</a>{" "}
                </p>
                {location.state.payment ? (
                  <div
                    className="r-book-payment-btn text-center"
                    onClick={handleSubmit}
                  >
                    <a className="btn btn-warning">Book Your Moment</a>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    //  Review Book Section End
  );
};

export default ReviewAndBook;
