import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyB_TMVpylL_JoglTzvi5r48kB0TLDMCxQw",
    authDomain: "plus-one-abdfd.firebaseapp.com",
    projectId: "plus-one-abdfd",
    storageBucket: "plus-one-abdfd.appspot.com",
    messagingSenderId: "1010227444615",
    appId: "1:1010227444615:web:1c4623492f2e75116e7122",
    measurementId: "G-9037KC233P"
};

const firebaseApp=firebase.initializeApp(firebaseConfig);

// const db=firebaseApp.firestore();


export default firebase;