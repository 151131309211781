import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import Select, { StylesConfig } from "react-select";
import { REACT_APP_HOST_NAME } from "../baseurl";
import axios from "axios";
import { useEffect } from "react";
import NavBar from "./NavBar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SafetyCenter = () => {
  return (
    <>
      <Header />
      <section className="po-sharing-section">
        <div className="po-sharing-content">
          <NavBar />
          <div className="privacy-sharing-body" data-scrollbar>
            <div className="po-sharing-sidebar-icon d-block d-lg-none">
              <i className="fa-solid fa-bars"></i>
            </div>
            <div className="privacy-sharing safety-center">
              <div className="privacy-sharing-tabs safety-center-content">
                <h2 className="privacy-sharing-title notification-title">
                  Safety Center
                </h2>
                <p className="privacy-committed-title privacy-request-title">
                  Maecenas eget condimentum velit, sit amet feugiat lectus.
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per inceptos himenaeos.{" "}
                </p>
                <div className="safety-center-emergency">
                  <p className="privacy-committed-title privacy-request-title">
                    <span>
                      <svg
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2.40747 0.925781H5.59745C5.86116 0.925781 6.09934 1.08741 6.19291 1.34261L7.43488 4.63466C7.47741 4.74525 7.48592 4.86434 7.4689 4.97493L6.84792 8.30101C7.61352 10.0959 8.87249 11.2953 10.9566 12.3842L14.2402 11.7462C14.3593 11.7207 14.4784 11.7377 14.5889 11.7802L17.8895 13.0392C18.1362 13.1328 18.2978 13.3709 18.2978 13.6347V16.68C18.2978 18.0666 17.0814 19.181 15.6523 18.8747C13.0577 18.3133 8.24301 16.8757 4.87439 13.4985C1.65038 10.2745 0.570044 5.80858 0.20426 3.4012C0.000101358 2.02313 1.09745 0.925781 2.40747 0.925781ZM14.6318 6.97398C14.1639 6.97398 13.7811 6.59118 13.7811 6.12332V4.79703H12.4619C11.994 4.79703 11.6112 4.41423 11.6112 3.94636C11.6112 3.4785 11.994 3.0957 12.4619 3.0957H13.7811V1.77644C13.7811 1.30858 14.1639 0.925781 14.6318 0.925781C15.0996 0.925781 15.4824 1.30858 15.4824 1.77644V3.0957H16.8087C17.2766 3.0957 17.6594 3.4785 17.6594 3.94636C17.6594 4.41423 17.2766 4.79703 16.8087 4.79703H15.4824V6.12332C15.4824 6.59118 15.0996 6.97398 14.6318 6.97398Z"
                          fill="#E31103"
                        />
                      </svg>
                    </span>{" "}
                    Call local emergency services
                  </p>
                  <p className="privacy-committed-title privacy-request-title">
                    Quisque mauris dolor, fringilla sed tincidunt ac, finibus
                    non odio. Sed vitae mauris nec ante pretium finibus.{" "}
                  </p>
                </div>
                <div className="safety-tips">
                  <h4 className="safety-tips-title refer-work-item-title">
                    Safety Tips
                  </h4>
                  <div className="safety-tips-item">
                    <div className="safety-tips-link">
                      <div className="safety-tips-img">
                        <img
                          src="/images/tips_1.png"
                          alt=""
                          //   className="img-fluid"
                        />
                      </div>
                      <div className="safety-tips-body">
                        <p className="section-discription">Clients</p>
                        <p className="section-discription">
                          Lorem Ipsum Dolor Sit Amet
                        </p>
                      </div>
                    </div>
                    <div className="safety-tips-link">
                      <div className="safety-tips-img">
                        <img
                          src="/images/tips_2.png"
                          alt=""
                          //   className="img-fluid"
                        />
                      </div>
                      <div className="safety-tips-body">
                        <p className="section-discription">PlusOnes:</p>
                        <p className="section-discription">
                          Lorem Ipsum Dolor Sit Amet
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="safety-contact">
                <h6 className="privacy-committed-title privacy-request-title">
                  Need to get in touch?
                </h6>
                <p className="privacy-committed-discription profile-work-link m-0 d-inline">
                  We’ll start with some questions and get you <br /> the right
                  place.
                </p>
                <p className="howwork-turpis-btn">
                  <a href="#" className="btn privacy-deactivate">
                    Contact Us
                  </a>
                </p>
                <p className="section-discription safety-feedback">
                  You can also give us feedback.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SafetyCenter;
