import React, { useState } from "react";
import { useEffect } from "react";
import { ChatRoom } from "./ChatRoom";
import { ChatUser } from "./ChatUser";
import Header from "./Header";
import { REACT_APP_HOST_NAME, SERVER_URL } from "../baseurl";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { io } from "socket.io-client";
import axios from "axios";

// const socket = io(SERVER_URL);
// const socket = io('http://localhost:3001');

const Message = () => {

  const [users, setUsers] = useState([]);
  const [userChat, setUserChat] = useState({});
  const [selectedInd, setSelectedInd] = useState('');

  const containerStyle = {
    width: "100%",
    height: "200px",
    border: "1px solid grey",
    borderRadius: "8px",
  };

  // socket.on("connect", () => {
  //   localStorage.setItem('PO_SOCKET_ID', socket.id);
  //   console.log(socket.id);
  // })
  useEffect(() => {
    // if (localStorage.getItem("PO_SOCKET_ID") == null) {

    // socket.emit('Chat_Users', { 'socket_id': localStorage.getItem("PO_SOCKET_ID"), 'from_id': localStorage.getItem("PO_USER_ID") });

    // socket.on("Chat_Users", (res) => {
    //   setUsers(res);
    //   setUserChat(res[0]);
    //   setSelectedInd(0);
    // })
    // }
  });

  const addDots = (string, limit) => {
    var dots = "...";
    if (string.length > limit) {
      string = string.substring(0, limit) + dots;
    }
    return string;
  }

  const handleSelect = (ind, elem) => {
    setSelectedInd(ind);
    setUserChat(elem);
  }
  return (
    <>
      <Header />
      <section className="message-section">
        <div className="message-content">
          <div className="massage-box-content">
            <div className="message-profile-content">
              <div className="massage-inbox">
                <h6 className="massage-heading-title">Inbox</h6>
                <a href="javascript:void(0)">
                  <img
                    src="/images/Icon_Menu-2.png"
                    alt=""
                    className="img-fluid"
                  />
                </a>
              </div>
              <div className="message-profile-list" dataScrollbar="true">
                {users.length > 0 && users.map((elem, index) =>
                  <div class={selectedInd === index ? "message-profile-link active" : "message-profile-link"} onClick={() => handleSelect(index, elem)}>
                    <div class="message-user-img">
                      <img src={elem.user_profile_pic} alt="chat-img1" class="img-fluid" />
                    </div>
                    <div class="rk-message-user-details">
                      <div class="rk-message-user-detail-item">
                        <h5 class="rk-message-user-name">{elem.user_first_name} {elem.user_last_name}</h5>
                        {/* <span class="message-profile-receive-mass"></span> */}
                      </div>
                      <div class="rk-message-user-detail-item">
                        {/* <h5 class="rk-message-user-message-content">George C.</h5> */}
                        <h5 class="rk-message-user-message-content">{elem.last_message.content}</h5>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <ChatRoom userChat={userChat} />
            <div className="massage-moment-details-content">
              <div className="massage-details">
                <h6 className="massage-heading-title">Moment Details</h6>
              </div>
              {Object.keys(userChat).length > 0 ?
                <div className="massage-moment-details">
                  <div className="message-po-profile d-flex">
                    <img
                      src={userChat.user_profile_pic}
                      height={50}
                      width={50}
                      alt=""
                      srcset=""
                    />

                    <h5
                      className="ms-4 message-po-profile-heading"
                      style={{ color: "#17146B" }}
                    >
                      Your Plus One Will meet you here:
                    </h5>
                  </div>
                  <div className="massage-momemt-map">
                    {/* <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={{
                        lat: userChat.moment_lat_long.lat,
                        lng: userChat.moment_lat_long.long,
                      }}
                      zoom={18}
                    >
                      <Marker
                        position={{
                          lat: userChat.moment_lat_long.lat,
                          lng: userChat.moment_lat_long.long,
                        }}
                      />
                    </GoogleMap> */}
                  </div>
                  <div className="plus-one-moment-detail d-flex justify-content-center">
                    <div className="">
                      <p className="order-address-link">
                        <img
                          src="/images/Calendar.png"
                          alt=""
                          className="img-fluid"
                        />
                        Thursday - {userChat.moment_date}
                      </p>
                      <p className="order-address-link">
                        <img
                          src="/images/Icon_Time.png"
                          alt=""
                          className="img-fluid"
                        />
                        {userChat.moment_time.new}
                      </p>
                      <p className="order-address-link">
                        <img
                          src="/images/pin_location.png"
                          alt=""
                          className="img-fluid"
                        />
                        {addDots(userChat.moment_address, 20)}
                      </p>
                      <div className="massage-update-edite d-flex justify-content-center">
                        <div className="">
                          <a
                            href="javascript:void(0)"
                            className="text-decoration-none"
                          >
                            <img
                              src="/images/edit_moment.png"
                              height={20}
                              width={20}
                              className="me-2"
                              alt=""
                              srcset=""
                            />{" "}
                            Update Moment
                          </a>
                          <a href="" className="mb-0">
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="massage-moment-address">
                    <p className="massage-address-verified">
                      <svg
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="8.96881"
                          cy="8.78473"
                          r="7.565"
                          stroke="javascript:void(0)008EF9"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.17308 9.02174C5.32072 8.87411 5.56008 8.87411 5.70772 9.02174L7.45665 10.7707L12.23 5.99737C12.3776 5.84973 12.617 5.84973 12.7646 5.99737C12.9122 6.145 12.9122 6.38437 12.7646 6.53201L7.72397 11.5726C7.57633 11.7203 7.33697 11.7203 7.18933 11.5726L5.17308 9.55638C5.02544 9.40875 5.02544 9.16938 5.17308 9.02174Z"
                          fill="javascript:void(0)008EF9"
                        />
                      </svg>{" "}
                      ID Verified
                    </p>
                    <p className="massage-address-verified">
                      <svg
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.03125 2.36426L11.5032 7.27989L17.0312 8.07299L13.0312 11.8971L13.9752 17.2996L9.03125 14.7476L4.08725 17.2996L5.03125 11.8971L1.03125 8.07299L6.55925 7.27989L9.03125 2.36426Z"
                          stroke="javascript:void(0)008EF9"
                          stroke-width="1.15"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>{" "}
                      5 Reviews
                    </p>
                    <p className="massage-address-link">
                      <svg
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(javascript:void(0)clip0_77_6703)">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.96875 2.84766C6.13727 2.84766 3.03125 5.95368 3.03125 9.78516C3.03125 11.0497 3.36907 12.2337 3.95902 13.2535C4.02624 13.3697 4.04969 13.5061 4.02511 13.6381L3.54681 16.2071L6.11579 15.7288C6.24778 15.7042 6.3842 15.7277 6.50041 15.7949C7.52025 16.3848 8.70419 16.7227 9.96875 16.7227C13.8002 16.7227 16.9062 13.6166 16.9062 9.78516C16.9062 5.95368 13.8002 2.84766 9.96875 2.84766ZM1.90625 9.78516C1.90625 5.33236 5.51595 1.72266 9.96875 1.72266C14.4215 1.72266 18.0312 5.33236 18.0312 9.78516C18.0312 14.238 14.4215 17.8477 9.96875 17.8477C8.57651 17.8477 7.26527 17.4943 6.12137 16.8721L2.94671 17.4632C2.76442 17.4971 2.57712 17.439 2.446 17.3079C2.31489 17.1768 2.25681 16.9895 2.29075 16.8072L2.88182 13.6325C2.25959 12.4886 1.90625 11.1774 1.90625 9.78516Z"
                            fill="javascript:void(0)646667"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_77_6703">
                            <rect
                              width="18"
                              height="18"
                              fill="white"
                              transform="translate(0.96875 0.785156)"
                            />
                          </clipPath>
                        </defs>
                      </svg>{" "}
                      Speaks English
                    </p>
                    <p className="massage-address-link">
                      <svg
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.98245 17.2872C9.07444 17.2872 8.16643 17.1475 7.28529 16.8627C6.50623 16.5887 5.81851 16.1052 5.28123 15.4604C4.7332 14.7727 4.34636 13.956 4.14219 13.0856C3.89504 12.0111 3.77684 10.9043 3.79296 9.79209C3.79296 7.20239 4.28188 5.34877 5.28123 4.13451C6.29132 2.90951 7.87093 2.28626 9.98245 2.28626C10.8905 2.26477 11.8092 2.40984 12.6903 2.71072C13.464 2.98473 14.1518 3.47366 14.6837 4.11839C15.2263 4.81686 15.6132 5.64428 15.8066 6.51467C16.0484 7.58924 16.1612 8.69604 16.1451 9.79747C16.1612 10.8882 16.0484 12.0003 15.8066 13.0803C15.6132 13.9453 15.2263 14.7673 14.689 15.4443C14.141 16.0998 13.4533 16.578 12.6903 16.852C11.7985 17.1367 10.8851 17.2818 9.9717 17.2818L9.98245 17.2872ZM10.149 3.31784C10.1006 3.31784 10.0469 3.31784 9.99856 3.31784C8.19867 3.31784 6.88233 3.81214 6.07641 4.79C5.24899 5.79471 4.82454 7.48178 4.82454 9.79747C4.80842 10.8344 4.91588 11.8606 5.14691 12.8546C5.31346 13.5746 5.63583 14.2515 6.08178 14.8103C6.49011 15.2992 7.02739 15.6753 7.61303 15.8849C9.15503 16.3792 10.8045 16.3792 12.368 15.8849C12.9429 15.6807 13.4748 15.3046 13.8939 14.8049C14.3291 14.2569 14.6461 13.5799 14.8072 12.8653C15.0329 11.866 15.1404 10.8398 15.1242 9.80821C15.1404 8.76589 15.0329 7.74505 14.8072 6.7457C14.6461 6.02037 14.3291 5.33802 13.8831 4.76851C13.4748 4.27421 12.9429 3.89811 12.3519 3.68857C11.6373 3.44142 10.8958 3.31784 10.1436 3.31784H10.149Z"
                          fill="javascript:void(0)646667"
                        />
                        <path
                          d="M12.0993 10.6517H10.9281V11.9251C10.9281 12.1991 10.826 12.4624 10.6487 12.6558C10.4714 12.8492 10.2296 12.9567 9.97707 12.9567C9.72455 12.9567 9.48277 12.8492 9.30547 12.6558C9.12817 12.4624 9.02608 12.1991 9.02608 11.9251V10.6517H7.85481C7.60229 10.6517 7.36051 10.5443 7.18321 10.3509C7.0059 10.1574 6.90382 9.89418 6.90382 9.62016C6.90382 9.34615 7.0059 9.08288 7.18321 8.88946C7.36051 8.69604 7.60229 8.58858 7.85481 8.58858H9.02608V7.31522C9.02608 7.04121 9.12817 6.77794 9.30547 6.58452C9.48277 6.3911 9.72455 6.28364 9.97707 6.28364C10.2296 6.28364 10.4714 6.3911 10.6487 6.58452C10.826 6.77794 10.9281 7.04121 10.9281 7.31522V8.58858H12.0993C12.3519 8.58858 12.5936 8.69604 12.7709 8.88946C12.9482 9.08288 13.0503 9.34615 13.0503 9.62016C13.0503 9.89418 12.9482 10.1574 12.7709 10.3509C12.5936 10.5443 12.3519 10.6517 12.0993 10.6517Z"
                          fill="javascript:void(0)646667"
                        />
                      </svg>{" "}
                      Joined in August 2020
                    </p>
                    <p className="massage-address-link">
                      <svg
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.73886 8.20347C2.57687 7.93839 2.66043 7.59218 2.92552 7.43018L9.67552 3.30519C9.85558 3.19515 10.0821 3.19515 10.2621 3.30519L17.0121 7.43018C17.2772 7.59218 17.3608 7.93839 17.1988 8.20347C17.0368 8.46855 16.6906 8.55212 16.4255 8.39013L9.96883 4.44438L3.51215 8.39013C3.24707 8.55212 2.90085 8.46855 2.73886 8.20347ZM4.71875 9.97264C5.02941 9.97264 5.28125 10.2245 5.28125 10.5351V15.2226H14.6562V10.5351C14.6562 10.2245 14.9081 9.97264 15.2188 9.97264C15.5294 9.97264 15.7812 10.2245 15.7812 10.5351V15.3351C15.7812 15.8943 15.3279 16.3476 14.7687 16.3476H5.16875C4.60956 16.3476 4.15625 15.8943 4.15625 15.3351V10.5351C4.15625 10.2245 4.40809 9.97264 4.71875 9.97264ZM11.4688 13.3476C11.7794 13.3476 12.0312 13.0958 12.0312 12.7851C12.0312 12.4745 11.7794 12.2226 11.4688 12.2226H8.46875C8.15809 12.2226 7.90625 12.4745 7.90625 12.7851C7.90625 13.0958 8.15809 13.3476 8.46875 13.3476H11.4688Z"
                          fill="javascript:void(0)646667"
                        />
                      </svg>{" "}
                      Lives in Orlando, Florida
                    </p>
                  </div>
                  <div className="massage-moment-review">
                    <h6 className="massage-review-title">
                      Your Plus One’s Reviews
                    </h6>
                    <p className="massage-reviews-name">
                      Alex R.{" "}
                      <img
                        src="/images/group_star.png"
                        alt=""
                        className="img-fluid"
                      />
                    </p>
                    <small>Mon, July 25, 2022</small>
                  </div>
                </div> : ''}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Message;
