import React, { useState, useEffect } from "react";
import PaymentMethods from "./PaymentMethods";
import axios from "axios";
import UploadImgModal from "./UploadImgModal";
import CustomModal from "../../components/CustomModal";
import { REACT_APP_HOST_NAME } from "../../baseurl";
import countries from "../../helper/country.json";
import { toast } from "react-toastify";

const TakeProfileImg = ({
  setStep,
  setProcessPipline,
  setBack,
  data,
  handleImg,
}) => {
  const [file, setFile] = useState(null);
  const [err, setErr] = useState("");
  const [fileDataURL, setFileDataURL] = useState(null);
  const [isImgModal, setIsImgModal] = useState(false);
  const [disable, setDisable] = useState(false);
  const goBack = () => {
    setBack(1);
  };
  const handleContinue = () => {
    if (data.profile_pic) {
      setIsImgModal(true);
    } else {
      toast.error("Please Select Profile Pic");
    }
  };
  const handleNextStep = (e) => {
    // e.preventDefault();
    let AllData = { data };
    console.log("AllData", AllData);
    // .then((response) => {
    //   if (response?.data.data.token) {
    //     cookies.set("User", response.data.data, { path: "/" });
    //     cookies.set("authToken", response.data.data.token, { path: "/" });

    //   }
    // })
    // .catch((error) => {
    //   toast.error(error.response.data.error, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // });

    setStep(3);
    setProcessPipline(50);
  };

  // const handleChange = (e) => {
  //   const storeFile = e.target.files[0];
  //   setFile(storeFile);
  //   setIsImgModal(true);
  // };

  const handleImage = (e) => {
    let type = e.target.files[0].type;
    if (type === "image/png" || type === "image/jpg" || type === "image/jpeg") {
      setDisable(true);
      setErr("");
      // var img = new Image();
      // img.src = URL.createObjectURL(e.target.files[0]);
      // img.onload = function () {
      //   if (this.width < 100 || this.height < 100) {
      //     setErr(`Image dimension must be 200 x 200`)
      //   } else {
      //     setErr('');
      const myurl = `${REACT_APP_HOST_NAME}/apps/upload-img`;
      var bodyFormData = new FormData();
      bodyFormData.append("image", e.target.files[0]);
      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          if (response.data.success === true) {
            let url = response.data.data.url;
            handleImg(url);
            setDisable(false);
          }
        })
        .catch((error) => {
          console.log("Errors", error);
          setDisable(false);
        });
      //   }
      // }
    } else {
      setErr("Please select valid image");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const tmp = countries.filter(
      (con) => con.dial_code === data.country_code.toString()
    );
    // console.log(tmp);
    const payload = {
      email: data.email,
      country_code: data.country_code,
      mobile: data.mobile,
      password: data.pwd,
      first_name: data.fname,
      last_name: data.lname,
      profile_pic: data.profile_pic,
      birth_date: data.bod,
      country_iso2_code: tmp[0].code,
      device_token: '123435'
    };
    axios
      .post(`${REACT_APP_HOST_NAME}/apps/web/signup`, payload, {
        headers: {
          // "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        console.log(response.data.data);
        if (response.data.success === true) {
          localStorage.setItem("PO_TOKEN", response.data.data.token);
          setIsImgModal(false);
          setStep(4);
          setProcessPipline(99);
        }
      })
      .catch((error) => {
        console.log("Errors", error);
      });
  };

  return (
    <>
      <div
        className="tab-pane "
        id="upload"
        role="tabpanel"
        aria-labelledby="upload-tab"
      >
        <div className="information-tab-body">
          <div className="information-form-content">
            <div className="container">
              <div className="information-form-box">
                {/* <a
                  // href="javascript:void(0)"
                  className="text-decoration-none information-form-back"
                  onClick={() => goBack()}
                >
                  <i className="fa-solid fa-arrow-left"></i>
                </a> */}
                <div className="information-profile information-form">
                  <h2 className="information-profile-title">
                    Take your profile photo
                  </h2>
                  <div className="information-profile-img">
                    <div className="profile-uload">
                      {data.profile_pic ? (
                        <img
                          // src={fileDataURL || "/images/profile_img.png"}
                          src={data.profile_pic}
                          alt=""
                          className="img-fluid"
                          style={{
                            objectFit: "cover",
                            width: "200px",
                            height: "200px",
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            width: "200px",
                            height: "200px",
                            borderRadius: "50%",
                            backgroundColor: "#F3F3F3",
                          }}
                        ></div>
                      )}
                      <div className="information-profile-upload">
                        <label>
                          {" "}
                          <input type="file" onChange={handleImage} />{" "}
                          <img
                            src="/images/Icon_Camera.png"
                            alt=""
                            className="img-fluid"
                          />
                        </label>
                      </div>
                    </div>
                    <div className="error">{err}</div>
                    {/* <h6 className="information-upload-selfie">
              {" "}
              <a href="#" className="">
                Upload Selfie
              </a>
            </h6> */}
                  </div>
                  <div className="information-profile-points">
                    <p className="information-ptofile-discription">
                      Customers will use the photo to identify you. Looking
                      friendly makes a great impression. Photo requirements:
                    </p>
                    <ol className="profile-uload-list">
                      <li className="information-ptofile-discription">
                        Show your whole face and tops of your shoulder
                      </li>
                      <li className="information-ptofile-discription">
                        Take your sunglasses & hats off
                      </li>
                      <li className="information-ptofile-discription">
                        Take your photo in a well-lit place
                      </li>
                    </ol>
                  </div>
                  <button
                    className="btn btn-warning information-btn w-100"
                    // data-bs-toggle="modal"
                    // data-bs-target="#uploadModal"
                    onClick={handleContinue}
                    disabled={disable}
                  >
                    {disable ? "Uploading..." : "Next"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Upload Modal Start */}
      <CustomModal
        isModalOpen={isImgModal}
        handleOk={() => setIsImgModal(false)}
        handleCancel={() => setIsImgModal(false)}
      >
        <UploadImgModal
          setIsImgModal={setIsImgModal}
          handleSubmit={handleSubmit}
        />
      </CustomModal>
      {/* Upload Modal End */}
    </>
  );
};

export default TakeProfileImg;
